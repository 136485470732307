.onboardForm {
  .radio-group {
    margin: 0.5rem 0;
    border: 1px solid #007bff3f;
    label {
      font-size: 0.9rem;
      color: #000;
      font-weight: 400;
      letter-spacing: 1px;
      margin: 0;
    }
  }
  label.checkLable {
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid rgba(119, 119, 119, 0.4196078431);
    font-weight: 500;
    text-align: center;
    height: 100%;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    margin: 0;
    user-select: none;
    &.activeLable {
      box-shadow: 0px 0px 2px #007bff;
      background-color: #007bff08;
      border: 1px solid #007bff;
      color: #007bff;
    }
  }

  .wrapper {
    max-width: 830px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0px 10px 60px rgba(192, 195, 200, 0.2);
    border-radius: 9px;
    background-color: $color-white;
  }
  .head {
    padding: 30px 60px 25px;
    border-bottom: solid 1px #eeeff3;
    h2 {
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: #212121;
    }
  }
  .inner {
    padding: 20px 60px;
    h3 {
      font-weight: 500;
      font-size: 19px;
      line-height: 28px;
      color: #212121;
      margin-bottom: 22px;
      margin-top: 10px;
    }
  }
  .row {
    &:nth-of-type(2) {
      .form-group {
        &:nth-of-type(4) {
          margin-right: 38px;
        }
        &:nth-of-type(5) {
          margin-right: 0;
        }
      }
    }
  }
  .form-group {
    &:nth-of-type(even) {
      margin-right: 0;
    }
    &.full {
      width: 100%;
      margin-right: 0;
    }
    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      color: #464e5f;
      font-family: "Poppins", sans-serif;
    }
    input,
    select,
    textarea {
      width: 100%;
      outline: none;
      background: #f3f6f9;
      border-radius: 4px;
      border: none;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      //   color: #b5b5c3;
      font-family: "Poppins", sans-serif;
    }
    ul {
      gap: 0.2rem;
    }
    li {
      width: calc(20% - 0.2rem);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
      input {
        display: none;
      }
      label {
        cursor: pointer;
        padding: 10px 2px;
        font-weight: 400;
        text-align: center;
        height: 100%;
        width: 100%;
        font-size: 13px;
        line-height: 16px;
        color: #000c;
        margin: 0;
        user-select: none;
        border: 1px solid #7777776b;
      }
      .activeLable {
        box-shadow: 0px 0px 2px #007bff;
        background-color: #007bff08;
        border: 1px solid #007bff;
        color: #007bff;
      }
    }
  }
  .btnWrap {
    justify-content: space-between;
    button {
      outline: none;
      cursor: pointer;
      padding: 8px 32px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      font-family: "Poppins", sans-serif;
      border: none;
      &:first-of-type {
        color: #7e8299;
        background-color: #f3f6f9;
      }
      &:nth-of-type(2) {
        color: $color-white;
        background-color: #3699ff;
      }
    }
  }
}

// popup section start here
.popup {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 50px 0;
  display: none;
  .wrapper {
    max-width: 325px;
    width: 100%;
    margin: 0 auto;
    background-color: $color-white;
    box-shadow: 0px 10px 60px rgba(192, 195, 200, 0.2);
    border-radius: 9px;
  }
  .head {
    border-bottom: solid 1px #eeeff3;
    padding: 20px 25px 17px;
  }
  form {
    padding: 25px;
  }
  h3 {
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 15px;
    color: #212121;
  }
  .form-group {
    margin-bottom: 20px;
  }
  label {
    display: block;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #464e5f;
    font-family: "Poppins", sans-serif;
  }
  input,
  select,
  textarea {
    width: 100%;
    outline: none;
    background: #f3f6f9;
    border-radius: 4px;
    border: none;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    // color: #b5b5c3;
    font-family: "Poppins", sans-serif;
  }
  button {
    outline: none;
    cursor: pointer;
    padding: 8px 32px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    font-family: "Poppins", sans-serif;
    border: none;
    color: $color-white;
    background-color: #3699ff;
  }
}

.documentGrc {
  .container {
    width: 70%;
    margin: 20px auto;
    border: 1px solid #000;
    padding: 20px;
  }
  .header {
    text-align: center;
    margin-bottom: 2rem;
    h1 {
      margin: 0;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  .form-group {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    label {
      width: 130px;
      font-weight: bold;
      font-size: 13px;
    }
  }
  .section-title {
    margin-top: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 1rem;
  }
  .flex-row {
    display: flex;
  }
  .bottom-line {
    border-bottom: 2px solid #000;
    width: 100%;
    // padding-top: 10px;
  }
  .box {
    width: 220px;
    height: 220px;
    border: 2px solid #000;
  }
  .instructions {
    font-size: 0.9rem;
  }
}
