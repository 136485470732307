@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
.userProfile {
  margin-top: 1rem;
  padding: 10px 0 20px;
  background-color: #f7f7f7;
  .col-left {
    width: 363px;
    position: sticky;
    top: 2%;
    overflow-y: auto;
    height: 90vh;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-shadow: 0px 0px 10px #f6f6f6;
    border-radius: 6px;
    h3 {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #181c32;
      margin-bottom: 20px;
    }
    .wrap {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.02);
      border-radius: 12px;

      .logoutButton {
        border: 1px solid #00a2ff3f;
        font-size: 15px;
        width: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #00a3ff;
        font-family: $font-family-body;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: transparent;
        padding: 10px 0px;
        transition: all 1s ease;
        font-weight: 600;
        &:hover {
          background-color: #04a3ff27;
        }
        img {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .profile-inner {
    position: relative;
    padding: 30px;
    .editBtn {
      color: #fff;
      padding: 2px 7px;
      border-radius: 4px;
      font-size: 0.8rem;
      svg {
      }
    }
    .addGstButton {
      color: #002bff;
      padding: 0.03rem 0.3rem;
      font-family: "Poppins", sans-serif;
      border: 1px solid #00a3ff;
      border-radius: 4px;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .gstDetailsAdd {
      .form-group {
        margin: 0;
        margin-top: 0.3rem;
      }
      label{
        color: #000;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 4px;
      }
      input {
        padding: 0 0.5rem;
        text-align: left;
        font-size: 0.8rem;
        letter-spacing: 0.7px;
        width: 100%;
        border-color: #00a3ff61 !important;
        color: #000;
        &::placeholder {
          color: #000000a4;
        }
      }
    }
    .user_Sidebar_closeBtn {
      display: none;
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 0.2rem 0.5rem;
    }

    figure {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      position: relative;
      margin: 0 auto 15px;
      overflow: hidden;
      border: 2px solid #00a3ff;
      label {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      i {
        display: none;
      }
      &.active {
        opacity: 0.5;
        scale: 1.2;
        box-shadow: 0px 10px 30px #00a3ff;
        margin-bottom: 20px;
        i {
          position: absolute;
          z-index: 999;
          color: white;
          font-size: 1.7rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          user-select: none;
          pointer-events: none;
          display: block;
        }
        label {
          cursor: pointer;
        }
      }

      &.active::after {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
        user-select: none;
        pointer-events: none;
      }
      img,
      input {
        width: 100%;
        height: 100%;
      }
      span {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        bottom: 3px;
        &.active {
          background-color: #50cd89;
        }
      }
    }
    input {
      border: 0;
      outline: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #181c32;
      font-family: $font-family-body;
      text-align: left;
      text-align: center;
      background: transparent;
      border-radius: 4px;
      &.activeInput {
        border-bottom: 1px solid #000 !important;
        border-radius: 0 !important;
        box-shadow: 0px 0px 40px #0000002d;
        scale: 1.1;
        background: #fff;
        margin-bottom: 0.4rem;
      }
    }
    h4 {
      margin-top: 13px;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #181c32;
      font-family: $font-family-Poppins;
      text-align: left;
      margin-bottom: 15px;
      &:first-of-type {
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 0;
      }
    }
    .customerId {
      color: rgb(67, 67, 68);
      margin-bottom: 20px;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      span {
        position: static;
        color: rgba(70, 78, 95, 1);
      }
    }
    .list {
      background: #ffffff;
      border: 1px dashed #e4e6ef;
      border-radius: 4px;
      padding: 5px 10px;
      margin-bottom: 13px;
      position: relative;
      input {
        text-align: start;
        border-radius: 4px;
        border: 0;
        background: transparent;
        font-family: $font-family-body;
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        outline: none;
        padding: 0 4px;
      }
      h6{
        color: #000;
        font-size: 14px;
        padding: 0px 4px;
      }
      p {
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        color: #3d3d3f;
        font-family: $font-family-body;
      }
      .tick {
        position: absolute;
        right: 14px;
        top: 7px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #d7f9ef;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 10px;
          color: #0bb783;
        }
      }
    }
  }
  .wallet {
    background-color: #c8e3ff;
    padding: 10px 30px;
    .wraps {
      display: flex;
      justify-content: space-between;
    }
    h4 {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
      margin-bottom: 0;
    }
    img {
      margin-right: 7px;
    }
    p {
      background: #e8fff3;
      border-radius: 6px;
      padding: 6px 11px;
      font-family: $font-family-Poppins;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #50cd89;
      margin: 0;
    }
    .transferBtn {
      background: #3699ff;
      border-radius: 6px;
      padding: 10px 12px;
      font-family: $font-family-Poppins;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      margin-top: 14px;
    }
  }
  // .referral{
  //   background-color: #ffc8c866;
  //   padding: 20px 30px;
  //   button{
  //     background-color: red;
  //     border: 0;
  //     padding: 3px 10px;
  //     color: #fff;
  //     font-size: 0.7rem;
  //     font-weight: 500;
  //     letter-spacing: .5px;
  //     border-radius: 4px;
  //     &:nth-of-type(1){
  //       background-color: #3699ff;
  //     }
  //   }
  //   .referInput{
  //     margin-top: 1rem;
  //     input{
  //       outline: 0;
  //       width: 75%;
  //       border-radius: 4px;
  //       border: 1px solid #00000021;
  //       padding: 3px 10px;
  //     }
  //   }
  // }
  .bottom {
    padding: 20px 30px;
    align-items: center;
    justify-content: space-between;
    .form_group {
      align-items: center;
    }
    input {
      width: 20px;
      height: 20px;
      border: 1px solid #b5b5c3;
      border-radius: 4px;
      margin-right: 8px;
    }
    label {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #7e8299;
    }
    .dlt-btn {
      background: #f1416c;
      border-radius: 6px;
      padding: 10px 12px;
      font-family: $font-family-Poppins;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
    }
  }

  .col-right {
    width: calc(100% - 363px);
    padding-left: 30px;
    .heading {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      h3 {
        font-family: $font-family-body;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #181c32;
      }
      select {
        background: #ffffff;
        border-radius: 6px;
        padding: 7px 4px;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #505052;
        border: solid 1px #fff;
        outline: 0;
      }
    }
  }
  .bookingList {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 12px;
    margin-bottom: 20px;
    figure {
      width: 210px;
      height: 200px;
      padding: 10px;
      border-radius: 12px;
      margin-bottom: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    .content {
      width: calc(100% - 220px);
      padding-left: 5px;
      justify-content: space-between;
    }
    .left-content {
      padding-top: 15px;
      width: calc(100% - 190px);
      .showfoodButtons {
        button {
          margin-bottom: 10px;
          padding: 3px 6px;
          border-radius: 2px;
        }
      }
    }
    h2 {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      color: #181c32;
    }
    p {
      font-family: $font-family-body;
      font-weight: 400;
      font-size: 13px;
      line-height: 28px;
      color: #5e6278;
      margin-bottom: 2px;
    }
    .address {
      // margin-top: 3px;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #b5b5c3;
    }
    .right-content {
      border-left: solid 1px #d9dbe4;
      width: 190px;
    }
    .bookId {
      padding: 20px 20px 30px 20px;
      text-align: right;
      border-bottom: 1px solid #d9dbe4;
      p {
        &:first-of-type {
          font-family: $font-family-Poppins;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #5e6278;
          margin-bottom: 5px;
        }
        &:nth-of-type(2) {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          color: #b5b5c3;
          font-family: $font-family-Poppins;
        }
      }
    }
    .amount {
      text-align: right;
      padding: 15px 19px 19px 5px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      p {
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 10px;
        text-align: end;
        &.pending {
          color: #f7c000;
        }
        &.success {
          color: #50cd89;
        }
        &.cancel {
          color: #f1416c;
        }
        &.no-data {
          color: #181c32;
        }
      }
      h6 {
        font-size: 0.9rem;
      }
    }
  }
  .btnWraps {
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px 10px 0;
    border-top: 1px solid #d9dbe4;
    button {
      border-radius: 6px;
      border: none;
      padding: 6px 12px;
      font-family: $font-family-Poppins;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      &:first-of-type {
        background-color: #f1faff;
        color: #00a3ff;
      }
      &:nth-of-type(2) {
        background: #e8fff3;
        color: #50cd89;
      }
    }
  }
  .bothBtns {
    text-align: left;
    button {
      margin-bottom: 10px;
      font-size: 11px;
      &:nth-of-type(2) {
        background-color: #d9214e !important;
        border: solid 1px #d9214e !important;
      }
    }
  }
  .cancelReason {
    width: 100%;
    margin-top: 10px;
  }
}

// popup section start here
.popup-second {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 50px 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  .wraps {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 10px;
    padding: 25px 30px 1px;
    width: 505px;
    position: relative;
  }
  .closeBtn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffe2e5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      color: #d9214e;
      font-size: 16px;
    }
  }
  h2 {
    font-family: $font-family-body;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #181c32;
    margin-bottom: 20px;
  }
  .form_group {
    width: 100%;
    margin-bottom: 30px;
    input {
      outline: none;
      width: 100%;
      background: #ffffff;
      border: 1px solid #d9dbe4;
      border-radius: 6px;
      padding: 13px 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #a1a5b7;
    }
    button {
      outline: none;
      background: #00a3ff;
      border-radius: 6px;
      border: solid 1px #00a3ff;
      padding: 12px 30px;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.inner-form-banner {
  margin-top: -75px;
}

.starsRate {
  i {
    cursor: pointer;
  }
}

.ratingReview {
  width: 100%;
}

.hideTabs {
  display: none !important;
}

.reasonCancel {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  label {
    margin-bottom: 0;
    margin-left: 7px;
  }
}
