.detailsWrapper {
  background-color: #fbfbfb;
  padding: 20px 5px 0px;

  .propertyName {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 4px 4px 0px 0px;
    position: relative;
    font-family: $font-family-body;
    padding: 15px 25px;
    margin-bottom: 0;
    a {
      font-size: 0.7rem;
      color: #000;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 1px;
    }
    .sharesBtns {
      a {
        font-size: 1rem;
        color: #fff;
      }
    }
    .shareBtn{
      top: 50%;
      transform: translateY(-50%);
    }
    h2 {
      line-height: 25px;
      font-weight: 500;
      color: #181c32;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }
  }
  .col_ {
    width: 100%;
  }
  .sliderSections {
    background-color: #f7fff6;
    // padding: 10px;
  }
  .mainBook {
    &.detailbook-sec {
      padding: 0;
      .check-sections {
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #d9dbe4;
        padding: 20px;
        li {
          padding: 0 25px;
          text-align: center;
          border-right: solid 1px #d9dbe4;
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            border-right: none;
          }
          p {
            &:first-of-type {
              font-family: $font-family-inter;
              font-weight: 500;
              font-size: 10px;
              line-height: 23px;
              color: #5b5670;
            }
            &:nth-of-type(2) {
              background: rgba(0, 163, 255, 0.2);
              border-radius: 4px;
              padding: 4px 8px;
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              color: #2884ef;
              margin-top: 5px;
            }
          }
        }
        .ratingCal {
          border-radius: 90px;
          background-color: rgba(71, 190, 125, 0.2);
          display: flex;
          align-items: center;
          span {
            background: #47be7d;
            border-radius: 90px;
            padding: 12px 21px;
            font-family: $font-family-body;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.25em;
            color: #ffffff;
          }
          p {
            padding-left: 5px;
            font-family: $font-family-body;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.25em;
            color: #47be7d;
            padding-right: 15px;
            padding-top: 14px;
          }
          button {
            background: #3e97ff;
            border-radius: 90px;
            color: $color-white;
            padding: 11px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
          }
        }
        .col-left,
        .col-right {
          width: auto;
          box-shadow: none;
          border-radius: 0px;
          padding: 0px;
        }
      }
    }
  }
  .big-slider {
    width: 44%;
    height: 342px;
    overflow: hidden;
    z-index: 1;
    .slick-slider {
      height: 100%;
      .slick-slide div div {
        display: block !important;
      }
    }
    .sliderImg {
      margin: 0;
      width: 100%;
      height: 100%;
      // border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border-radius: 10px;
      }
    }
  }
  .showImages {
    width: calc(32% - 16px);
    height: 342px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -4px 8px;
    figure {
      width: calc(50% - 4px);
      box-shadow: 0px 0px 10px #00000011;
      height: 167px;
      margin: 4px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &:last-child {
        position: relative;
        background-color: rgb(0, 0, 0);
        img {
          opacity: 0.4;
        }
        &::after {
          content: "Show All Images";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.4rem;
          text-align: center;
          padding: 20px;
        }
      }
    }
  }
  .otherDetails {
    width: 23%;
    margin-left: auto;
    .map {
      height: 55%;
      iframe {
        height: 100%;
        display: block;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 15px 0px;
      p {
        width: calc(50% - 4px);
        border-radius: 6px;
        font-size: 11px;
        font-weight: 700;
        color: #448700;
        border: 1px solid rgba(67, 135, 0, 0.2588235294);
        background-color: #fff;
        display: flex;
        align-items: center;
        padding: 8px 5px;
        margin-bottom: 12px;
        i,
        svg {
          font-size: 12px;
          margin-right: 4px;
        }
      }
    }
  }
  .navSliderParent {
    width: 28%;
    height: 451px;
    overflow: hidden;
    position: relative;
    .navSlider {
      transform: rotate(90deg);
      position: absolute;
      width: 451px;
      top: 73px;
      left: -93px;
      .sliderImg_tab {
        cursor: pointer;
        margin: 0;
        width: 300px;
        height: 100%;
        img {
          width: 100%;
          height: 300px;
          object-fit: contain;
          object-position: 0 100%;
          vertical-align: middle;
          transform: rotate(-90deg);
        }
      }
    }
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 0;
    outline: none;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:before {
      content: "\f053";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: "FontAwesome";
      color: #fff;
      z-index: 99999999999;
      font-size: 14px;
    }
    &.slick-prev {
      background-color: #3e97ff;
      left: 15px;
      right: auto;
      z-index: 1;
      &:before {
        // background-image: url("../../images/leftarrowSlide.png");
      }
    }
    &.slick-next {
      background-color: #3e97ff;
      right: 15px;
      &:before {
        content: "\f054";
      }
    }
  }

  .deluxeCard {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
      margin-bottom: 7px;
    }
    li {
      justify-content: space-between;
      margin-bottom: 5px;
      p {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #5b5670;
        font-family: $font-family-Poppins;
        &:nth-of-type(2) {
          color: #a1a5b7;
        }
      }
      &:nth-of-type(2) {
        align-items: center;
        s {
          color: #ff0034;
          display: block;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
        }
        p {
          &:nth-of-type(2) {
            font-family: $font-family-Poppins;
            font-weight: 900;
            font-size: 18px;
            line-height: 21px;
            text-align: right;
            color: #2884ef;
          }
        }
      }
      &:nth-of-type(3) {
        span {
          display: block;
          text-align: right;
          color: #ff0034;
        }
      }
    }
    .btnsWrap {
      justify-content: space-between;
      padding-top: 15px;
      position: relative;
      margin-top: 10px;
      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: calc(100% + 40px);
        height: 1px;
        background-color: #d9dbe4;
      }
      a {
        padding: 10px 20px;
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background: #00a3ff;
        border-radius: 6px;
        &:first-of-type {
          background: #eef6ff;
          color: #00a3ff;
        }
      }
    }
  }
  .cardProp {
    width: 100%;
    height: 240px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .cartBtns {
      cursor: pointer;
      width: 34px;
      height: 34px;
      background: #e1f0ff;
      border-radius: 5px;
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: auto;
      }
    }
  }
  .highlight {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 4px;
    padding: 15px;
    height: 100%;
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 8px;
      color: #181c32;
    }
    li {
      display: flex;
      gap: 0.8rem;
      flex-wrap: wrap;
      padding: 13px 0;
      border-bottom: 1px solid #d9dbe4;
      &:last-of-type {
        border-bottom: none;
      }
      div {
        width: 15%;
        font-size: 0.8rem;
        background-color: #f9f9f9;
        padding: 0 5px;
        margin-bottom: 0;
        img {
          width: 18px;
        }
        h4 {
          font-family: $font-family-Poppins;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #5e6278;
        }
      }
    }
  }
}

.prop-description {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
  border-radius: 0px 0px 4px 0px;
  padding: 15px;
  // margin-top: -7px;
  margin-bottom: 15px;
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #181c32;
    margin-bottom: 15px;
  }
  p {
    font-family: $font-family-Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #181818;
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
    strong {
      display: block;
    }
  }
  h6 {
    color: #000;
    font-size: 0.9rem;
    margin: 0;
  }
  ol {
    li {
      list-style: square;
      border: 0 !important;
      background-color: transparent !important;
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
  }
}

.selects-rooms {
  background: #fbfbfb;
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #181c32;
    margin-bottom: 0px;
  }
  .formFilter {
    margin-bottom: 25px;
    li {
      margin-right: 20px;
      background: #ffffff;
      border: 1px dashed #d9dbe4;
      box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
      border-radius: 5px;
      padding: 10px;
      display: flex;
      align-items: center;
      &:first-of-type {
        border: 1px solid #d9dbe4;
      }
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border: 1px solid #b5b5c3;
        border-radius: 4px;
        margin-right: 9px;
        appearance: none;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          //   background-image: url("../../images/tickIcon-multicheckbox.png");
          width: 10px;
          height: 7px;
          background-repeat: no-repeat;
          opacity: 0;
          visibility: hidden;
          transition: all 0.25s ease;
        }
        &:checked {
          background: #23232e;
          border: 1px solid #3f4254;
          &:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      label {
        margin-bottom: 0px !important;
      }
      select {
        font-family: $font-family-body;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #464e5f;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .roomsBox {
    width: calc(33.33% - 1rem);
    background: #ffffff;
    // box-shadow: 0px 4px 30px rgb(0 0 0 / 20%);
    border-radius: 4px;
    padding: 0;
    margin-bottom: 10px;
    .col_ {
      width: 100%;
      .sold-out {
        width: 150px;
        height: 100px;
      }
    }
    .roomSlider {
      width: 100%;
      height: 200px;
      .sliderImg {
        width: 100%;
        height: 200px;
        border-radius: 10px 10px 0 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px 10px 0 0;
        }
      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 0;
        outline: none;
        border: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        &:before {
          content: "\f053";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-family: "FontAwesome";
          color: #fff;
          z-index: 99999999999;
          font-size: 14px;
        }
        &.slick-prev {
          background-color: #3e97ff;
          left: 15px;
          right: auto;
          z-index: 1;
        }
        &.slick-next {
          background-color: #3e97ff;
          right: 15px;
          &:before {
            content: "\f054";
          }
        }
      }
    }
    .buttons {
      padding: 6px 16px;
      button {
        display: flex;
        background-color: transparent;
        flex-direction: column;
        align-items: center;
        font-family: $font-family-body;
        padding: 3px 0.7rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        border-radius: 6px;
        transition: all 0.25s ease;
        border: solid 1px #00a3ff;
        box-shadow: 0px 10px 10px #00a2ff21;
        color: #00a3ff;
        &:not(:last-child) {
          pointer-events: none;
          border-color: gray;
          color: gray;
          del {
            color: rgb(255, 181, 181);
          }
        }
        span {
          text-align: center;
          font-size: 0.8rem;
        }
        del {
          color: red;
          font-size: 0.66rem;
          font-weight: 600;
          margin-left: 3px;
        }
        &:hover {
          background: #00a3ff;
          color: #fff;
        }
        &:active {
          scale: 0.9;
        }
        span {
          &:nth-of-type(2) {
            font-size: 0.7rem;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
    .roomDetails {
      padding: 10px;
      margin-bottom: 5px;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      .lists {
        li {
          align-items: center;
          margin-bottom: 15px;
          img {
            width: 18px;
          }
          p {
            font-family: $font-family-Poppins;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #5b5670;
            padding-left: 11px;
          }
        }
      }
      .amenities {
        h5 {
          font-family: $font-family-Poppins;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #17212d;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          gap: 0.1rem;
        }
        li {
          font-family: $font-family-Poppins;
          font-weight: 500;
          font-size: 10px;
          line-height: 13px;
          color: #000000;
          display: flex;
          width: calc(20% - 0.1rem);
          text-transform: capitalize;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 5px;
          img {
            width: 16px;
            margin-bottom: 0.3rem;
          }
          span {
            font-size: 0.6rem;
            letter-spacing: 0.4px;
            font-weight: 500;
            line-height: 1;
          }
          a {
            font-weight: 600;
            font-size: 10px;
            line-height: 15px;
            text-transform: uppercase;
            color: #2884ef;
          }
        }
      }
    }
    .wraps {
      padding: 10px 16px 0;
      border-bottom: solid 1px #d9dbe4;
    }
    .left-content {
      h3 {
        font-size: 1rem;
        margin-bottom: 3px;
      }
      p {
        font-size: 0.8rem;
        color: #000;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin: 0;
        min-height: 43px;
      }
      li {
        align-items: center;
        margin-bottom: 20px;
        .tick {
          margin-right: 10px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #d7f9ef;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 9px;
            color: #0bb783;
          }
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #5b5670;
          font-family: $font-family-Poppins;
        }
        &.red {
          .tick {
            background-color: #ffe7ee;
            i {
              color: #f1416c;
            }
          }
        }
      }
    }
    .right-content {
      .tags {
        background: #fff8dd;
        border: 1px solid #e9b500;
        border-radius: 86px;
        padding: 12px 10px 0px 10px;
        margin-bottom: 11px;
        width: 160px;
        text-align: center;
        margin-left: auto;
        p {
          font-family: $font-family-Poppins;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          margin-bottom: 10px;
          letter-spacing: 0.03em;
          color: #e9b500;
        }
      }
      li {
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 7px;
        text-align: right;
        &:last-of-type {
          color: #ff0034;
        }
        &:nth-of-type(2) {
          font-family: $font-family-Poppins;
          font-weight: 900;
          font-size: 22px;
          line-height: 26px;
          color: #2884ef;
          s {
            display: block;
            font-family: $font-family-Poppins;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #ff0034;
          }
        }
      }
      .buttonWrap {
        margin-top: 20px;
        margin-bottom: 20px;
        a {
          padding: 12px 30px;
          background: #eef6ff;
          border: 1px solid #00a3ff;
          border-radius: 6px;
          font-family: $font-family-Poppins;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #00a3ff;
          &:nth-of-type(2) {
            margin-left: 20px;
            background: #00a3ff;
            color: $color-white;
          }
        }
        .book {
          width: 155px;
          height: 42px;
          color: white;
          background: #00a3ff;
          border-radius: 6px;
          border: solid 1px #00a3ff;
          transition: all 0.25s ease;
          &:hover {
            background-color: #fff;
            color: #00a3ff;
          }
        }
      }
    }
  }
  // new custom select a rooms
  &.customSelectRooms {
    &.active {
      .activeRoomHeader {
        transition: all 0.5s ease-out;
        box-shadow: 0px 10px 30px #00000023;
        position: sticky;
        top: 0;
        z-index: 2;
        .selectRoomHeader {
          border-radius: 0;
        }
      }
    }
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .selectRoomHeader {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #fff;
      border-radius: 0px;
      margin-bottom: 1rem;
      padding: 10px 0 0;
      align-items: center;
      padding: 15px;
      border-radius: 4px;
      .form-group {
        margin-bottom: 0px;
      }
    }
    .roomsBox {
      .col_ {
        .wraps {
          border-bottom: none;
        }
      }
      .roomSlider {
        height: 220px;
        .sliderImg {
          height: 220px;
        }
      }
    }
  }
}

.policy-section {
  background-color: #fbfbfb;
  padding: 8px 0;
  .policybox,
  .faqbox {
    width: calc(50% - 11px);
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 4px;
    padding: 15px;
    align-self: flex-start;
  }
  .policybox {
    margin-right: 22px;
  }
  .heading {
    position: relative;
    padding-bottom: 2px;
    margin-bottom: 12px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #d9dbe4;
    }
    h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #181c32;
    }
    h5 {
      font-size: 1rem;
    }
  }
  h4 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 21px;
    color: #000000;
    font-family: $font-family-body;
  }
  ul {
    margin-bottom: 27px;
    padding-left: 8px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  li {
    font-family: $font-family-body;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #2d2e33;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #7e8299;
    }
  }
  .moreBtns {
    text-align: right;
    a {
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      text-transform: uppercase;
      color: #2884ef;
    }
  }
  .accordian {
    &.first {
      margin-bottom: 10px;
    }
    .box {
      padding: 5px 10px 1px 0px;
      border-bottom: 1px dashed #e4e6ef;
      &:last-of-type {
        border-bottom: none;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #000000;
    }
    .tab-head {
      align-items: center;
    }
    .icon {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: rgba(181, 181, 195, 0.25);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #b5b5c3;
        font-size: 10px;
      }
    }
    .tab-content {
      padding: 0px 11px 0px 35px;
      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #2d2e33;
      }
    }
  }
}

.popular-section {
  &.similar-section {
    padding-bottom: 70px;
    .explore-box {
      margin-bottom: 0;
    }
  }
}


ul.roomDesctiptionLess{
  display: flex;
  flex-wrap: wrap;
  li{
    width: 50%;
    margin: 0px !important;
    &:empty, &:empty::before{
      display: none;
    }
  }
}

.policybox ul{
  li{
    &:empty, &:empty::before{
      display: none;
    }
  }
}