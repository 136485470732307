.bookConfirm {
  padding: 20px 0 40px;
  background-color: #f2f3f7;
  .goBackRoom {
    border: 1px solid red;
    border-radius: 4px;
    padding: 0.2rem 2rem;
    color: #fff;
    background: red;
    transition: all 0.5s ease-out;
    &:active {
      scale: 0.9;
    }
  }
  .heading {
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      font-family: $font-family-body;
      &.green {
        color: #47be7d;
      }
      &.red {
        color: #ff0000;
      }
      &.yellow {
        color: #c7af0d;
      }
    }
    p {
      margin-top: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-family-body;
      color: #5e6278;
      a {
        color: rgba(54, 153, 255, 1);
      }
    }
    .printBtn {
      background: #7239ea;
      border-radius: 6px;
      padding: 12px 30px;
      cursor: pointer;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
    }
  }
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 12px;
    padding: 20px 30px 35px;
  }
  .book {
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #d9dbe4;
    .col-left {
      h4 {
        font-family: $font-family-body;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #181c32;
      }
      p {
        font-family: $font-family-Poppins;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #5e6278;
      }
    }
    .col-right {
      text-align: right;
      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: rgba(70, 78, 95, 1);
        span {
          color: #b5b5c3;
        }
      }
    }
  }
  .product {
    padding: 20px 0;
    border-bottom: 1px solid #d9dbe4;
    justify-content: space-between;
    .col-left {
      width: calc(100% - 200px);
    }
    h4 {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
      &:first-of-type {
        margin-bottom: 30px;
      }
      &:nth-of-type(3) {
        margin-top: 30px;
      }
    }
    .address {
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #7e8299;
      img {
        margin-right: 8px;
      }
    }
    p {
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: rgba(126, 130, 153, 1);
      span {
        color: rgba(24, 28, 50, 1);
      }
    }
    .col-right {
      width: 200px;
      figure {
        width: 100%;
        height: 200px;
        border-radius: 12px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }
    }
  }
  .details {
    padding: 20px 0 0;
    border-bottom: 1px solid #d9dbe4;
    li {
      width: calc(25% - 7.5px);
      margin-right: 10px;
      background: #ffffff;
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      padding: 9px 13px;
      margin-bottom: 20px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &:nth-of-type(9) {
        width: 74.7%;
      }
      &:last-of-type {
        margin-right: 0;
      }
      p {
        &:first-of-type {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #5e6278;
          margin-bottom: 3px;
        }
        &:nth-of-type(2) {
          font-family: $font-family-Poppins;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #b5b5c3;
        }
      }
    }
  }
  .payments {
    padding: 20px 0;
    border-bottom: 1px solid #d9dbe4;
    h4 {
      margin-bottom: 10px;
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
    }
    .inner {
      background: #ffffff;
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      padding: 20px 13px;
      justify-content: space-between;
      p {
        &:first-of-type {
          font-family: $font-family-Poppins;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #5e6278;
        }
        &:nth-of-type(2) {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #181c32;
        }
      }
    }
    .descrption {
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      p {
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5e6278;
        width: 610px;
      }
      .payBtn {
        background: #47be7d;
        border-radius: 6px;
        border: solid 1px #47be7d;
        padding: 12px 30px;
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }
  .think-section {
    padding-top: 20px;
    h4 {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
      font-family: $font-family-body;
      width: 100%;
    }
    li {
      margin-right: 50px;
      p {
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #b5b5c3;
      }
      &:first-of-type {
        a {
          background: #e8fff3;
          border-radius: 6px;
          font-family: $font-family-Poppins;
          padding: 6px 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #50cd89;
        }
      }
      &:nth-of-type(2) {
        a {
          background: #f1faff;
          border-radius: 6px;
          font-family: $font-family-Poppins;
          padding: 6px 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #00a3ff;
        }
      }
    }
  }
}

.slick-slider .slick-track {
  min-width: 100%;
  display: flex;
}

.explore-box .slick-list {
  padding: 0 !important;
}

.customerReview {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  p {
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.policybox {
  .customerReview {
    border-radius: 0px;
    box-shadow: none;
    border: solid 1px #ddd;
    padding: 12px;
  }
}
