.btn-primary {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 8px 27px;
  border-radius: 6px;
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
