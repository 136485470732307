.form-label {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 3px !important;
}
.form-control {
    font-size: 12px !important;
    border-radius: 4px !important;
    height: 38px !important;
    font-weight: 300 !important;
    border: solid 1px #ddd !important;
    -webkit-appearance: auto !important;
    padding: 0px 10px !important;
}

.form-filter{
    width: auto !important;
    height: 34px !important;
    border-color: #858585 !important;
    font-size: 12px !important;
    padding: 0px 8px !important;
}
.btn-filter{
    line-height: 1.7 !important;
}

@media(max-width: 1024px){
    .form-filter{
        height: 30px !important;
    }
    .table thead th{
        white-space: nowrap;
    }
    .showItemsBtn{
        white-space: nowrap;
    }
}