.unpaid-orders{
    max-height: 340px;
    overflow-y: auto;
    .box{
        width: 100%;
        background-color: #fafafa;
        padding: 10px;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0px;
        }
        h5{
            font-size: 14px;
            input[type=checkbox]{
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
        }
        ul{
            margin-bottom: 0px;
            li{
                font-size: 12px;
                list-style: none;
                line-height: 1;
                border-bottom: solid 1px #f4f4f4;
                padding: 7px 0px;
                margin-bottom: 0px;
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0px;
                }
            }
        }
    }
}

.foodOrderList{
    .order-card{
        .left{
            width: 60%;
        }
        .center{
            width: 20%;
        }
        .right{
            width: 20%;
            text-align: right;
        }
    }
}