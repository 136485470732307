// sider bar
.sidebar {
  width: 240px;
  background-color: #fff !important;
  position: fixed;
  top: 58px;
  left: 0px;
  height: 100vh;
  overflow: auto;
  transform: translateX(-240px);
  transition: transform 0.4s ease;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 10px #a4a4a4;
  padding: 10px 0px;
  .closeSidebarBtn {
    display: none;
    cursor: pointer;
    display: none;
    top: 5px;
    right: 8px;
  }

  &.propertyUser {
    background-color: #2f3349;
  }
  figure{
    display: none;
  }
  .left-nav {
    li {
      white-space: nowrap;
      border-bottom: solid 1px #eeeeee;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      padding: 12px 15px;
      .dashboard-btn {
        background-color: transparent;
      }
      a {
        display: flex;
        align-items: center;
        line-height: 1.25em;
        color: #000;
        transition: all 0.25s ease;
        border: none;
        outline: none;
        width: 100%;
        cursor: pointer;
      }
      img {
        margin-right: 15px;
      }
      i,
      svg {
        color: #000;
        font-size: 15px;
        margin-right: 10px;
      }
      .master-group{
        svg{
          width: 16px;
          font-size: 12px;
          margin: 0px;
        }
      }
      ul {
        padding: 0px;
        display: none;
        padding-top: 12px;
        li {
          border-bottom: none;
          a{
            font-size: 12px;
          }
        }
      }
      button{
        background-color: transparent;
        width: calc(100% - 30px);
        svg{
          font-size: 12px;
          margin-right: 0px;
        }
      }
  }
}
}

.sidebar .left-nav ul ul li {
  border-bottom: none !important;
}
.sidebar .left-nav li div li {
  border-bottom: none !important;
}
.sidebar ul div li {
  border-bottom: none !important;
}

.dashboard-wrap {
  width: 100%;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f6f6f6;
  // main content

  .openSidebarBtn {
    cursor: pointer;
    padding: 5px;
    img{
      width: 24px;
      opacity: 0.6;
    }
  }
  .searchFilter {
    // margin-right: 20px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .css-13cymwt-control,
    .css-t3ipsp-control {
      min-height: 0px;
      line-height: 0px;
      padding: 0;
    }
    .css-1dimb5e-singleValue {
      overflow: visible;
    }
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
    .css-1fdsijx-ValueContainer {
      width: 150px;
    }
    .css-1nmdiq5-menu {
      width: 300px;
      right: 0;
    }
    .react-datepicker__year-select,
    .react-datepicker__month-select {
      width: 100%;
      border: 1px solid #a6a6a6;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      color: $color-black;
    }
    &.hideDate {
      .react-datepicker {
        .react-datepicker__month,
        .react-datepicker__day-names {
          display: none;
        }
      }
    }
    label {
      margin: 0;
      font-size: 0.75rem;
      letter-spacing: 0.5px;
      color: #000000c9;
      font-weight: 600;
      font-family: $font-family-body;
    }
    input,
    select {
      outline: 0;
      border-radius: 5px;
      padding: 1px 5px;
      border: 1px solid #0000004d;
    }
  }
  .main-content {
    width: 100%;
    height: 100vh;
    overflow: auto;
    transition: width 0.4s ease;
    position: relative;
    padding: 60px 25px;
    .main-router {
      background-color: #fff;
      padding: 20px;
    }
    figure.headerLogoAdmin {
      display: flex;
      align-items: center;
      margin: 0;
      .logo-box {
        padding: 5px;
        border-radius: 5px;
        background-color: #1b1b28;
        display: none;
        align-items: center;
        display: flex;
        margin-left: 10px;
        img {
          width: 32px;
        }
        h2 {
          font-size: 22px;
          color: #ffffff;
          margin-left: 10px;
          margin-bottom: 0;
          text-transform: uppercase;
          &:first-letter {
            color: #ff9d25;
          }
        }
      }
    }
  }
  .round-card {
    // position: relative;
    // padding: 1rem 0.5rem;
    // border-radius: 0px;
    // background-color: #ffffff;
    // box-shadow: 0px 0px 30px rgba(56, 71, 109, 0.09);
    .activity-wrapper {
      margin-top: 5px;
      overflow: hidden;
      transition: all 0.5s ease-in;
      font-family: $font-family-body;
      font-size: 1rem;
      letter-spacing: 0.2px;
      table {
        font-size: 0.7rem;
        font-weight: 600;
        letter-spacing: 0.3px;
        $table-border: #0000005b;
        border: 1px solid $table-border;

        th {
          font-size: 0.8rem;
          border-top: 1px solid $table-border;
        }
        td,
        th {
          border-bottom: 1px solid $table-border;
          border-right: 1px solid $table-border;
          padding: 3px;
        }
      }
    }
    .showMorebtn,
    .printBtn {
      padding: 1px 5px;
      font-size: 0.85rem;
    }
    .printBtn {
      position: absolute;
      top: 2%;
      right: 2%;
    }
  }
  .horizontal-line {
    background-color: #e5eaee;
    border-width: 2px;
  }
  .booking-tabs {
    display: flex;
    padding: 8px 16px;
    background-color: #ffffff;
    justify-content: space-between;
    border-top: 1px solid #eeeff3;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
    .tabs {
      border: 0px !important;
      .nav-link {
        border: 0px !important;
        color: #959cb6 !important;
        &.active {
          color: #434349 !important;
        }
      }
    }
  }
  .all-bookings {
    width: 100%;
    height: auto;
    .customer-booking-details {
      .foodShowOrders {
        max-height: 300px;
        overflow: auto;
        &::-webkit-scrollbar-thumb {
          border: 4px solid transparent;
          border-radius: 9px;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }
      }
      .btn-outline-primary {
        background: #eef6ff;
        border: 1px solid #3e97ff;
        border-radius: 6px;
        padding: 4px 8px;
        font-size: 0.75rem;
        &:hover {
          color: #3e97ff;
        }
        &:active {
          color: #3e97ff;
          background: #eef6ff;
        }
      }
      .btn-success {
        background: #47be7d;
        border-color: #47be7d;
        border-radius: 6px;
        padding: 6px 10px;
        font-size: 0.75rem;
      }
    }
    .bookings {
      margin-top: 0rem;
      // width: calc(100% - 2rem);
      .tabs {
        border: 0px !important;
        .nav-link {
          border: 0px !important;
          color: #000000 !important;
          padding: 8px 12px;
          &.active {
            background: #f5f8fa;
            color: #009ef6 !important;
          }
          // &:first-child {
          //   border-right: 1px solid #EBEDF2 !important;
          // }
        }
      }
    }
  }
}

.room-card {
  padding: 0.7rem 0.8rem;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #f8f9ff;
  border: 1px dashed #d8d8d8;
  width: 15.333%;
  margin-right: 1%;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  .people-card {
    border-radius: 16px;
    padding: 2px 10px;
    border: 1px solid #d8d8d8;
  }
  .checkbox {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
  }
  .onlyAssignRoom {
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.5rem;
    font-weight: 600;
    background-color: yellow;
    transform: rotate(-45deg) translate(-30%, -30%);
    padding: 0 10px;
  }
}

.dashboard {
  background-color: #ffffff63;
}

// bread crumbs
.breadcrumb {
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem !important;
  background-color: $color-white;
  // box-shadow: 0px 10px 10px #0000000a;
  .react-datepicker__input-container input {
    width: 120px;
    margin-right: 0.5rem;
  }
  ul {
    margin-bottom: 0;
    li {
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: #37383c;
      padding-right: 30px;
      border-right: 1px solid #ebedf2;
      &:not(:first-of-type) {
        padding-left: 15px;
      }
      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: #434349;
      }
      &:nth-of-type(2) {
        border-right: none;
      }
    }
  }
  .addnewBtn {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    padding: 8px 20px;
    background: #3699ff;
    border-radius: 6px;
  }
}

// fixed icons
.iconsFixed {
  width: 50px;
  background-color: $color-white;
  padding: 10px 8px;
  box-shadow: -25px 0px 30px rgba(0, 0, 0, 0.02);
  position: fixed;
  right: 0;
  top: 250px;
  z-index: 999;
  display: none;
  li {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      background: #f4f4f8;
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.main-content-section {
  padding: 5px 0px;
  .container {
    max-width: 98.5% !important;
    margin: 0;
    padding: 0;
    .row:not(.addBookingRow) > * {
      padding: 0 !important;
    }
    .addBookingRow {
      .heading {
        font-size: 1.1rem;
        color: #000000bb;
        text-align: left;
        line-height: 1.3em;
      }
    }
  }
  .form-select[name="table-pagination-options"] {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .input-group {
    input {
      font-size: 0.8rem;
      font-weight: 500;
      border-right: none !important;
      border-color: #00000027 !important;
    }
    button {
      background-color: transparent !important;
      border-left: none !important;
      border-color: #00000027 !important;
      svg {
        color: #000;
        font-size: 0.8rem;
      }
    }
  }
  .ButtonGroup__root.btn-group {
    button {
      border-color: #00000027 !important;
      font-size: 0.8rem;
      font-weight: 500;
      color: #000;
    }
  }
}

button {
  border: none;
  outline: none;
}

.color-black {
  color: #3f4254 !important;
}

.color-gray {
  color: #5b5670 !important;
}

.color-orange {
  color: #ff9d25 !important;
}

.color-brown {
  color: #8d5f3d !important;
}

.color-success {
  color: #50cd89 !important;
}

.color-primary {
  color: #2884ef !important;
}

.color-yellow {
  color: #e9b500 !important;
}

.fs-75 {
  font-size: 0.75rem !important;
}

// tax and rooms start here
.taxRooms {
  padding: 25px;
  button {
    padding: 8px;
    align-self: flex-end;
  }
}

.couponForm {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

// up down buttons
.BtnsWrapBoth {
  display: flex;
}
.BtnUp {
  width: 40px;
  margin-left: 8px;
  border-radius: 0.25rem;
}
.BtnDown {
  width: 40px;
  margin-right: 8px;
  border-radius: 0.25rem;
}

// comman css
.starsLists {
  margin-bottom: 10px;
  .rr--pointer .rr--box {
    flex-grow: 0;
    width: 22px;
    svg {
      width: 20px;
      height: 20px;
      padding: 0;
      flex-grow: 0;
    }
  }
}

.textLeft {
  p {
    text-align: left;
  }
  h6 {
    text-align: left;
    &.success {
      color: #50cd89;
    }
  }
}

.bulkBooking {
  label {
    cursor: pointer;
    text-decoration: underline;
    color: #000;
  }
  .innerBulkBooking {
    display: flex;
    align-items: center;
    li {
      margin-right: 10px;
      &:first-of-type {
        i {
          color: #0fc10f;
        }
      }
      i {
        font-size: 18px;
        color: #3b3b3b;
      }
    }
  }
}

.categoryWrap {
  &.menuCardWrap {
    li {
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 20px;
      margin-left: 0;
      padding: 0;
      border-radius: 10px;
      position: relative;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      img {
        border-radius: 10px;
        width: 100%;
      }
      .dltIcon {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        background-color: red;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          color: #fff;
        }
      }
    }
  }
}

.fullImgModal {
  img {
    width: 100%;
  }
}

.useCode {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  margin-top: 10px;
  color: green;
}

header {
  // &.propertyUserHeader {
  //   background-color: #fff;
  //   box-shadow: 0px 0px 5px #d9d9d9;
  //   border-bottom: solid 1px #ddd;
  //   padding: 8px 10px;
  //   margin-bottom: 15px;
  // }
  &.dash-header {
    background-color: #fff;
    box-shadow: 0px 0px 5px #d9d9d9;
    border-bottom: solid 1px #ddd;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    right: 0px;
    width: calc(100% - 240px);
    height: 60px;
    z-index: 10;
    transition: width 0.4s ease;
    padding: 0px 15px;
    margin-bottom: 15px;
    &.header-lg {
      width: 100%;
      .openSidebarBtn {
        margin-left: 12px;
      }
    }
    .openSidebarBtn {
      width: 38px;
      transition: ease-in 0.2s;
      padding: 0px;
    }
  }
}

.btnBlue {
  background-color: #00a3ff !important;
  color: #fff !important;
  border: solid 1px #00a3ff !important;
  transition: all 0.25s ease;
  &:hover {
    color: #00a3ff !important;
    background-color: #fff !important;
  }
}

.btnRed {
  background-color: red !important;
  border: solid 1px red !important;
  color: #fff !important;
  transition: all 0.25s ease;
  &:hover {
    background-color: #fff !important;
    color: red !important;
  }
}

.shareBtn {
  cursor: pointer;
  width: 34px;
  height: 34px;
  background: #434649;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  color: #fff;
  font-size: 1rem;
}
@media(max-width: 767px){
  .shareBtn{
    right: 10px;
  }
}

.sharesBtns {
  width: 34px;
  height: auto;
  background: #434649;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  a {
    margin-bottom: 3px;
    padding: 1px 5px;
    color: #fff;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// popup next prev
.listCard .imageslider {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.452);
    &:before {
      font-family: fontawesome;
      font-size: 20px;
      line-height: 1.25em;
      padding-top: 2px;
    }
    &.slick-prev {
      left: -40px;
      &:before {
        content: "\f053";
      }
    }
    &.slick-next {
      right: -40px;
      &:before {
        content: "\f054";
      }
    }
  }
  .fitImg img {
    height: 450px;
    width: 100%;
  }
}

.banner-form {
  .react-datepicker__navigation {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
  }
  .react-datepicker__navigation-icon {
    position: relative !important;
    top: -4px !important;
    font-size: 20px !important;
    padding: 0 !important;
  }
  .react-datepicker__navigation-icon--previous {
    right: 0px !important;
  }
  .react-datepicker__navigation-icon--next {
    left: 0px !important;
  }
}

// full menu card css
.menuCardShowFull {
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 50%;
    }
  }
  .width90 {
    width: 90%;
  }
  .sliderImg {
    border: solid 1px #d5d5d5;
  }
}

.amenities {
  font-size: 0.7rem !important;
}
.userTable {
  thead {
    tr {
      th {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
}

.InvoicecontentPdf {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img,
  input[type="image"] {
    width: 20%;
  }
  span {
    width: 100%;
    text-align: center;
    margin: 0;
    &:nth-of-type(1) {
      font-size: 3.8rem;
      font-family: serif;
      margin-bottom: -10px;
      line-height: 38pxs;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      font-size: 0.9rem;
      font-weight: 500;
      margin: -10px 0;
    }
  }
}

.invoiceInputParent {
  display: inline-flex;
  align-items: center;
  .invoiceInput {
    min-width: 80%;
    height: 20px;
  }
}
.invoiceInputTd {
  td {
    border: 0 !important;
    text-align: center;
    &:nth-child(1) {
      text-align: start;
    }
    input {
      margin-right: 0.4rem;
    }
    label {
      margin: 0;
    }
  }
}
.invoiceInput {
  display: inline;
  height: 25px;
  min-width: 74%;
  background-color: white;
  color: #000;
  border: 0;
  outline: 0;
  border-bottom: 1px dotted rgb(0, 0, 0);
}

.invoice {
  table:not(.headingTable) {
    thead {
      background-color: #1a1a1a;
      color: #fff;
      text-align: center;
    }
    th {
      font-size: 0.87rem;
      font-weight: 500;
      padding: 0 0px;
    }
    td {
      font-size: 0.9rem;
      font-weight: 400;
      border: 1px solid #000;
      padding: 5px 0px 5px 5px;
    }
  }
}
.foodInvoice {
  // .foodPdf {
  //   width: 50%;
  // }
  .gstText {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin-bottom: 1.5rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 0.76rem;
      &:nth-of-type(1) {
        font-size: 2rem;
        font-weight: 600;
      }
      &:nth-of-type(2) {
        margin-top: -2px;
        line-height: 14px;
        width: 90%;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .borderedDiv {
    display: flex;
    border-top: 2px solid black;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    &:last-child {
      border-bottom: 2px solid black;
    }
    p {
      display: flex;
      font-size: 0.8rem;
      font-weight: bold;
      color: black;
      margin-bottom: 0;
      gap: 0.4rem;
      span {
        font-size: 0.88rem;
        font-weight: normal;
        letter-spacing: 0.4px;
      }
      input.noCenter {
        border: 0;
        border-bottom: 1px dotted black;
        outline: none;
        text-align: start;
        min-width: 10px;
        max-width: 100px;
      }
      input:not(.noCenter) {
        min-width: 10px;
        max-width: 50px;
        border: 0;
        outline: none;
        text-align: center;
      }
    }
  }
}
.card:not(.uploadimg-card) {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #475f7b;
  overflow: hidden;
}
.rc-accordion-toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  background-color: #ebedf0;
  transition: 0.3s;
}
.rc-accordion-toggle.active {
  background-color: #5a8dee;
}
.rc-accordion-toggle.active .rc-accordion-icon {
  transform: rotate(180deg);
  color: #fff;
}
.rc-accordion-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}
.rc-accordion-card:last-child {
  margin-bottom: 0;
}
.rc-accordion-title {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  margin-bottom: 0;
  color: #475f7b;
  transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title {
  color: #fff;
}
.rc-accordion-icon {
  position: relative;
  top: 2px;
  color: #475f7b;
  transition: 0.35s;
  font-size: 12px;
}
.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
.rc-accordion-body p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #727e8c;
}
.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.rc-collapse.show {
  height: auto;
}
// comman content start here
.contentMains {
  font-family: $font-family-body;
  padding: 35px 10px 25px;
  text-align: justify;
  h1 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000;
  }
  h2 {
    font-size: 16px;
    color: #000;
    margin-bottom: 3px;
    margin-top: 25px;
  }
  p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #000;
  }
  ul {
    margin-left: 20px;
    li {
      margin-bottom: 15px;
      list-style: inside;
      font-size: 14px;
    }
  }
}
.contactUs {
  iframe {
    height: 100%;
    width: 100%;
  }
  .information_contactPage {
    height: 500px;
    position: relative;
    .icons_contacts {
      position: absolute;
      bottom: 0;
      right: 10%;
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
    }
    padding: 20px;
    ul {
      margin-top: 3rem;
      li {
        list-style: none;
        display: flex;
        gap: 1rem;
        align-items: flex-start;
        margin-bottom: 1rem;
        &:nth-of-type(1) {
          svg {
            color: rgb(255, 102, 0);
          }
        }
        &:nth-of-type(2) {
          svg {
            color: rgb(0, 174, 255);
          }
        }
        &:nth-of-type(3) {
          svg {
            color: rgb(0, 106, 114);
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1450px) {
.sidebar {
  // position: fixed;

  &.sideOpen {
    transform: translateX(0px);
  }
}
.dashboard-wrap.closeBar {
  .openSidebarBtn {
    display: block;
  }
  figure.headerLogoAdmin {
    .logo-box {
      display: inline-flex;
    }
  }
  .main-content {
    // width: calc(100% - 1px) !important;
    width: 100% !important;
  }
}
// }

.foodOrderTabs {
  .tabs {
    border: 0px !important;
    margin-bottom: 0 !important;
    .nav-link {
      border: 1px solid transparent;
      color: #434349 !important;
      font-size: 0.85rem;
      font-weight: 400;
      letter-spacing: 0.5px;
      padding: 3px;
      margin: 0 5px;
      transition: all 0.3s ease-in;
      &.active {
        color: #2150ff !important;
        background: #2151ff0c;
        // border-radius: 4px;
        border: 1px solid #2150ff;
      }
    }
  }
}
.modal_Table.table-bordered {
  tr {
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    padding: 5px;
    td,
    th {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-bottom-width: 1;
      padding: 5px 10px;
    }
  }
}

.bookings .btn-outline-primary {
  background: #eef6ff;
  border: 1px solid #3e97ff;
  padding: 1px 6px;
  font-size: 0.7rem;
  font-weight: 600;
  &:hover {
    color: #ffffff;
  }
  &:active {
    color: #3e97ff;
    background: #eef6ff;
  }
}

.DisableLikeBtn {
  font-size: 0.66rem;
  font-weight: 600;
  color: green;
  margin-bottom: 0;
}

.onboardForm.FoodOrders {
  padding: 0;
}
.addItems {
  .css-b62m3t-container {
    input {
      border: 0 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }
  }
  .incDec {
    border: 1px solid #0000004d;
    box-sizing: border-box;
    input {
      border: 0 !important;
      outline: 0 !important;
      width: 30px !important;
      height: 30px;
    }
    .incDecBtn {
      background-color: #000000c2;
      color: #fff;
      font-size: 1.2rem;
      width: 34px !important;
      height: 30px;
    }
  }
}
.css-1fdsijx-ValueContainer {
  padding: 0 10px;
  .css-qbdosj-Input {
    input {
      min-width: 0 !important;
      background-color: transparent !important;
      height: 100%;
      border: 0px !important;
      float: right;
      text-align: center;
      outline: 0 !important;
    }
  }
}

.customFormControl {
  height: calc(1.2em + 0.7rem + 2px) !important;
  padding: 0.2rem 0.5rem !important;
  font-size: 0.85rem !important;
}
.modal-dialog .btn-close {
  background-color: #ffe2e5;
  border-radius: 50%;
  color: #d9214e;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  width: 24px;
}

.modal_add_property .modal-xl {
  max-width: 950px !important;
}

ul.customecheckbox {
  input[type="checkbox"] {
    display: none;
  }
  li {
    width: calc(20% - 0.2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    input {
      display: none;
    }
    label {
      cursor: pointer;
      padding: 10px 2px;
      font-weight: 400;
      text-align: center;
      height: 100%;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      color: #000c;
      margin: 0;
      user-select: none;
      border: 1px solid #7777776b;
    }
    .activeLable {
      box-shadow: 0px 0px 2px #007bff;
      background-color: #007bff08;
      border: 1px solid #007bff;
      color: #007bff;
    }
  }
}
.paginationOpts__root {
  label {
    display: none;
  }
  .form-select {
    padding: 0 1rem;
    width: 80px;
  }
}
.input-group {
  .form-control {
    height: 30px;
  }
  .btn {
    height: 30px;
    line-height: 0.5;
    padding: 0.375rem 3px;
  }
}
.paginationFoodReport {
  position: fixed;
  bottom: 0;
}

.ButtonGroup__root.btn-group {
  display: flex;
  gap: 0.3rem;
  button {
    border-radius: 4px !important;
    background-color: #fff !important;
    color: #000;
    padding: 0rem 0.5rem;
  }
}
.foodTaxParent input {
  width: 40px;
  border: 0;
  border-bottom: 1px dotted #000;
  text-align: center;
  padding: 0;
}
.theadFoodSection {
  th {
    text-align: center;
    border-right: 1px solid #000 !important;
    &:last-of-type {
      text-align: left;
      border: 0;
    }
  }
}

/* STYLE C */
.checkbox.style-c {
  $color_1: #333;
  $background-color_1: #f7cb15;
  $background-color_2: #eee;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkbox__checkmark {
          background-color: $background-color_1;
          border: 0;
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkbox__checkmark {
          background-color: $background-color_2;
        }
      }
      &:checked {
        ~ {
          .checkbox__checkmark {
            background-color: $background-color_1;
          }
        }
      }
    }
  }
  .checkbox__checkmark {
    position: absolute;
    top: -7px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $background-color_2;
    transition: background-color 0.25s ease;
    border-radius: 4px;
    border: 1px solid #000;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 5px;
      height: 10px;
      border: solid #333;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: opacity 0.25s ease;
    }
  }
  .checkbox__body {
    color: $color_1;
    line-height: 1.4;
    font-size: 16px;
  }
}
.floatingButton {
  position: fixed;
  bottom: 5%;
  right: 5%;
  background-color: red;
  z-index: 2;
}

.activeAddImg {
  box-shadow: 0px 0px 20px #1a7e19;
}
.selectedImg {
  box-shadow: 0px 0px 20px #ff2626;
  border: 2px solid red;
}

.byDateRoomPrice {
  & > button {
    font-size: 0.9rem;
    width: 100px;
    border: 1px solid #00000028;
    color: #000000;
    padding: 2px 0;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0px 0px 4px #11dbff5d;
    margin-bottom: 0.4rem;
    font-family: $font-family-body;
    svg {
      margin: 0 5px 0 !important;
    }
  }

  label {
    margin: 0;
  }
}

.paginationParent {
  display: flex;
  gap: 0.3rem;
  margin-bottom: 0.5rem;
  justify-content: flex-end;
  li {
    border: 1px solid #0000002a;
    padding: 2px 10px;
    box-shadow: 0px 0px 10px #0000002d;
    border-radius: 4px;
  }
}
.activePaginationBtn {
  background-color: #3e97ff;
  color: #fff;
}
.disablePaginationBtn {
  opacity: 0.6;
  a {
    cursor: auto;
  }
}
.selectTableItemsSelect {
  padding: 3px 5px;
  background-color: #fff;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #00000036;
  outline: 0;
}

.todayCheckout {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%);
}
.shimmerEffect {
  width: 150px;
  height: 120px;
  animation: shimmer 2.2s forwards infinite linear;
  background: #ffffff;
  background: linear-gradient(to right, #f0f0f0 8%, #ffffff 18%, #f3f3f3 33%);
  background-size: 1200px 100%;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0px 0px 10px #00000011 !important;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.invoice-for-commision {
  font-family: "poppins";
  color: black;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: black;
  }
}

.invoicePaymentDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "poppins";
  .row {
    border-bottom: 1px solid #0000000e;
    .col:nth-of-type(1) {
      color: #000;
      font-size: 0.8rem;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
    .col:nth-of-type(2) {
      color: #000;
      font-weight: 600;
      font-size: 0.85rem;
      letter-spacing: 0.5px;
    }
  }
}

.reviewAdminSection {
  .reviewCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #0000000a;
    margin-bottom: 0.5rem;
    overflow: hidden;
    box-shadow: 0px 0px 10px #0000001c;
    transition: all 0.5s ease-out;
    &:hover {
      border-color: #009ef6;
      box-shadow: 0px 0px 10px #009ef6;
    }

    h1 {
      font-weight: bold;
      font-size: 22px;
      color: white;
      margin-bottom: 15px;
    }

    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding-left: 0px;
      margin-bottom: 70px;
      li {
        & + li {
          margin-top: 5px;
          margin-right: 2px;
        }
        &:first-child {
          margin-top: 5px;
          margin-right: 2px;
        }
        a {
          color: #8c8aa7;
          border-radius: 10px;
          background-color: #1d1c2d;

          text-decoration: none;
          display: block;
          padding: 10px 20px;
          &.active {
            background-color: #8780f8;
            color: white;
          }
        }
      }
    }
    .comment {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 10px;

      &_header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        &-pic {
          display: flex;
          width: 40px;
          height: 40px;
          background-color: #d56a6a;
          border-radius: 50%;
        }
        h2 {
          font-size: 18px;
          font-weight: bold;
          color: #000;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }
      &_content {
        font-size: 16px;
        color: #000;
      }
      &_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #000000bb;
          letter-spacing: 0.5px;
        }
        button {
          background-color: transparent;
          padding: 8px 10px;
          border: 1px solid #1f1e2d;
          display: flex;
          align-items: center;
          color: #8c8aa7;
          border-radius: 3px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.Module {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  &_Item {
    display: flex;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    flex-direction: column;
    & .mainLi {
      margin-bottom: 1rem;
      background-color: rgba(255, 0, 0, 0.089);
    }
    & label {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      font-weight: 400;
      text-align: center;
      height: 100%;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
      -webkit-user-select: none;
      user-select: none;
      border: 1px solid rgba(119, 119, 119, 0.4196078431);
    }
  }
  &_inner {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    li {
      width: auto;
      margin-bottom: 0.5rem;
    }
  }
}

.modal-body {
  padding: 0.5rem !important;
}

.foodItemsShow {
  font-family: "poppins";
  .outer {
    width: 110px;
    height: 110px;
    border-radius: 40px;
    background: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.219);
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s ease-out;
    color: #11313c;
  }

  .inner {
    padding: 0 !important;
    width: 140px;
    height: 56%;
    object-fit: cover;
    border: 1px solid #f0f0f0;
    border-bottom-right-radius: 80px;
    transform: translateX(-4px) translateY(-5px) rotate(10deg);
  }

  .item-name {
    text-align: center;
  }

  .item-name h4 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    color: #000;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .item-name p {
    font-weight: bold;
    font-size: 12px;
    font-weight: 500;
  }

  .outer:hover {
    transform: scale(1.04);
  }
}

.quantityHandler {
  display: flex;
  border: 1px solid #00000021;
  justify-content: space-between;
  background-color: #fff;
  font-weight: 500;
  font-family: "poppins";
  border-radius: 5px;
  input {
    font-size: 0.8rem;
    width: 30px;
    text-align: center;
    border: 0;
  }
  button {
    width: 35%;
    font-size: 1.2rem !important;
    background-color: rgba(250, 241, 192, 0.63);
  }
}

.summaryCard {
  p {
    font-size: 0.9rem;
    color: #001524;
    margin-bottom: 0.4rem;
    &:last-of-type {
      font-weight: 600;
    }
  }
}
.banquetBookingDetails {
  font-family: "poppins";
  h6 {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }
}

.left-nav {
  ul {
    transition: all 0.5s ease-out;
  }
  .hideSideBarMenu {
    display: grid;
    grid-template-rows: min-content 0fr;
  }
  .showSideBarMenu {
    grid-template-rows: min-content 1fr;
  }
}

.loading-trigger{
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-trigger .loader{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}
.loading-trigger .loader::before{
  content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid transparent;
    border-top-color: #3498db;
    z-index: 100;
    animation: spin 1s infinite;
}
.loading-trigger .loader::after{
  content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid transparent;
    border-top-color: #3498db;
    border: 5px solid #ccc;
}
@keyframes spin{
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
}


.fs-14{
  font-size: 14px;
}

.select-filter{
  background-color: #eeeeee;
  width: auto;
  height: 34px;
  border: none;
  font-size: 12px;
  border-radius: 12px;
}


@media(max-width: 1024px){
  .dashboard-wrap .all-bookings .bookings .tabs .nav-link{
    font-size: 12px;
  }
  .dashboard-wrap .all-bookings .bookings .tabs{
    margin-bottom: 20px;
  }
}