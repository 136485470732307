.scrollTable {
  .table{
    margin-bottom: .3rem;
  }
  &.invoiceTable {
    td{
      text-transform: capitalize;
      
    }
    th {
      min-width: 188px;
      &:last-child {
        min-width: 337px;
      }
    }
  }
  &.isRoomAdmin.invoiceTable {
    th {
      min-width: 188px;

      &:last-child {
        min-width: 100px;
      }
    }
  }

  max-width: 100%;

  &.paymentreport {
    max-height: 84vh;
    th {
      &:nth-of-type(2) {
        min-width: 153px;
      }
    }
  }
  overflow: auto;
  &.propertyUserTable {
    th {
      &:nth-child(1) {
        min-width: 50px;
      }
    }
  }
  th {
    min-width: 142px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    left: 0;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #3e97ff;
    border-radius: 10px;
    border: 5px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #057aff;
  }
}
.common-table,
.food-report.table {
  font-family: $font-family-body;
  text-align: center;
  overflow: auto;
  .showItemsBtn {
    background: #eef6ff;
    border: 1px solid #3e97ff;
    padding: 0px 5px;
    color: #194a81;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 4px;
  }
  th {
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000;
    font-size: 0.7rem;
    padding: 5px !important;
    background-color: rgb(250, 241, 192);
    &:first-child {
      padding-left: 10px;
    }
  }
  tbody {
    tr {
      border-top: 1px solid #e5eaee;
      &:nth-child(even) td {
        background-color: rgb(199 208 208 / 39%);
      }
      td {
        font-size: 12px;
        font-weight: 600;
        padding: 0;
        vertical-align: middle;
        color: #000;
        letter-spacing: 0.1px;
        padding: 3px;
        &:first-child {
          padding-left: 10px;
          text-align: center;
        }
        .statusText {
          font-weight: 500;
          font-size: 0.84rem;
          color: #071f3b;
        }
        &:nth-of-type(1) {
          text-transform: capitalize;
        }
        a {
          font-weight: 500;
          font-size: 0.9rem;
        }
        h6 {
          font-weight: 500;
          font-size: 0.88rem;
        }
        h5 {
          font-weight: 600;
          letter-spacing: 0.5px;
          font-size: 0.7rem;
          font-family: $font-family-body;
        }
        p {
          margin-bottom: 0;
        }
      }
      button {
        font-size: 0.775rem;
      }
    }
  }
}

.common-table.new_table tbody tr td {
  font-size: 0.76rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
