.printBox{
    background-color: #fff;
}

.print-table{
    thead{
        th{
            text-align: center;
            border: none !important;
            padding: 10px 0px !important;
            h2{
                font-size: 28px;
                color: #000;
            }
            p{
                font-size: 14px;
                margin: 0px;
                span{
                    font-weight: 700;
                }
            }
        }
    }
    tbody{
        td{
            border: none !important;
            padding: 2px 0px !important;
            &:first-child{
                font-weight: 700;
            }
            // &:last-child{
            //     text-align: right;
            // }
            p{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                margin: 0px;
                span{
                    font-weight: 700;
                }
            }
        }
    }
    .history{
        border-top: solid 1px #000;
        padding-bottom: 15px;
    }
    .box{
        border-bottom: dashed 1px #000;
        padding: 10px 0px;
    }
}

.printBox.borders{
    border: solid 1px #000;
    padding: 10px;
}
.printInvoice{
    width: 100%;
    table{
        width: 100%;
    }
    th, td{
        vertical-align: top;
    }
    *{
        color: #000;
    }
    h3{
        font-size: 18px;
        font-weight: 700;
        padding: 0px 0px 10px;
        margin: 0px;
    }
    h4{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0px;
    }
    p{
        font-size: 12px;
        padding: 0px;
        margin: 0px;
    }
    .one{
        width: 50%;
        vertical-align: top;
        padding: 10px 0px;
        p{
            span{
                width: 160px;
                font-weight: 600;
                display: inline-block;
            }
        }
        th, td{
            font-size: 11px !important;
            border: none !important;
            line-height: 1.4 !important;
            padding: 2px 0px !important;
        }
        th{
            width: 150px;
        }
    }
    .two{
        th, td{
            border: solid 1px #3e3e3e !important;
            font-size: 12px !important;
            line-height: 1.2 !important;
            padding: 2px 5px !important;
        }
    }
    .three{
        vertical-align: top;
        padding: 20px 0px;
        padding-right: 20px;
        h4{
            font-size: 14px;
            margin-bottom: 0px;
        }
        th, td{
            border: solid 1px #3e3e3e !important;
            font-size: 12px !important;
            line-height: 1.2 !important;
            padding: 5px 5px !important;
            span{
                font-weight: 600;
                display: inline-block;
                margin-right: 20px;
            }
        }
    }
    .four{
        th, td{
            border: solid 1px #3e3e3e !important;
            font-size: 12px !important;
            line-height: 1.2 !important;
            text-align: right;
            padding: 5px 5px !important;
        }
    }
    .five{
        th, td{
            font-size: 12px !important;
            border: none !important;
            line-height: 1.4 !important;
            padding: 4px 0px !important;
        }
        th{
            width: 150px;
        }
    }
}

.print-input-box{
    width: 70px;
    height: 10px;
    outline: none;
    border: none;
    border-bottom: dashed 1px #000;
}

.align-top th, .align-top td{
    vertical-align: top !important;
}

.print-table th.border-1, .print-table td.border-1{
    border: solid 1px #000 !important;
}
.print-table th.border-2, .print-table td.border-2{
    border: solid 2px #000 !important;
}
.print-table th.border-3, .print-table td.border-3{
    border: solid 3px #000 !important;
}
.print-table th.border-4, .print-table td.border-4{
    border: solid 4px #000 !important;
}
.print-table th.border-5, .print-table td.border-5{
    border: solid 5px #000 !important;
}


.print-table h3, .print-table h4, .print-table h5, .print-table h6{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}
.print-table p + h3, .print-table p + h4, .print-table p + h5, .print-table p + h6{
    padding-top: 15px;
}

.inner-table{
    width: 100%;
}
.inner-table th, .inner-table td, .inner-table tbody td p{
    font-size: 12px;
}
.inner-table th{
    background-color: #f7f7f7;
    border-top: solid 1px #dee2e6;
    border-bottom: solid 1px #dee2e6;
    padding: 0px !important;
}
.inner-table tbody td{
    padding: 5px 0px !important;
}
.inner-table tbody td p{
    line-height: 1;
}

// .printBox{
//     overflow-y: auto;
//     height: 100%;
// }

.voucherPrint p {
    font-size: 12px !important;
}