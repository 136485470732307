@include breakpoint(xxxmedium) {
  .headerLanding .headerTop .container {
    max-width: 100%;
  }

  .banner-form .wrapper {
    margin-top: 0;
  }

  .slider__wrapper {
    height: 100%;
  }

  .banner-form .form-group:nth-of-type(1) {
    width: 28%;
  }

  .choose-section {
    padding: 40px 0 10px;
  }

  .choose-section .heading h2 {
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 1.25em;
  }

  .choose-section .content {
    width: 100%;
    padding: 30px 30px 0;
  }

  .choose-section li span {
    width: 70px;
    height: 70px;
    font-size: 20px;
    line-height: 1.4em;
  }

  .choose-section li h3 {
    font-size: 22px;
    line-height: 1.25em;
    margin-bottom: 10px;
  }

  .choose-section figure {
    width: 100%;
    padding: 0px 40px 0px 10px;
  }

  .footerLanding .top .col:last-of-type p,
  .footerLanding .top .col:last-of-type ul {
    padding-left: 40px;
  }

  .footerLanding .top .col:nth-of-type(2),
  .footerLanding .top .col:nth-of-type(3) {
    padding-left: 40px;
  }

  .explore-box .content p {
    margin-bottom: 4px;
  }

  .explore-box .sliderImg figure {
    height: 320px;
  }
}

@include breakpoint(xmedium) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100% !important;
  }

  .headerTop li {
    margin-left: 15px;
  }

  .headerTop figure {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .headerTop .content p {
    font-size: 12px;
  }

  .headerTop .content a {
    font-size: 10px;
    line-height: 1.5em;
  }

  .headerTop .loginButton,
  .headerTop .logoutButton {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5em;
    min-height: 45px;
  }

  .headerTop .dashboardLogo {
    width: 150px;
    height: 35px;
  }

  .homeBanner h1 br {
    display: none;
  }

  .banner-form .wrapper {
    padding: 15px;
  }

  .banner-form .form-group:nth-of-type(2) {
    border-right: solid 1px #d9dbe4;
  }

  .banner-form form {
    justify-content: center;
  }

  .popular-section {
    padding-top: 40px;
  }

  .exclusive-section h2 {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 1.4em;
  }

  .explore-box .sliderImg {
    width: 100%;
  }

  .explore-box .sliderImg figure {
    height: 280px;
  }

  .explore-box .content p {
    margin-bottom: 3px;
  }

  .explore-section .purchase-box {
    padding: 15px;
  }

  .explore-section .purchase-box .image {
    width: 300px;
  }

  .explore-section .purchase-box .content {
    width: calc(100% - 300px);
    padding-right: 20px;
  }

  .explore-section .purchase-box .content h3 {
    font-size: 24px;
    line-height: 1.4em;
  }

  .footerLanding .top {
    padding-top: 105px;
    padding-bottom: 35px;
  }

  .footerLanding .top .col:nth-of-type(2),
  .footerLanding .top .col:nth-of-type(3) {
    padding-left: 20px;
  }

  .footerLanding .top .col:last-of-type p,
  .footerLanding .top .col:last-of-type ul {
    padding-left: 10px;
  }

  .detailsWrapper .col_:nth-of-type(2) {
    width: 260px;
  }

  .detailsWrapper .col_:nth-of-type(1) {
    width: calc(100% - 260px);
  }

  .selects-rooms .formFilter li {
    margin-right: 10px;
  }

  .selects-rooms .formFilter li:last-of-type {
    margin-right: 0;
  }

  .selects-rooms .roomsBox .wraps h3 {
    font-size: 20px;
  }

  .mainBook {
    padding: 35px 0 50px;
  }

  .mainBook .col-left {
    padding-right: 0;
    width: 100%;
  }

  .mainBook .col-right {
    width: 100%;
    margin-top: 20px;
  }
}

@include breakpoint(xxxmedium) {
  .headerLanding .headerTop .container {
    max-width: 100%;
  }

  .banner-form .form-group:nth-of-type(1) {
    width: 28%;
  }

  .choose-section {
    padding: 40px 0 10px;
  }

  .choose-section .heading h2 {
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 1.25em;
  }

  .choose-section li span {
    width: 35px;
    height: 35px;
    font-size: 17px;
    line-height: 1.4em;
  }

  .choose-section li h6 {
    font-size: 17px;
    line-height: 1.25em;
    margin-bottom: 10px;
  }

  .choose-section figure {
    width: 100%;
    padding: 0;
  }

  .footerLanding .top .col:last-of-type p,
  .footerLanding .top .col:last-of-type ul {
    padding-left: 40px;
  }

  .footerLanding .top .col:nth-of-type(2),
  .footerLanding .top .col:nth-of-type(3) {
    padding-left: 40px;
  }

  .explore-box .content p {
    margin-bottom: 4px;
  }

  .explore-box .sliderImg figure {
    height: 320px;
  }
}

@include breakpoint(xmedium) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100% !important;
  }

  .headerTop li {
    margin-left: 15px;
  }

  .headerTop figure {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .headerTop .content a {
    font-size: 10px;
    line-height: 1.5em;
  }

  .headerTop .loginButton,
  .headerTop .logoutButton {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5em;
    min-height: 45px;
  }

  .headerTop .dashboardLogo {
    width: 150px;
    height: 35px;
  }

  .homeBanner h1 br {
    display: none;
  }

  .banner-form .wrapper {
    padding: 15px;
  }

  .city-box {
    .show-city {
      margin-right: 1rem;
    }
  }

  .banner-form .form-group:nth-of-type(2) {
    border-right: solid 1px #d9dbe4;
  }

  .banner-form form {
    justify-content: center;
  }

  .explore-box {
    padding: 20px 0px;
  }

  .exclusive-section h2 {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 1.4em;
  }

  .explore-box .sliderImg {
    width: 100%;
  }

  .explore-box .sliderImg figure {
    height: 200px;
  }

  .explore-box .content p {
    margin-bottom: 3px;
  }

  .explore-section .purchase-box {
    padding: 15px;
  }

  .explore-section .purchase-box .image {
    width: 300px;
  }

  .explore-section .purchase-box .content {
    width: calc(100% - 300px);
    padding-right: 20px;
  }

  .explore-section .purchase-box .content h3 {
    font-size: 24px;
    line-height: 1.4em;
  }

  .footerLanding .top {
    padding-top: 105px;
    padding-bottom: 35px;
  }

  .footerLanding .top .col:nth-of-type(2),
  .footerLanding .top .col:nth-of-type(3) {
    padding-left: 20px;
  }

  .footerLanding .top .col:last-of-type p,
  .footerLanding .top .col:last-of-type ul {
    padding-left: 10px;
  }

  .detailsWrapper .col_:nth-of-type(2) {
    width: 260px;
  }

  .detailsWrapper .col_:nth-of-type(1) {
    width: calc(100% - 260px);
  }

  .selects-rooms .formFilter li {
    margin-right: 10px;
  }

  .selects-rooms .formFilter li:last-of-type {
    margin-right: 0;
  }

  .selects-rooms .roomsBox .wraps h3 {
    font-size: 20px;
  }

  .mainBook {
    padding: 10px 0 50px;
  }

  .hotalList .headerLanding {
    height: 70px;
  }

  .mainBook .col-left {
    padding-right: 0;
    width: 100%;
  }

  .mainBook .col-right {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 640px) {
  .sort-fillter {
    select {
      padding: 2px !important;
    }
  }

  .listing-wrapper {
    background-color: #d6d6d6;
    padding: 15px 5px 20px;
  }

  // .banner-form {
  //   .wrapper {
  //     padding: 5px;

  //     .form-group {
  //       padding: 5px 3px;

  //       &:nth-of-type(4) {
  //         padding: 0px 1px;
  //       }

  //       img {
  //         margin: 0 3px;
  //       }
  //     }

  //     input {
  //       padding: 0px 0;
  //       font-size: 10px;
  //       font-weight: 600;
  //       letter-spacing: 0.5px;
  //       text-align: center;
  //     }

  //     select {
  //       padding: 4px 0;
  //       font-size: 10px;
  //       font-weight: 600;
  //       letter-spacing: 0.5px;
  //       text-align: center;
  //     }

  //     button {
  //       padding: 0px 5px;
  //       font-size: 11px;
  //       font-weight: 600;
  //       letter-spacing: 0.5px;
  //     }
  //   }
  // }

  .city-box {
    .show-city {
      figure {
        width: 54px !important;
        height: 54px !important;
        margin: 0 auto 10px !important;
      }
    }
  }

  .exclusive-section {
    .wrap {
      border-radius: 0;
      padding: 10px;
      align-items: center;
      justify-content: space-between;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 0;
      padding: 15px;
      border-radius: 0;
    }

    .box {
      padding: 10px;
      border-radius: 0px;

      h4 {
        font-size: 17px;
        margin-bottom: 5px;
      }
    }
  }

  .choose-section {
    li {
      span {
        width: 50px;
        height: 50px;
      }

      h3 {
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
  }

  .explore-section .purchase-box {
    justify-content: space-between;

    .content {
      h3 {
        font-size: 14px;
      }
    }

    .image {
      ul {
        grid-template-areas:
          "top main "
          "bottom main";
        grid-template-columns: 2fr 1fr;

        li {
          &:nth-child(1),
          &:nth-child(2) {
            width: 170px;
          }

          width: 110px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .headerTop {
    li {
      .content {
        a {
          display: none;
        }
      }
    }

    figure {
      width: 20px;
    }
  }

  .headerTop .loginButton,
  .headerTop .logoutButton {
    width: 34px;
    min-height: 34px;
    padding: 0px 5px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  

  .banner-form {
    .wrapper {
      .form-group {
        img {
          margin: 0 3px;
        }
      }

      input {
        padding: 0px 0;
        font-size: 10px;
        text-align: center;
      }

      select {
        padding: 14px 0;
        font-size: 10px;
        text-align: center;
      }

      button:not(.react-datepicker__navigation) {
        height: 100%;
        padding: 0px 10px;
      }
    }
  }

  .heading {
    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px !important;
    }

    h4 {
      font-size: 18px !important;
    }
  }

  .explore-section .purchase-box {
    .image {
      ul {
        grid-template-columns: 1fr 1fr;

        li {
          &:nth-child(1),
          &:nth-child(2) {
            width: 110px;
          }

          width: 80px;
        }
      }
    }
  }

  .explore-box {
    .heading {
      margin-bottom: 0;

      h4 {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .sort-fillter {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  // .container {
  //   padding-right: 5px !important;
  //   padding-left: 5px !important;
  // }

  .headerTop {
    .container {
      justify-content: space-between;
    }
    ul {
      margin-left: 0;
    }

    li {
      &:nth-child(2) {
        flex-direction: column;
      }
    }
  }

  .heading {
    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }

    p {
      font-size: 10px !important;
    }

    h4 {
      font-size: 15px !important;
    }
  }

  .exclusive-section {
    .box {
      width: 100%;
    }

    .wrap {
      border-radius: 0;
      padding: 5px;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 0.5rem;
    }
  }

  .choose-section {
    .content {
      li {
        .contentInner {
          width: calc(100% - 50px);
          padding-left: 10px;
        }
      }
    }
  }

  .popular-section .explore-box {
    .sliderImg {
      .content {
        padding: 0px 8px;

        h3 {
          font-size: 16px;

          span {
            font-size: 12px;
          }
        }

        .button-wrap {
          margin-top: 0;

          a {
            padding: 5px 10px;
          }
        }
      }

      figure {
        height: 150px;
      }
    }
  }
}

@media screen and (max-width: 1263px) {
  .footerLanding {
    .top {
      padding-top: 100px;

      .listHotelFooter button {
        padding: 7px 0.5rem;
      }
    }
  }

  .loginSection {
    // height: 110vh;
    .wrapper.loginWrapper {
      gap: 2rem;
      padding-left: 0;
    }
  }

  .detailsWrapper .big-slider,
  .detailsWrapper .showImages {
    height: 445px;
  }
}

@media screen and (max-width: 1201px) {
  .headerTop,
  .popular-section {
    .container {
      max-width: 1200px !important;
    }

    li {
      margin-left: 20px;
    }
  }

  .detailsWrapper .big-slider,
  .detailsWrapper .showImages {
    height: 399px;
  }

  .loginSection {
    .content {
      padding-right: 0;

      h1 {
        margin-bottom: 0.5rem;
        font-size: 2rem;
      }
    }

    .container {
      max-width: 1200px !important;
    }

    .wrapper.loginWrapper {
      gap: 2rem;
      padding-left: 0;
    }
  }

  .hotalList {
    .container {
      max-width: 1200px !important;
    }
  }

  .navSliderParent .navSlider {
    .slick-arrow {
      top: 60%;
    }
  }

  .banner-form .clearSearchBar {
    right: 0;
    transform: translate(-100%, -50%);
  }

  .footerLanding,
  .explore-section {
    .container {
      max-width: 1200px !important;
    }
  }

  .listing-wrapper {
    .rightContant .content .buttons a {
      padding: 5px 0.4rem;
      line-height: 16px;
      font-size: 12px;
    }
  }

  .selects-rooms .roomsBox .buttons button {
    padding: 3px 0.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .headerLanding {
    height: 0;
  }

  

  .detailsWrapper .showImages {
    display: none;
  }

  .appDownloadSection {
    .second {
      h1 {
        font-size: 2.2rem;
      }

      .mobileInput {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  .banner-form.homeSearch {
    margin-top: -6px;
  }

  #filterBtnVis {
    display: block;
  }

  .banner-form {
    .wrapper {
      .form-group {
        span:not(.react-datepicker__navigation-icon) {
          right: 0;
          top: 2px;
          padding: 1px 5px;
          font-weight: 600;
          font-size: 10px;
          line-height: 0px;
          letter-spacing: 0.5px;
        }
      }

      input {
        padding: 0px 0;
        font-size: 13px;
        text-align: center;
      }

      select {
        padding: 10px 0;
        font-size: 13px;
        text-align: center;
      }

      button:not(.react-datepicker__navigation) {
        height: 100%;
        padding: 17px 16px;
      }
    }
  }

  .heading {
    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px !important;
    }

    h4 {
      font-size: 22px !important;
    }
  }

  .userProfile {
    .leftSidebar {
      transform: translateX(-110%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      overflow: auto;
      transition: all 0.7s ease-out;
      width: 316px;

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      .user_Sidebar_closeBtn {
        display: block;
      }

      .wrap {
        border-radius: 0;
        height: 100%;
        background-color: #fff;
      }

      .profile-inner,
      .bottom,
      .wallet {
        padding: 15px;
      }
    }

    .showFilter {
      transform: translateX(0%);
      position: fixed;
      border-radius: 0;
      background: #fff;

      .heading button svg {
        color: #fff;
      }
    }

    .col-right {
      width: 100%;
      padding: 0;
    }
  }

  .listing-wrapper {
    .leftSidebar {
      transform: translateX(-110%);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      z-index: 999;
      height: 100%;
      overflow: auto;
      transition: all 0.7s ease-out;

      .user_Sidebar_closeBtn {
        display: block;
      }
    }

    .showFilter {
      transform: translateX(0%);
      position: fixed;
    }

    .rightContant {
      width: 100%;
      padding: 0;

      .content {
        .buttons {
          a {
            padding: 6px 13px;
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
    }
  }

  .selects-rooms .roomsBox {
    .buttons {
      button {
        padding: 3px 0.3rem;
        font-size: 0.8rem;
      }
    }

    .roomDetails .amenities li {
      width: calc(20% - 0.1rem);
    }
  }

  .hotalList {
    .detailsWrapper {
      .container {
        .col_ {
          width: 100%;

          &:last-of-type {
            margin-top: 10px;
          }

          .sliderSections {
            flex-direction: column;

            .big-slider {
              width: 100%;
              height: 500px;
              margin-bottom: 0;

              .sliderImg {
                height: 500px;
              }

              .category_images button {
                padding: 4px 5px;
              }
            }
          }
        }
      }
    }
  }

  .footerLanding {
    .top {
      padding-bottom: 0px;

      .footerTabs {
        width: 25%;
      }
    }
  }

  .loginSection {
    .img {
      width: 320px;
    }

    .form-inner {
      padding: 3px 20px;
    }

    .topbar p {
      padding-left: 5px;
    }

    .wrapper.loginWrapper {
      flex-direction: column;
      gap: 0;

      .content {
        padding-right: 0;
        width: 100%;
        text-align: center;

        p {
          font-size: 0.9rem;
          text-shadow: 0px 0px 10px black;
        }

        h1 {
          font-size: 1.7rem;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .selects-rooms.customSelectRooms .row {
    gap: 0.9rem !important;

    .roomsBox {
      width: calc(50% - 1rem);
    }
  }
}

@media screen and (max-width: 785px) {
  .headerTop .loginButton,
  .headerTop .logoutButton {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 50px;
  }

  .appDownloadSection {
    padding: 30px 0;

    .first {
      ul {
        gap: 1.3rem;

        li {
          font-size: 1.2rem;
        }
      }
    }

    .second {
      margin: 1rem 0;

      h1 {
        br {
          display: none;
        }

        font-size: 2.2rem;
        margin: 0;
      }

      .mobileInput {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        margin-top: 1rem;
      }
    }

    .image {
      margin-top: 1rem;

      ul {
        grid-template-columns: 1fr 1fr;

        li {
          width: 130px;
        }
      }
    }
  }

  

  .banner-form {
    .wrapper {
      .form-group {
        img {
          margin: 0 5px;
        }

        span {
          padding: 1px 3px;
          font-size: 8px;
          top: 0;
        }
      }

      input {
        padding: 0px 0;
        font-size: 11px;
        text-align: center;
      }

      select {
        padding: 14px 0;
        font-size: 11px;
        text-align: center;
      }

      button:not(.react-datepicker__navigation) {
        height: 100%;
        padding: 5px 16px;
      }
    }
  }

  .selects-rooms.customSelectRooms .selectRoomHeader {
    padding: 15px 10px 0;
  }

  .exclusive-section {
    .wrap {
      padding: 0;
    }

    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }
  }

  .listing-wrapper {
    .rightContant {
      .imageslider {
        width: 300px;
        height: 254px;
      }

      .content {
        width: calc(100% - 300px);

        .useCode {
          font-size: 11px;
        }

        .amenities {
          gap: 0rem !important;
        }
      }

      .tags {
        position: static !important;
        gap: 0.5rem;
      }
    }
  }

  .mainBook {
    .tabBtns {
      li {
        padding: 10px;

        h6 {
          font-size: 12px;
        }

        p {
          color: #5a5a5b;
        }
      }
    }

    .filterRes {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 2rem;

      h4 {
        font-size: 1.3rem;
      }

      .fs-6 {
        font-size: 0.8rem !important;
      }
    }
  }

  .bookConfirm {
    .product .col-right {
      width: 150px;
      height: 150px;

      figure {
        height: 100%;
        width: 100%;
      }
    }

    .think-section {
      li {
        margin: 0;
      }
    }
  }

  .contactUs {
    .col-10 {
      max-width: 100% !important;

      .row {
        .col-6 {
          max-width: 100% !important;
        }

        display: flex;
        flex-direction: column;
      }
    }

    iframe {
      height: 300px;
    }

    .information_contactPage {
      padding: 34px 20px;
    }

    ul.icons_contacts {
      margin-top: 2rem !important;

      li {
        margin-bottom: 0rem;
      }
    }
  }

  .userProfile {
    .content {
      .left-content {
        button {
          padding: 0 5px;
        }
      }
    }
  }

  .hotalList {
    .detailsWrapper {
      .container {
        .col_ {
          width: 100%;
          padding: 0;

          &:last-of-type {
            margin-top: 0px;
          }

          .sliderSections {
            flex-direction: column;

            .big-slider {
              width: 100%;
              margin-bottom: 0;

              .category_images button {
                padding: 4px 5px;
              }
            }

            .navSliderParent {
              height: 151px;
              width: 100%;

              .navSlider {
                transform: rotate(0);
                top: 0;
                height: 100%;

                .sliderImg_tab {
                  // height: 100%;
                  height: 144px;

                  img {
                    height: 100%;
                  }
                }
              }
            }
          }

          .highlight {
            padding: 15px;

            li {
              flex-direction: row;
              gap: 0.5rem;

              div {
                padding: 0;
                font-size: 0.67rem;
                font-weight: 500;
                width: calc(20% - 0.5rem);
              }
            }
          }
        }
      }
    }

    .policy-section {
      .container {
        flex-direction: column;
        gap: 1rem;

        .policybox,
        .faqbox {
          width: 100%;
          margin: 0;
        }
      }
    }

    .roomsBox {
      padding: 0;
      border: 1px solid #00000036;

      .roomDetails .amenities li {
        // width: calc(20% - 1rem);
      }

      .wrap {
        .tags {
          padding: 5px 5px 0px 5px;

          p {
            margin-bottom: 5px;
          }
        }
      }

      .roomDetails {
        .amenities {
          h5 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .choose-section li span {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 1em;
  }

  .choose-section li h6 {
    font-size: 14px;
    line-height: 1em;
    margin-bottom: 6px;
  }

  .choose-section .heading p {
    padding: 0 3rem;

    br {
      display: none;
    }
  }

  .loginSection {
    padding: 10px 0;

    .container-lg:nth-child(1) {
      height: 10vh;
    }

    .container {
      margin-top: -5rem;
    }

    .topbar {
      flex-wrap: nowrap;
      gap: 2rem;

      p {
        padding: 0;
        font-size: 0.8rem;
      }
    }

    .wrapper {
      flex-direction: column;
    }

    h1 {
      margin-top: 0.5rem;
      font-size: 1.5rem;
      margin-bottom: 2px;
      text-align: center;
    }

    .img {
      width: 250px;
    }

    .form-wrap {
      width: 390px;

      .termsparagraph {
        margin: 0 10px;
      }

      .head {
        padding: 15px;
        font-size: 0.86rem;
      }

      .form-inner {
        padding: 3px 10px;

        button {
          padding: 5px 15px;
          margin-top: 0;
        }
      }

      h2 {
        font-size: 1.6rem;
        margin-bottom: 0;
      }
    }

    .topbar p {
      padding-left: 5px;
    }

    .wrapper.loginWrapper {
      flex-direction: column;
      gap: 0;
    }

    .wrapper {
      .content {
        padding-right: 0;
        width: 100%;
        text-align: center;

        p {
          font-size: 0.9rem;
          text-shadow: 0px 0px 10px black;
        }

        h1 {
          font-size: 1.7rem;
          margin-bottom: 15px;
        }
      }
    }
  }

  .footerLanding .top .container {
    gap: 1rem;

    .footerTabs {
      width: calc(33% - 1rem);
    }
  }

  .detailsWrapper {
    .prop-description {
      flex-direction: column;

      .w-25,
      .w-75 {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .selectRoomHeader {
    // align-items: start !important;
    // flex-direction: column;
  }

  .itemsCategory {
    li {
      .incDec {
        padding: 3px;

        button {
          width: 35%;
        }

        input {
          width: 30%;
        }
      }

      label {
        padding: 10px;
        font-size: 11px;
        text-transform: capitalize;
      }
    }
  }

  .headerTop {
    .dashboardLogo {
      width: 33px;
      height: 35px;

      h2 {
        display: none;
      }
    }

    li {
      margin-left: 10px;
    }
  }

  .listing-wrapper .rightContant {
    .tags {
      p {
        font-size: 10px !important;
      }
    }

    .listCard {
      .imageslider {
        height: 188px !important;
      }

      .content {
        padding: 0 14px 14px;
      }

      .shareBtn:not(.detailBtn) {
        top: 1.6rem;
        right: 8px;
      }
      .categoryTag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 6px 22px;
        &.reviewBox {
          right: 0;
          left: auto;
          padding-right: 10px;
        }
      }
    }
  }
  .loaderCss .loader-wrapper img {
    width: 50px;
  }
  #lottie {
    height: 200px !important;
    width: 400px !important;
  }

  .exclusive-section {
    .wrap {
      border-radius: 0;
      padding: 10px;
      align-items: center;
      justify-content: space-between;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 0;
      padding: 15px;
      border-radius: 0;
    }

    .box {
      padding: 10px;
      border-radius: 0px;

      h4 {
        font-size: 17px;
        margin-bottom: 5px;
      }
    }
  }

  .loginSection {
    .img {
      display: none !important;
    }

    .content p {
      padding: 0;
    }

    .topbar {
      figure {
        width: 100px;
        height: 58px;
      }

      flex-wrap: nowrap;
      gap: 2rem;

      p {
        padding: 0;
        font-size: 0.8rem;
      }
    }

    .form-wrap {
      width: 100%;

      .form-inner {
        h2 {
          font-size: 1rem;
          margin-bottom: 9px;
        }

        padding: 0.5rem 1rem;

        button {
          padding: 4px 14px;
          font-size: 11px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .choose-section {
    .wrap {
      flex-direction: column;
    }

    li {
      span {
        width: 50px;
        height: 50px;
      }

      h6 {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
  }

  .explore-section .purchase-box {
    justify-content: space-between;

    .content {
      h3 {
        font-size: 14px;
      }
    }

    .image {
      ul {
        grid-template-areas:
          "top main "
          "bottom main";
        grid-template-columns: 2fr 1fr;

        li {
          &:nth-child(1),
          &:nth-child(2) {
            width: 170px;
          }

          width: 110px;
        }
      }
    }
  }

  

  .hotalList {
    .listing-wrapper {
      .rightContant {
        display: flex;
        flex-wrap: wrap;
        gap: 1.2rem;
        justify-content: start;

        .heading {
          width: 100%;
          padding-bottom: 0px;

          h1 {
            font-size: 18px;
          }

          .sort-fillter {
            label {
              display: none;
            }
          }
        }

        .listCard {
          display: block !important;
          padding: 0;
          width: calc(50% - 0.4rem);
          border-radius: 20px 20px 0px 0px;
          overflow: hidden;
          margin: 0;

          .soldOutImg {
            top: 55%;
          }

          .imageslider {
            width: 100%;
            height: 267px;
            border-radius: 20px 20px 0px 0px;
            .slick-arrow {
              &::before {
                font-size: 0.77rem;
              }
            }

            .sliderImg img {
              border-radius: 0;
            }
          }

          .content {
            width: 100%;

            .buttons {
              a,
              button {
                font-size: 12px;
                padding: 5px 8px;
              }
            }

            .price-wrap h3 {
              font-size: 20px;
            }

            .useCode {
              font-size: 9px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .mainBook {
    .tabBtns {
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0;

      li {
        padding: 10px;
        width: 100%;

        h6 {
          font-size: 14px;
        }
      }
    }
  }

  .userProfile {
    .cancelReason {
      .col-8 {
        flex: 1;
        max-width: 100%;

        label {
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
    }

    .col-right {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      justify-content: start;

      .heading {
        width: 100%;
        margin-bottom: 0;
        // padding: 0 15px;

        h1 {
          font-size: 18px;
        }
      }

      h2 {
        font-size: 18px;
      }

      .bookingList {
        display: block !important;
        padding: 0;
        width: calc(50% - 0.4rem);
        margin: 0;

        .soldOutImg {
          top: 55%;
        }

        p {
          font-size: 11px;
          font-weight: 600;

          br {
            display: none;
          }
        }

        a {
          width: 100%;
        }

        figure {
          width: 100%;
          height: 188px;
          padding: 0;
          border-radius: 0;

          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .content {
          flex-direction: column;
          width: 100%;
          padding: 6px;

          .left-content {
            padding: 0;
            width: 100%;

            .showfoodButtons {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 100%;
              margin-bottom: 0;
            }
          }

          .right-content {
            width: 100%;
            display: flex;
            border-left: 0;
            padding: 2px 0px;
            justify-content: space-between;

            .bookId {
              border-bottom: 0;
              padding: 0;

              p {
                text-align: start;
                font-size: 0.8rem;
              }
            }

            .amount {
              padding: 0;

              p {
                font-size: 9px;
                font-weight: 800;
                color: #000;
                margin: 0;
              }

              h6 {
                font-size: 1rem;
              }
            }
          }

          .bothBtns button {
            padding: 3px 5px;
          }
        }
      }
    }
  }

  .selects-rooms .roomsBox .roomDetails .amenities ul {
    gap: 0rem;
  }

  .selects-rooms.customSelectRooms .row .roomsBox {
    width: 100%;
  }

  .hotalList {
    .detailsWrapper {
      .container {
        .col_ {
          .sliderSections {
            .big-slider {
              width: 100%;
              height: 50%;

              .sliderImg {
                height: 295px;
              }

              .category_images {
                padding: 0 5px;
              }
            }

            .navSliderParent {
              height: 110px;
              width: 100%;

              .navSlider {
                .sliderImg_tab {
                  height: 100px;
                }
              }
            }
          }

          .highlight {
            li {
              gap: 0.5rem;

              div {
                font-size: 0.6rem;
                width: calc(25% - 0.5rem);

                img {
                  width: 15px;
                }
              }
            }
          }
        }
      }
    }

    .roomsBox {
      .col_ .wraps {
        flex-direction: column;
        justify-content: flex-start;
      }

      .roomDetails {
        padding: 1px 7px;

        .amenities {
          margin-top: 0.5rem;

          li {
            padding: 0.2rem;
            text-align: center;
            flex-direction: column;

            img {
              width: 15px;
            }

            span {
              font-size: 0.6rem;
            }
          }
        }
      }

      .right-content {
        li:nth-of-type(2) {
          font-size: 18px;
        }

        .tags {
          width: 125px;
          padding: 2px 0;

          p {
            font-size: 10px;
            margin-bottom: 0;
          }
        }
      }

      .wrap .col_:nth-of-type(2) .buttonWrap {
        display: flex;
        justify-content: flex-end;

        .book {
          padding: 2px 5px;
          font-size: 0.8rem;
          width: 100px;
          height: 27px;
        }
      }
    }
  }

  

  .detailsWrapper .propertyName {
    h2 {
      font-size: 20px;
      line-height: 20px;
    }

    a {
      font-size: 0.6rem;
      letter-spacing: 0.4px;
      line-height: 1.4;
    }
  }

  .hotelsBox {
    padding: 15px 10px !important;
    margin-bottom: 5px !important;
  }

  .bookingTags .tag {
    svg {
      font-size: 1rem !important;
    }

    i {
      font-size: 1rem !important;
    }

    .content {
      p {
        font-size: 0.8rem !important;
      }

      span {
        font-size: 0.75rem !important;
        line-height: 15px;
      }
    }
  }

  .selects-rooms h2 {
    margin-bottom: 1px;
  }

  // New Start
  .headerLanding .headerTop .container {
    align-items: center;
  }
  .headerTop {
    padding: 10px 0px;
    .dashboardLogo {
      width: 44px;
      height: 44px;
      h2 {
        display: none;
      }
    }
    li {
      margin-left: 20px;
    }
  }
  .headerTop ul {
    align-items: center !important;
  }
  .headerTop li {
    align-items: center;
  }
  .headerTop li:first-child {
    padding: 5px 0px;
  }
  .headerTop .content p {
    line-height: 1;
    margin-bottom: 6px;
  }
  .headerTop .content span {
    font-size: 10px;
    line-height: 1;
    display: block;
  }
  .hotalList .headerLanding,
  .hotalList .homeBanner {
    height: 65px;
  }
  
 
  .banner-form {
    position: static;
    z-index: 999;
    width: 100%;
    // background-image: url("../../images/banner-mobile.jpg");
    background-color: rgba(0,0,0,0.6);
    background-position: center;
    .wrapper {
      .form-group {
        width: 100%;
        border: none;
        background-color: transparent;
        border-radius: 0px;
        color: #fff;
        font-size: 14px;
        padding: 0px;
        margin: 0px 0px 10px;

        input:only-of-type(2) {
          padding: 4px 0;
        }

        span:not(.react-datepicker__navigation-icon) {
          display: none;
        }

        &:nth-child(2),
        &:nth-child(4) {
          border: 0;
        }
        &:last-child {
          margin-bottom: 0px;
        }

        .react-datepicker-wrapper {
          width: 100%;
        }

        img {
          margin: 0px 10px;
        }
      }

      select {
        padding: 4px 0;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: center;
        width: 100%;
        color: #fff;
      }

      button:not(.react-datepicker__navigation) {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2px;
        border-radius: 4px;
        padding: 12px;
        margin-top: 0px;
      }
    }
  }
  .banner-form .wrapper input,
  .banner-form .wrapper select {
    height: 40px !important;
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
    border: solid 1px #939393 !important;
    font-size: 12px !important;
    color: #fff;
    font-weight: 400 !important;
    &::placeholder{
      color: #fff;
    }
  }
  .banner-form .wrapper .form-group.position-relative + .form-group {
    background-color: transparent;
  }
  .explore-box {
    padding: 0px;
  }
  .explore-box .sliderImg figure {
    border: none;
  }
  .explore-box .sliderImg {
    padding: 0px;
  }
  .explore-box .slick-arrow {
    right: 0px;
  }
  .appDownloadSection {
    padding: 20px 0px;
  }
  .appDownloadSection .second h1::after {
    display: none;
  }
  .appDownloadSection .second {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0rem 0;

    h1 {
      text-align: left;
      font-size: 1rem;
      width: 70%;
    }

    .mobileInput {
      width: 90%;
      margin-top: 1rem;
    }
  }
  .appDownloadSection .image ul {
    margin-bottom: 0px;
  }
  .choose-section {
    padding: 30px 0px;
  }
  .choose-section .heading {
    padding: 0px 15px;
  }
  .choose-section .heading h2 {
    margin-bottom: 8px;
  }
  .choose-section .heading p {
    padding: 0px;
  }
  .choose-section .wrap {
    background-color: #f9f9f9;
    padding: 20px 15px 0px;
  }
  .choose-section .content {
    padding: 0px;
  }
  .choose-section .content ul {
    margin-bottom: 0px;
  }
  .choose-section figure {
    margin-bottom: 0px;
  }
  .testimonialHomePage {
    padding: 20px 0px 20px;
  }
  .purchase-box {
    border-radius: 0px;
    padding: 15px;
  }
  .purchase-box .image ul {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .footerLanding .top .footerTabs {
    padding: 0px;
  }
  
  .listing-wrapper .rightContant .ifNoPrt svg {
    font-size: 3rem;
  }
  .listing-wrapper .rightContant .ifNoPrt h4 {
    font-size: 1rem;
  }
  .listing-wrapper .rightContant .ifNoPrt h6 {
    font-size: 0.8rem;
  }
  .listing-wrapper .rightContant .ifNoPrt h6 a {
    font-size: 14px;
  }
  .userProfile {
    margin-top: 0px;
  }
  .loginSection .form-wrap .form-inner .button {
    text-align: center;
    button {
      font-size: 12px;
      padding: 8px 20px;
    }
  }
  .listing-wrapper .rightContant .content .buttons {
    padding-top: 15px;
  }
  .footerLanding .top .listHotelFooter button {
    font-size: 14px;
    padding: 7px 0.8rem;
  }
  .footerLanding ul {
    margin-bottom: 0px;
  }
  .footerLanding .top a {
    font-weight: 400;
    font-size: 13px;
  }
  .footerLanding .top li:not(:last-of-type) {
    margin-bottom: 5px;
  }
  .footerLanding .top p {
    margin-bottom: 6px;
  }
  .footerLanding .middTop {
    padding: 15px 0px 10px;
  }
  .footerLanding .middTop p {
    margin-bottom: 12px;
  }
  .footerLanding .middTop .container {
    align-items: start;
  }
  .footerLanding .center ul {
    gap: 0px;
    margin-left: 0px;
  }
  .footerLanding .center li {
    color: #727e8c;
  }
  .footerLanding .center {
    padding: 15px 0px;
  }
  .footerLanding .center h3 {
    margin-bottom: 10px;
  }
  .footerLanding .bottom {
    padding: 15px 0px;
  }
  .footerLanding .bottom figure {
    margin-bottom: 0px;
  }
  .react-datepicker__input-container input {
    height: 36px;
    width: 100%;
    margin-left: 0px !important;
  }
  .detailsWrapper .col_:nth-of-type(2) {
    width: 100%;
  }
  
  .detailsWrapper .showImages {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 0px;
    margin: 0px;
  }
  .detailsWrapper .showImages figure {
    width: 25%;
    height: 100px;
    margin: 0px;
  }
  .detailsWrapper .otherDetails {
    width: 100%;
    display: none;
  }
  .detailsWrapper .showImages figure:last-child::after {
    font-size: 14px;
  }
  .policy-section .policybox,
  .policy-section .faqbox {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .mainBook .filterRes {
    gap: 0px;
  }
  .mainBook h2 {
    margin-bottom: 0px;
  }
  .mainBook h2 p {
    margin-bottom: 5px;
  }
  .mainBook .filterRes h6 {
    padding: 10px 0px;
    margin-bottom: 0px;
  }
  .mainBook .col-left {
    padding: 0px;
  }
  .booking-form .bookingActions {
    padding-bottom: 0px;
  }
  .booking-form .bookingActions .form-group {
    margin-bottom: 15px;
  }
  .booking-form .row ul {
    margin-bottom: 5px;
  }
  .booking-form .row ul li {
    margin-top: 0px;
  }
  .booking-form .row .form-group.col-12:last-child {
    margin-bottom: 0px;
  }
  .mainBook .col-right {
    padding: 15px;
  }
  .mainBook .col-right h3 {
    padding-bottom: 6px;
  }
  .mainBook .col-right ul {
    margin-top: 10px 0px;
  }
  .mainBook .col-right li {
    align-items: center;
    margin-bottom: 10px;
  }
  .mainBook .col-right li span {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .mainBook .col-right li p {
    margin-bottom: 0px;
  }
  .mainBook .col-right .total-price {
    padding-top: 15px;
  }
  .mainBook .col-right .total-price.bgTotalPrice {
    padding: 15px;
  }
  .mainBook .col-right .total-price.bgTotalPrice h4 {
    margin-bottom: 0px;
  }
  .applyCoupon {
    margin-top: 10px;
  }
  .popular-section .explore-box .show-explore-city .city-box .show-city p {
    font-size: 10px;
  }
}
// New End

@media screen and (max-width: 500px) {
  .explore-section .explore-box {
    padding: 50px 0 5px;
  }

  .frontFormAddMember {
    .col-6 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .col-4 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .form-label {
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 10px;
    }

    .modal-body {
      padding: 3px 0px;
    }
  }

  .headerTop {
    li {
      .content {
        a {
          display: none;
        }
      }
    }

    figure {
      width: 20px;
    }
  }

  .headerTop .loginButton,
  .headerTop .logoutButton {
    width: 34px;
    min-height: 34px;
    padding: 0px 5px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .appDownloadSection {
    .first {
      ul {
        gap: 0.4rem;

        li {
          font-size: 0.72rem;
          font-weight: 600;
          letter-spacing: 0.2px;

          &:nth-child(2) svg,
          svg {
            font-size: 1rem;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .testimonialSeaction {
    .section-title {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }

  .choose-section {
    .wrap {
      flex-direction: column;
    }

    li {
      span {
        width: 40px;
        height: 40px;
      }

      h6 {
        font-size: 18px;
      }
    }
  }

  .choose-section .heading p {
    // padding: 0 0.5rem;
    text-align: justify;
  }

  .heading {
    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px !important;
    }

    h4 {
      font-size: 18px !important;
    }
  }

  

  .listing-wrapper {
    .rightContant {
      justify-content: center;
      align-items: center;

      .heading {
        width: 100%;

        h1 {
          font-size: 14px !important;
        }

        select,
        button {
          padding: 1px;
        }
      }

      .listCard {
        width: 100% !important;

        .imageslider {
          width: 100%;
          height: 200px !important;
        }

        .content {
          .useCode {
            font-size: 12px;
            margin: 0;
          }

          .buttons {
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .bookConfirm {
    .book {
      flex-direction: column;

      .col-right {
        text-align: start;
      }

      .fs-6,
      time,
      .col-left h4,
      p {
        font-size: 0.8rem !important;
      }
    }

    .product {
      flex-direction: column;

      .col-right,
      .col-left {
        width: 100%;
        height: auto;
      }
    }
  }

  .userProfile {
    .col-right {
      justify-content: center;
      align-items: center;

      .heading {
        h3 {
          font-size: 15px !important;
        }
      }

      h2 {
        font-size: 14px;
      }

      .bookingList {
        width: 100% !important;

        .soldOutImg {
          top: 55%;
        }

        a {
          width: 100%;
        }

        figure {
          width: 100%;
          height: 190px;
        }

        .content {
          flex-direction: column;
          width: 100%;

          .left-content {
            padding: 0;
            width: 100%;
          }

          .right-content {
            width: 100%;
            display: flex;
            border-left: 0;
            padding: 2px 10px;
            justify-content: space-between;

            .bookId {
              border-bottom: 0;
              padding: 0;

              p {
                text-align: start;
              }
            }

            .amount {
              padding: 0;
            }
          }

          .bothBtns button {
            padding: 3px 5px;
          }
        }
      }
    }
  }

  .hotalList {
    .detailsWrapper {
      .mainBook h2 a {
        font-size: 16px;
        line-height: 21px;
      }

      .container {
        .hotelsBox {
          padding: 9px;
          margin-bottom: 10px;
        }

        .prop-description {
          padding: 10px;

          h2 {
            font-size: 16px;
            line-height: 15px;
            margin-bottom: 4px;
          }

          p {
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.5px;
          }
        }

        .col_ {
          .highlight {
            li {
              gap: 0rem;

              div {
                font-size: 0.5rem;
                font-weight: 600;
                letter-spacing: 0.5px;
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #999;
                text-align: center;
                padding: 2px;
                line-height: 10px;

                img {
                  width: 15px;
                  margin-bottom: 0.5rem;
                }
              }
            }
          }

          .shareBtn {
            width: 27px;
            height: 27px;
          }

          .sharesBtns {
            top: 36px;
            right: 16px;
            width: 25px;
          }
        }
      }
    }

    .selects-rooms.customSelectRooms {
      .selectRoomHeader {
        h2 {
          padding-left: 16px;
        }

        flex-direction: column;

        .dateFilterDetails {
          margin-top: 0.5rem;
          max-width: 100%;
          flex: 1;
          justify-content: space-between !important;
        }
      }
    }

    .roomsBox {
      .col_ {
        .sold-out {
          top: 53px !important;
        }

        .wraps {
          flex-direction: row;

          .left-content,
          .right-content {
            .roomDesctiptionLess {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .wrap {
        flex-direction: column;

        .col_:first-of-type {
          width: 100%;
        }

        .col_:nth-of-type(2) {
          width: 100%;
          padding-bottom: 10px;
        }
      }
    }
  }

  .banner-form .searchSuggestionText {
    font-size: 12px;

    & + i {
      font-size: 0.8rem;
    }
  }

  .loginSection {
    .container {
      margin-top: 0;
    }

    .topbar {
      figure {
        margin-bottom: 5px;

        h2 {
          display: block;
        }
      }

      p {
        font-size: 0.7rem;
      }

      gap: 0;
    }

    .form-wrap {
      .head {
        padding: 10px 15px;
        font-size: 0.8rem;
      }
    }
  }

}

@media screen and (max-width: 414px) {
  .exclusive-section {
    .box {
      width: 100%;
    }

    .wrap {
      border-radius: 0;
      padding: 5px;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 0.5rem;
    }
  }

  .loginSection h1 {
    margin-top: 0rem;
    font-size: 1.3rem;
  }

  .goBackRoom {
    margin-bottom: 10px;
  }

  // .container {
  //   padding-right: 15px !important;
  //   padding-left: 5px !important;
  // }

  .headerTop {
    .container {
      justify-content: space-between;
    }

    ul {
      margin-left: 0;
    }

    li {
      &:nth-child(2) {
        flex-direction: column;
      }
    }
  }

  .heading {
    h2 {
      font-size: 20px !important;
      margin-bottom: 5px;
    }

    p {
      font-size: 10px !important;
    }

    h4 {
      font-size: 15px !important;
    }
  }

  .choose-section {
    li {
      h6 {
        font-size: 13px;
      }
    }
  }

  .popular-section .explore-box {
    .sliderImg {
      .content {
        padding: 0px 8px;

        h3 {
          font-size: 16px;

          span {
            font-size: 12px;
          }
        }

        .button-wrap {
          margin-top: 0;

          a {
            padding: 5px 10px;
            margin-bottom: 10px;
          }
        }
      }

      figure {
        height: 150px;
      }
    }
  }

  .explore-section .purchase-box {
    .content {
      width: 100%;
    }

    .image {
      width: 100%;

      ul {
        grid-template-areas: "top bottom main ";
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        li {
          &:nth-child(1),
          &:nth-child(2) {
            width: 110px;
          }

          width: 80px;
        }
      }
    }
  }

  .contentMains {
    padding: 10px 2px 12px;

    p {
      font-size: 12px;
      text-align: justify;
      font-weight: 500;
    }

    h1 {
      font-size: 13px;
    }

    h2 {
      margin-bottom: 15px;
      font-size: 13px;
    }

    ul {
      margin-left: 5px;

      li {
        font-size: 12px;
      }
    }
  }

  .mainBook .col-left .bookingActions {
    flex-direction: column;
    align-items: flex-start !important;

    // gap: 2rem;
    .form-group {
      width: 100%;

      // input {
      //   width: 100% !important;
      // }

      // .react-datepicker__input-container {
      //   input {
      //     width: 88% !important;
      //   }
      // }
    }
  }

  .bookConfirm {
    .wrap {
      padding: 10px;
    }

    .heading {
      margin-bottom: 0;
      padding: 0 5px;

      h2 {
        font-size: 17px !important;
        margin-bottom: -10px;
      }
    }
  }

  .hotalList {
    .policy-section {
      .container {
        flex-direction: column;
        gap: 1rem;

        .policybox,
        .faqbox {
          width: 100%;
          padding: 10px 15px;
          margin: 0;

          .heading {
            margin-bottom: 5px;
            padding-bottom: 5px;
          }
        }

        .accordian {
          h3 {
            font-size: 15px;
          }

          .tab-content {
            padding: 0px 0px 0px 35px;

            p {
              font-size: 13px;
              font-weight: 600;
            }
          }
        }

        h4 {
          font-size: 15px;
        }
      }
    }
  }

  .hotalList .selects-rooms.customSelectRooms .dateFilterDetails {
    .form-group {
      flex-direction: column;
      margin-bottom: 0.3rem;
      gap: 0.3rem !important;

      label {
        margin: 0;
      }
    }
  }

  .selects-rooms.customSelectRooms .selectRoomHeader {
    top: -35px;
  }
}

@media screen and (max-width: 354px) {
  .headerTop {
    li {
      .content {
        p {
          font-size: 8px;
        }
      }
    }
  }

  .headerTop .loginButton,
  .headerTop .logoutButton {
    width: 31px;
    min-height: 25px;
    border: 0;

    img {
      width: 35px;
      height: 23px;
    }
  }

  .homeBanner {
    h1 {
      font-size: 15px;
      letter-spacing: 0.5px;
      padding: 10px;
    }
  }

  .detailsWrapper .propertyName {
    padding: 5px 0px !important;
  }

  .heading {
    p {
      font-size: 10px !important;
      line-height: 1.5 !important;
    }

    h4 {
      font-size: 15px !important;
      line-height: 27px !important;
    }
  }

  .explore-section {
    .purchase-box {
      .image {
        ul {
          grid-template-areas: "top main" "bottom main";
          grid-template-columns: 1fr 2fr;
          align-items: center;

          li {
            &:nth-child(1),
            &:nth-child(2) {
              width: 110px;
            }

            width: 80px;
          }
        }
      }
    }
  }

  .footerLanding {
    .bottom {
      padding: 10px;

      .wrap.flex {
        flex-wrap: nowrap;

        ul {
          li {
            margin-left: 6px;

            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .explore-box .slick-arrow {
    width: 25px;
    height: 25px;
  }

  .mainBook {
    padding: 10px 0;

    .filterRes {
      ul li {
        h5 {
          font-size: 10px;
        }
      }
    }

    .col-right {
      padding: 10px;
    }
  }

  .userProfile {
    .leftSidebar {
      width: 250px;

      .wallet {
        h4 {
          font-size: 10px;
          font-weight: 600;
        }

        p {
          font-size: 12px;
          padding: 3px 5px;
        }

        .transferBtn {
          margin-top: 0;
          padding: 5px 10px;
        }
      }

      .bottom {
        .dlt-btn {
          padding: 7px 10px;
          margin-top: 10px;
        }

        .form-group {
          margin-bottom: 10px;

          input {
            width: 15px;
            height: 15px;
          }
        }

        .logoutButton {
          padding: 3px 0px;

          img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .profile-inner {
      .list {
        padding: 6px;
        margin-bottom: 7px;

        p {
          margin: 4px 0;
          color: #000000af !important;
        }
      }

      h4 {
        font-size: 15px;
        margin-top: 0;
      }

      p.customerId {
        margin-bottom: 3px;
        font-size: 13px;
        line-height: 19px;
        color: #000;
      }
    }
  }

  .loginSection {
    .form-wrap {
      .form-inner button {
        margin-top: 10px;
      }

      .head {
        padding: 3px;
        font-size: 0.67rem;
      }

      .form-group {
        margin-bottom: 0;
      }

      ul {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 312px) {
  .headerTop {
    li {
      .content {
        p {
          font-size: 6px;
        }
      }
    }
  }

  .exclusive-section .box h4 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .sort-fillter {
    gap: 0.2rem !important;
  }
}

@media screen and (max-width: 610px) and (min-width: 500px) {
  .hotalList .listing-wrapper .rightContant .listCard .content .buttons a {
    font-size: 10px;
    padding: 5px;

    del {
      font-size: 8px;
    }
  }
}

// property responsiveness

@media screen and (max-width: 540px) {
  .banquetBookingDetails {
    h6,
    h5,
    p {
      font-weight: 400 !important;
      font-size: 0.9rem !important;
    }
    table {
      thead {
        th {
          font-size: 0.4rem;
          font-weight: 800;
          padding: 0 !important;
        }
      }
      tbody tr td {
        font-size: 0.4rem;
        font-weight: 800;
        padding: 0 !important;
      }
    }
  }
  .checkInModal.agreementModalShow .bottom-tab,
  .checkInModal.agreementModalShow .container .innerBox {
    padding: 1rem;
  }
  .InvoicecontentPdf {
    span:nth-of-type(1) {
      font-size: 1rem !important;
      margin-bottom: 0;
    }
    span:nth-of-type(2) {
      font-size: 0.7rem !important;
      margin-bottom: 0;
    }
    span:nth-of-type(3) {
      font-size: 0.6rem !important;
      margin-bottom: 0;
    }
  }
  .Module {
    width: 90%;
    &_inner {
      li {
        padding: 0 !important;
        margin: 5px !important;
        span {
          font-size: 0.7rem;
        }
        small {
          font-size: 0.6rem;
        }
      }
    }
  }
  .dashboard-wrap {
    .sidebar {
      width: 0;
      transform: translateX(-100%);

      &.sideOpen {
        width: 220px;
        position: fixed;
        transform: translateX(0);
      }
    }

    .addedTogglerClaas {
      position: absolute;
      z-index: 1;
      right: -325%;

      svg {
        color: #fff;
        fill: #fff;
      }
    }

    .main-content {
      width: 100%;
      overflow-x: hidden;

      .boxWrap {
        padding: 1.2rem;

        ul {
          gap: 1.4rem;
          margin-top: 1rem;

          li {
            min-width: 45%;
            height: 125px;

            h4 {
              line-height: 62px;
              font-size: 40px;
            }
          }
        }
      }

      .profile.flex {
        div:not(.name),
        figure {
          display: none;
        }
      }

      figure.headerLogoAdmin {
        .logo-box {
          display: block;

          h2 {
            display: none;
          }
        }
      }
    }
  }
  .chart {
    width: 100%;
    margin-bottom: 0;
  }
  .modal-dialog {
    .form-group {
      max-width: 100% !important;
      flex: 0 0 100%;
    }
  }
}

@media screen and (max-width: 380px) {
  .dashboard-wrap {
    .main-content {
      .boxWrap {
        padding: 10px;
        ul {
          gap: 1.4rem;
          margin-top: 1rem;
          li {
            min-width: 45%;
            height: 100px;
            h4 {
              line-height: 42px;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media(min-width: 1024px){
  .hotalList .homeBanner{
    height: 78px;
  }
}

// By MANL
@media (max-width: 767px) {
  .homeBanner{
    box-shadow: 0px 0px 18px 2px #9a9a9a;
    padding: 120px 0px 0px;
  }
  .homeBanner h1{
    font-size: 22px;
  }
  .banner-form .wrapper{
    background-color: transparent;
  }
  .hotalList .banner-form .wrapper{
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0px;
  }
  .homeBanner .searchWrapper{
    height: auto;
    margin-top: 20px;
  }
  .banner-form.active{
    position: static;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .hotalList .banner-form{
    background-color: transparent;
    background-image: url(../../images/Banner3.jpg);
    background-repeat: no-repeat;
    position: relative;
  }
  .popular-section{
    padding-top: 22px;
  }
  .popular-section .explore-box .show-explore-city .city-box .show-city {
    width: 66px;
    margin: 0px;
    margin-right: 18px;
  }
  .popular-section {
    .heading {
      h4 {
        line-height: 1;
        padding-bottom: 10px;
      }
    }
  }
  .hotalList .banner-form .wrapper input, .hotalList .banner-form .wrapper select{
    // color: #000 !important;
    font-weight: 500 !important;
    // background-color: rgba(0, 0, 0, 0.1);
  }

  .explore-box .slick-arrow, .testimonialHomePage .slick-arrow{
    width: 24px;
    height: 24px;
  }
  .explore-box .slick-arrow:before, .testimonialHomePage .slick-arrow:before{
    width: 5px;
    height: 10px;
  }
  .explore-box .upperSliderHomepage .slick-arrow{
    top: -34px !important;
  }
  .explore-box .sliderImg figure{
    height: auto;
  }
  .testimonialSeaction .testimonial-item p{
    padding-top: 15px;
  }

  // .choose-section ul::before{
  //   display: none;
  // }
  // .choose-section li:nth-of-type(2), .choose-section li:nth-of-type(3), .choose-section li:nth-of-type(4){
  //   width: 100%;
  //   height: auto;
  //   clip-path: none;
  //   padding: 10px;
  //   margin: 5px 0px;
  // }
  // .choose-section li:first-child{
  //   border-bottom: none;
  // }
  // .choose-section li:last-child{
  //   border-top: none;
  // }
  // .choose-section li h6{
  //   margin-bottom: 0px;
  // }
  .listing-wrapper .rightContant .content .amenities li{
    padding: 5px;
  }
  .listing-wrapper .rightContant .content .buttons a{
    box-shadow: none;
  }
  .listing-wrapper .rightContant .listCard .tags{
    padding: 5px 0px;
  }
  .detailsWrapper{
    padding-bottom: 20px;
  }
  .detailsWrapper, .selects-rooms, .policy-section{
    background-color: #d6d6d6;
  }
  .detailsWrapper .propertyName {
    padding: 12px 50px 12px 12px;
  }
  .detailsWrapper .big-slider {
    width: 100%;
    height: 232px;
  }
  .detailsWrapper .big-slider .sliderImg{
    height: 232px;
  }
  .prop-description{
    padding: 12px;
  }
  .detailsWrapper .highlight{
    padding: 12px;
  }
  .detailsWrapper .highlight h2{
    line-height: 1;
    padding-bottom: 8px;
  }
  .detailsWrapper .highlight li{
    gap: 0px;
    padding: 0px;
  }
  .detailsWrapper .highlight li div {
    width: 50%;
    background-color: transparent;
    font-size: 0.7rem;
  }
  .detailsWrapper .highlight li div img{
    margin-right: 4px !important;
  }
  .mainBook .hotelsBox{
    padding: 12px !important;
    margin-bottom: 0px !important;
  }
  .mainBook .hotelsBox .bookingTags .tag .content span{
    opacity: 0.8;
    font-size: 10px;
    padding-top: 4px;
  }
  .selects-rooms.customSelectRooms .selectRoomHeader {
    padding: 15px;
    margin-top: 0px;
  }
  .custom-wrapper {
    max-width: 100%;
    padding: 0px 15px;
  }
  .selects-rooms{
    padding: 0px 5px;
    margin-top: 0px;
  }
  .selects-rooms.customSelectRooms .selectRoomHeader{
    align-items: flex-start !important;
  }
  .selects-rooms.customSelectRooms .selectRoomHeader h2{
    text-align: left;
    padding-left: 0px;
  }
  .selects-rooms.customSelectRooms .selectRoomHeader .form-group{
    width: calc(50% - 10px);
  }
  .hotalList .selects-rooms.customSelectRooms .selectRoomHeader h2{
    padding-left: 0px;
  }
  .selects-rooms.customSelectRooms.active .activeRoomHeader .selectRoomHeader{
    padding: 8px;
  }
  .selects-rooms.active .activeRoomHeader .selectRoomHeader h2{
    font-size: 16px;
  }
  .hotalList .selects-rooms.active.customSelectRooms .selectRoomHeader .dateFilterDetails{
    margin-top: 0px;
  }
  .selects-rooms .roomsBox{
    border-radius: 10px;
  }
  .selects-rooms .roomsBox .wraps h3{
    color: #000;
  }
  .selects-rooms .roomsBox .buttons .text-black{
    font-size: 12px;
    line-height: 1;
    opacity: 0.6;
  }
  .selects-rooms .roomsBox .wraps ul li{
    margin-left: 0px !important;
  }
  .hotalList .roomsBox .roomDetails{
    padding: 0px 15px;
  }
  .selects-rooms .roomsBox .roomDetails .amenities ul{
    align-items: flex-start;
  }
  .policy-section{
    padding: 10px 5px;
  }
  .policy-section .heading{
    margin-bottom: 5px;
  }
  .policy-section .heading:after{
    display: none;
  }
  .customerReview .date, .customerReview p{
    font-size: 12px;
    margin-top: 0px;
  }
  .rr--space-sm .rr--svg{
    padding: 15% !important;
  }
  .starsLists .rr--dir-x{
    margin-left: -3px;
  }
  .policybox .customerReview{
    border: none;
    border-bottom: solid 1px #ddd;
    padding: 10px 0px;
  }
  .policy-section h4{
    margin-bottom: 0px;
  }
  .starsLists{
    margin-bottom: 2px;
  }
  .purchase-box {
    .content {
      width: 100%;
      h3 {
        font-size: 16px;
        padding-right: 0px;
      }
    }
  }
  .purchase-box {
    .image {
      width: 220px;
      margin: 0 auto;
      ul {
        grid-template-columns: 1fr 1fr;

        li {
          &:nth-child(1),
          &:nth-child(2) {
            width: 110px;
          }

          width: 80px;
        }
      }
    }
  }
  .footerLanding .top .container .footerTabs{
    width: 100%;
  }
  .footerLanding .top .container .footerTabs:first-child{
    width: 100%;
  }
  .footerLanding .top .container .footerTabs:last-child{
    width: 100%;
  }
  .choose-section ul{
    min-height: 100px;
  }
  .choose-section li{
    width: 50%;
    position: static;
    margin: 10px 0px;
  }
  .choose-section li:nth-child(5){
    transform: translate(0px);
  }
  .choose-section li img{
    width: 120px;
    height: 120px;
  }
  .choose-section li h6{
    font-size: 12px;
    line-height: 1.5;
  }
  // .banner-form {
  //   form {
  //     border: solid 1px #000;
  //     border-radius: 6px;
  //     overflow: hidden;
  //   }

  //   .wrapper {
  //     .form-group {
  //       border-bottom: solid 1px #000 !important;
  //       border-radius: 0px;
  //       margin-bottom: 0px;
  //       img {
  //         display: none;
  //       }
  //       .form-control {
  //         text-align: left !important;
  //         border-radius: 0px !important;
  //         border: none !important;
  //         color: #000 !important;
  //         font-weight: 500 !important;
  //         height: 40px !important;
  //         padding: 0px 10px !important;
  //       }
  //       select {
  //         text-align: left !important;
  //         font-size: 12px;
  //         height: 40px !important;
  //         padding: 0px 6px !important;
  //       }
  //     }
  //   }
  // }
}


@media(max-width: 420px){
  .homeBanner h1{
    font-size: 20px;
  }
  .homeBanner p{
    font-size: 14px;
  }
}

@media(max-width: 400px){
  .homeBanner h1{
    font-size: 16px;
  }
}