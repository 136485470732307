.boxWrap {
  padding: 13px 30px;
  .boxWrapHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h2{
      margin-bottom: 0px;
    }
    .timeTabDashboard {
      display: flex;
      gap: 0.6rem;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      background-color: #dce9eb98;
      padding: 5px;
      border-radius: 6px;
      margin-left: 15px;
      button {
        white-space: nowrap;
        padding: 2px 10px;
        border-radius: 6px;
        background: transparent;
        transition: all 0.4s ease;
        &:hover {
          background: #6bacb698;
        }
        &:active {
          scale: 0.8;
        }
        &.active {
          background: #00a3ff;
          color: #fff;
          box-shadow: 0px 0px 15px rgba(16, 120, 128, 0.116);
        }
      }
    }
  }
  h2 {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #000;
  }
  ul {
    gap: 2.5rem;
    padding: 5px 9px;
    li {
      width: calc(16% - 2.5rem);
      border-radius: 8px;
      display: flex;
      align-items: start;
      border: 1px dashed #000;
      outline: 8px solid #ffffff;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
      text-align: center;
      overflow: hidden;
      .d-flex {
        flex-direction: column-reverse;
        width: 100%;
      }
      h4 {
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        color: #3f4254;
        margin: 0;
        width: 100%;
        padding: 10px;
      }
      span:not(.skeleton, .react-loading-skeleton) {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        display: block;
        border: 1px solid #00000036;
        background-color: #00a3ff;
        padding: 8px 10px;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      span.skeleton.react-loading-skeleton{
        line-height: 0;
      }
      p {
        font-size: 0.7rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 0.1rem;
        text-align: start;
        padding-left: 0.4rem;
      }
      img {
        width: 22px;
      }
    }
  }
}

.bigImg {
  margin-top: 35px;
  padding: 30px;
}
.boxWrap {
  position: relative;
  .form-group {
    width: 170px;
    margin-right: 5px;
  margin: 0;
    select {
      font-family: $font-family-body;
      padding: 5px 10px;
      width: 100%;
      outline: 0;
      border: 1px dashed #0000002d;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
  }
}
.chart {
  width: 25%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  &:nth-of-type(1) {
    border-right: 1px solid #000;
  }
  text {
    font-family: $font-family-body;
  }
  position: relative;
  .form-group {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-bottom: 0px;
    select {
      font-family: $font-family-body;
      width: 110px;
      height: 34px;
      font-size: 12px;
      outline: 0;
      border: none;
      background-color: #e8e8e8;
      border-radius: 15px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      background-size: 10px 10px;
      // padding: 1px 6px;
    }
  }
  .timeTabDashboard {
    display: flex;
    gap: 0.6rem;
    // box-shadow: inset 0px 0px 4px #18363698;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    background-color: #dce9eb98;
    padding: 5px;
    border-radius: 6px;
    width: auto;
    margin-top: 18px;
    button {
      padding: 2px 10px;
      border-radius: 6px;
      background: transparent;
      transition: all 0.4s ease;
      &:hover {
        background: #6bacb698;
      }
      &:active {
        scale: 0.8;
      }
      &.active {
        background: #00a3ff;
        color: #fff;
        box-shadow: 0px 0px 15px rgba(16, 120, 128, 0.116);
      }
    }
  }
}


.tab-nav{
  width: auto;
  display: inline-flex;
  gap: 0.6rem;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  background-color: rgba(220, 233, 235, 0.5960784314);
  border-radius: 6px;
  padding: 2px;
  button{
    white-space: nowrap;
    border-radius: 6px;
    font-size: 12px;
    background: transparent;
    transition: all 0.4sease;
    padding: 2px 10px;
    &.active{
      background: #00a3ff;
      color: #fff;
      box-shadow: 0px 0px 15px rgba(16, 120, 128, 0.116);
    }
  }
}

.chartWrapper{
  .column{
    position: relative;
    min-height: 200px;
    border-right: solid 1px #ebebeb;
    padding-right: 15px;
    h4{
      font-size: 16px;
      color: #000;
    }
  }
  .col-lg-4:last-child .column{
    border-right: none;
  }
}

.revenueWrapper{
  border-top: solid 1px #ebebeb;
  padding-top: 20px;
  margin-top: 20px;
  .column{
    position: relative;
    border-right: solid 1px #ebebeb;
    padding: 0px 15px;
    h4{
      font-size: 16px;
      color: #000;
      padding-bottom: 25px;
    }
  }
  .col-lg-4:last-child .column{
    border-right: none;
  }
}


@media(max-width: 1024px){
  .chartWrapper .column, .revenueWrapper .column{
    border-right: none;
    border-bottom: solid 1px #ebebeb;
    padding: 20px 0px;
  }
  .revenueWrapper{
    border-top: none;
  }
}