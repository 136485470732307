.payment-section {
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #181c32;
    margin-bottom: 8px;
    padding: 0;
  }
  .tabBtns {
    li {
      width: calc(33.33% - 16px);
      text-align: center;
      background: #ffffff;
      border: 1px solid rgba(210, 201, 197, 0.3);
      border-radius: 12px;
      padding: 40px 35px;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
      &.active {
        background: #c8e3ff;
        border: 1px dashed #3699ff;
        border-radius: 12px;
        h6 {
          color: #181c32;
        }
        p {
          color: #7e8299;
        }
      }
      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #7e8299;
        margin-bottom: 7px;
        margin-top: 15px;
      }
      p {
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        color: #b5b5c3;
      }
    }
  }
  .cardBoxes {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 10px;
    padding: 25px 30px;
    margin-top: 20px;
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
      color: #181c32;
      margin-bottom: 20px;
      padding: 0;
    }
    .guestForm {
      padding: 0px;
      background: #ffffff;
      box-shadow: none;
      border-radius: 0px;
      form {
        .form_group {
          &:last-of-type {
            justify-content: flex-end;
            margin-bottom: 30px;
            button {
              &:first-of-type {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
    li {
      background: #ffffff;
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      padding: 30px;
      margin-bottom: 30px;
      h4 {
        display: flex;
        font-family: $font-family-Poppins;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #3f4254;
        .tags {
          padding: 6px 11px;
          background: #e8fff3;
          border-radius: 6px;
          font-family: $font-family-Poppins;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #50cd89;
          margin-left: 10px;
        }
      }
      .cardInfo {
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        .col-left,
        .col-right {
          width: auto;
          padding: 0;
        }
        .col-left {
          display: flex;
          h6 {
            font-family: $font-family-Poppins;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #3f4254;
          }
          p {
            font-family: $font-family-Poppins;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #b5b5c3;
          }
        }
        figure {
          width: 80px;
        }
        .det {
          padding-left: 15px;
        }
        button {
          padding: 10px 16px;
          border: none;
          background: #f5f8fa;
          border-radius: 6px;
          font-family: $font-family-Poppins;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #b5b5c3;
          cursor: pointer;
          &:first-of-type {
            background: transparent;
            margin-right: 10px;
          }
        }
      }
    }
    .payNows {
      padding: 12px 30px;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
      background: #00a3ff;
      border: solid 1px #00a3ff;
      border-radius: 6px;
    }
  }
}
.booking-btn {
  background-color: #00a3ff;
  color: #ffffff;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
}


.payment-button{
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}