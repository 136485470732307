.hotalList {
  .headerLanding {
    height: 80px;
  }

  .homeBanner {
    // height: 220px;
    height: 55px;
    padding: 0px;
    // background-position: bottom;
  }

  .banner-form  {
    background-color: #fff;
  }
  .popupcontainer {
    width: 50%;
    height: 700px;
  }

  .explore-section {
    background-color: #f2f3f7;
    &:after {
      display: none;
    }
  }
}
// .hotalListHeight {
//   .homeBanner {
//     height: 72px !important;
//     // background-position: bottom;
//   }
// }
.checkInModal.showImgDetails {
  .modal-content {
    background: transparent;
    border: 0;
  }
  .modal-backdrop.show {
    opacity: 0.8;
  }
  .modal-header {
    border: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  .modal-body {
    padding: 0;
  }
  .darkAreaModal {
    background-color: red;
  }
  .imagesOnModal {
    margin: auto;
    width: 90%;
    .slick-slider {
      height: 428px;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 1rem;
    }
    figure {
      margin-bottom: 0;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
    .slick-list {
      border-radius: 10px;
      overflow: hidden;
    }
    &:hover {
      .slick-arrow {
        background-color: #000000b4;
      }
    }
    .navSliderParent {
      .slick-arrow {
        display: none !important;
      }
      figure {
        margin-right: 10px;
        border-radius: 10px;
        overflow: hidden;
        height: 100px;
      }
    }
    .slick-arrow {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 0;
      outline: none;
      border: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.233);
      transition: all 0.3s ease-in;
      &:active {
        scale: 0.9;
      }
      &:before {
        background-image: url("../../images/leftarrowRight.png");
        content: "\f054";
        width: 25px;
        display: flex;
        background-repeat: no-repeat;
        top: 8px;
        position: absolute;
        left: 10px;
      }
      &.slick-prev {
        transform: translate(50%, -50%);
        left: 0;
        right: auto;
        z-index: 1;
        &:before {
          background-image: url("../../images/leftarrowSlide.png");
          content: "\f054";
        }
      }
    }
  }
}

.checkInModal.agreementModalShow {
  .modal-header {
    padding: 0.3rem;
  }
  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85vh;
  }
  .container {
    overflow: auto;
    max-width: 100% !important;
    padding: 0;
    margin: 0;
    .heading {
      border-bottom: 2px solid #000;
    }
    .innerBox {
      padding: 1rem 5rem 5rem 5rem;
      color: #000;
      letter-spacing: 0.5px;
      background-color: white;
      p {
        font-size: 0.9rem;
      }
      h6 {
        font-size: 1rem;
        color: #000;
      }
      h5 {
        font-size: 1.1rem;
        color: #000;
      }
      ul {
        &.noStrong {
          li {
            strong {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .bottom-tab {
    padding: 1em 5rem;
    border-top: 1px solid green;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: white;
    p {
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #000;
    }
  }
}

.checkInModal {
  .card {
    width: 230px;
    border-radius: 5px;
    // background-color: rgb(214 220 220 / 72%);
    background-color: rgb(189 205 205 / 72%);
    padding: 0;
    box-shadow: rgba(100, 100, 111, 0.445) 0px 7px 20px 0px;
    transition: transform 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    justify-content: space-between;
    color: #000;
    border: 1px solid #00000013;
    font-family: $font-family-body;
    padding: 2px;
    margin-bottom: 0;
  }

  .card:hover {
    transform: scale(1.01);
  }

  .card .top-section {
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .card .top-section img {
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .card .bottom-section {
    margin-top: 1px;
    padding: 10px 5px;
  }

  .card .bottom-section .title {
    display: block;
    font-size: 14px;
    font-weight: bolder;
    color: #000;
    text-align: center;
    letter-spacing: 2px;
    line-height: 15px;
  }

  .card .bottom-section .row {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  .card .bottom-section .row .item {
    flex: 30%;
    text-align: center;
    padding: 0px;
    color: #000;
    display: flex;
    flex-direction: column;
  }

  .card .bottom-section .row .item .big-text {
    font-size: 11px;
    line-height: 8px;
  }

  .card .bottom-section .row .item .regular-text {
    font-size: 9px;
  }

  .card .bottom-section .row .item:nth-child(2) {
    border-left: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgb(255, 255, 255);
  }
  .card button {
    font-size: 0.7rem;
    font-weight: 600;
    border: 0;
    background-color: #ff00001f;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    border-radius: 4px;
    color: #e30000;
    margin-bottom: 0.2rem;
  }
  .guestHeading {
    display: flex;
    span {
      margin-top: 5px;
    }
  }
  .closeButton {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffe2e5;
    color: #d9214e;
    font-size: 16px;
    font-weight: 400;
  }
  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    color: #464e5f !important;
  }

  .uploadButton {
    width: 100%;
    background: #f1faff;
    border: 1px dashed #3699ff;
    border-radius: 4px;
    transform: rotate(-0.11deg);
    color: #b5b5c3;
    text-align: left;
  }
  .row {
    padding: 0px;
  }
  input[type="text"] {
    background-color: #f3f6f9;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  input[type="checkbox"].hotalMealPlane {
    width: 13px;
    height: 20px;
  }
  .checksection {
    display: flex;
    label {
      margin-left: 30px;
    }
    input {
      margin-left: 0;
    }
  }

  .cancel {
    background: #ffe2e5;
    border-radius: 6px;
    color: #d9214e;
  }

  .checkBox-section {
    margin-top: 10px;
    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .amount-section {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
    .amount {
      color: #5e6278;
    }
  }
  .modal-footer {
    button {
      width: 100%;
    }
  }
}

.listing-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbfbfb;
  .container {
    max-width: 1200px;
  }
  .leftSidebar {
    width: 300px;
    background: #fff;
    border-radius: 4px;
    padding: 20px 5px 10px 20px;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    align-self: flex-start;
    position: sticky;
    top: 15%;
    overflow-y: auto;
    height: 80vh;
    .user_Sidebar_closeBtn {
      display: none;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }
    .heading {
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9dbe4;
      padding-bottom: 8px;
      h2 {
        color: #181c32;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.25em;
        margin-bottom: 0px;
      }
      a {
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
        color: #2884ef;
      }
    }
    .price-range {
      padding-top: 16px !important;
    }
    .multi-checkbox {
      padding: 15px 0px;
      border-top: 1px solid #d9dbe4;
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #17212d;
        font-family: $font-family-Poppins;
      }
      input[type="checkbox"] {
        cursor: pointer;
        width: 16px;
        height: 15px;
        background-color: $color-white;
        border: 1px solid #b5b5c3;
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        margin-right: 7px;
        &:before {
          content: "\f00c";
          font-family: FontAwesome;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          // background-image: url('../../images/tickIcon-multicheckbox.png');
          width: 10px;
          // height: 7px;
          font-size: 10px;
          color: #fff;
          background-repeat: no-repeat;
          opacity: 0;
          visibility: hidden;
          transition: all 0.25s ease;
        }
        &:checked {
          background: #00a3ff;
          border: 0;
          &:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      label {
        cursor: pointer;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.25em;
        letter-spacing: 0.5px;
        color: #141414;
        margin: 0;
      }
      ul {
        margin-bottom: 0px;
        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
      button {
        font-family: $font-family-body;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
        color: #2884ef;
        border: none;
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        margin: 10px 0 0 auto;
      }
      &.stars-rate {
        .stars {
          i {
            color: #ffc700;
            &:not(:last-of-type) {
              margin-right: 3px;
            }
          }
        }
        span {
          font-family: $font-family-inter;
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;
          color: #7e8299;
          margin-bottom: 0;
          margin-left: 7px;
        }
      }
    }
  }
  .rightContant {
    width: calc(100% - 300px);
    padding-left: 20px;
    .heading {
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      h1 {
        font-family: $font-family-body;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        color: #181c32;
        margin-bottom: 0px;
      }
      label {
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #464e5f;
      }
      select {
        margin-left: 10px;
        background: #ffffff;
        border-radius: 4px;
        padding: 5px 10px;
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #b5b5c3;
      }
    }
    .shimmerEffect {
      animation: shimmer 2.3s forwards infinite linear;
      background-size: 3000px 100%;
    }

    .listCard {
      background: #fff;
      box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
      border-radius: 4px;
      border: 1px solid transparent;
      padding: 15px;
      margin-bottom: 20px;
      &:hover {
        border: 1px solid #00a3ff;
      }
      .categoryTag {
        // background-color: #5b0f57;
        padding: 8px 12px;
        line-height: 0px;
        color: #fff;
        font-size: 0.65rem;
        font-family: $font-family-body;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border-radius: 5px;
        z-index: 1;
      }
      .tags {
        position: absolute;
        top: 0;
        right: 7%;
        margin-top: 0.5rem;
        p {
          padding: 2px 5px;
          border-radius: 3px;
          font-size: 0.7rem;
          color: #448700;
          margin-bottom: 0;
          border: 1px solid #43870042;
        }
      }
      .soldOutImg {
        width: 150px;
        height: 50px;
        top: 0;
        right: 3%;
        z-index: 1;
      }
    }
    .imageslider {
      width: 320px;
      height: 200px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      .sliderImg {
        width: 100%;
        height: 220px;
        border-radius: 10px;
        margin-bottom: 0px;
        a {
          height: 100%;
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &:hover {
        .slick-arrow {
          background-color: #02020281;
        }
      }

      .slick-slider.slick-initialized {
        width: 100%;
        height: 100%;
      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background-color: #0202021c;
        box-shadow: none;
        transition: all 0.4s ease-out;
        z-index: 10;
        &:before {
          color: #fff;
          font-size: 0.9rem;
        }
        &.slick-prev {
          left: 15px;
          z-index: 1;
        }
        &.slick-next {
          right: 15px;
        }
      }
      .cartBtns {
        cursor: pointer;
        width: 34px;
        height: 34px;
        background: #e1f0ff;
        border-radius: 5px;
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .content {
      width: calc(100% - 360px);
      padding: 20px 14px 0px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .rating {
        justify-content: space-between;
        align-items: center;
        .col-left {
          i {
            color: #ffc700;
          }
          span {
            margin-left: 11px;
            font-family: $font-family-inter;
            font-weight: 700;
            font-size: 12px;
            line-height: 22px;
            color: #7e8299;
            text-transform: uppercase;
          }
        }
        .col-right {
          border-radius: 90px;
          background-color: rgba(71, 190, 125, 0.2);
          align-items: center;
          span {
            background: #47be7d;
            border-radius: 90px;
            padding: 5px 10px;
            font-family: $font-family-body;
            font-weight: 400;
            font-size: 10px;
            line-height: 1.25em;
            color: #ffffff;
          }
          p {
            padding-left: 5px;
            font-family: $font-family-body;
            font-weight: 400;
            font-size: 10px;
            line-height: 1.25em;
            color: #47be7d;
            padding-right: 15px;
            margin-bottom: 0;
          }
        }
      }
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #181c32;
        // text-transform: capitalize;
        margin-bottom: 6px;
        margin-top: 10px;
        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #181c32;
        }
      }
      .address {
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: rgba(181, 181, 195, 1);
        font-family: $font-family-Poppins;
        img {
          margin-right: 8px;
        }
      }
      .amenities {
        margin: 10px 0;
        gap: 0.5rem;
        li {
          display: flex;
          align-items: center;
          font-family: $font-family-body;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #5e6278;
          padding: 10px;
          img {
            margin-right: 6px;
            width: 16px;
          }
        }
        button {
          cursor: pointer;
          border: none;
          padding: 0px;
          background-color: transparent;
          font-family: $font-family-body;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          text-transform: uppercase;
          color: #2884ef;
        }
      }
      .price-wrap {
        margin-top: 0px;
        margin-bottom: 0.5rem;
        .priceMain {
          span {
            font-family: $font-family-Poppins;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #7e8299;
            &:first-of-type {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
        h3 {
          font-family: $font-family-Poppins;
          font-weight: 900;
          font-size: 24px;
          line-height: 28px;
          color: #2884ef;
          s {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #ff0034;
          }
        }
      }
      .buttons {
        padding-top: 1rem;
        a:not(.activeLink) {
          opacity: 0.7;
          cursor: auto;
        }
        a {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-family: $font-family-body;
          padding: 5px 2.2rem;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          border-radius: 6px;
          transition: all 0.25s ease;
          border: solid 1px #00a3ff;
          box-shadow: 0px 10px 10px #00a2ff21;
          color: #00a3ff;
          &:not(:last-child) {
            pointer-events: none;
            border-color: gray;
            color: gray;
            del {
              color: rgb(255, 181, 181);
            }
          }
          del {
            color: red;
            font-size: 0.66rem;
            font-weight: 600;
            margin-left: 3px;
          }
          &.activeLink:hover {
            background: #00a3ff;
            color: #fff;
          }
          &.activeLink:active {
            scale: 0.9;
          }
          span {
            &:nth-of-type(2) {
              font-size: 0.7rem;
              font-weight: 500;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .moreResult {
      padding-top: 40px;
      text-align: center;
      a {
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #7e8299;
      }
    }
    .ifNoPrt {
      margin-top: 3rem;
      color: #000;
      text-align: center;
      font-family: $font-family-body;
      svg {
        font-size: 6rem;
        margin-bottom: 2rem;
      }
      h4 {
        color: #000;
        color: #000;
        font-weight: 500;
        font-size: 1.3rem;
      }
      h6 {
        cursor: pointer;
        color: #000;
        margin: 1rem 0 0 0;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.5px;
        a {
          text-decoration: underline;
          color: #00a3ff;
          margin-left: 5px;
        }
      }
      span {
        font-size: 0.66rem;
        letter-spacing: 0.5px;
        font-weight: 500;
      }
    }
  }
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #ff9a0d !important;
}

.box-range-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  input {
    width: 49%;
  }
}

#filterBtnVis {
  display: none;
}
