.couponSet {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 1rem 2rem;
  background-color: linear-gradient(45deg, #0dff006c, #f76be97a);
}
.couponSet .coupon-card {
  width: calc(23% - 1rem);
  // background: linear-gradient(135deg, #7158fe, #9d4de6);
  background: linear-gradient(135deg, rgb(255 157 37), rgb(0 0 0));
  color: #fff;
  text-align: center;
  padding: 3px 26px 27px;
  border-radius: 15px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  border: 3px dotted white;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}
.couponSet .logo {
  width: 50px;
}
.couponSet .coupon-card h3 {
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: $font-family-body;
  text-transform: capitalize;
}
.couponSet .coupon-card h4 {
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: $font-family-body;
}
.couponSet .coupon-card p {
  font-size: 13px;
}
.couponSet .coupon-row {
  display: flex;
  align-items: center;
  width: fit-content;
}
.couponSet #cpnCode {
  border: 1px dashed #fff;
  border-right: 0;
  padding: 5px;
  text-transform: uppercase;
}
.couponSet #cpnBtn {
  border: 1px solid #fff;
  background: #fff;
  padding: 5px;
  color: #7158fe;
  cursor: pointer;
}
.couponSet .circle1,
.couponSet .circle3,
.couponSet .circle2 {
  background: #f0fff3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.253);
  i {
    font-size: 1.4rem;
  }

}
.circle1 {
  i {
    color: #0076FC;
  }
}
.circle2 {
  i {
    color: red;
  }
}
.circle3 {
  width: 55px;
  height: 55px;
  i {
    font-size: 1.2rem !important; 
    line-height: 18px;
  }
  span {
    font-family: $font-family-body;
    font-size: 0.55rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 10px;
  }
}
.couponSet .circle1 {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.couponSet .circle2 {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}
.couponSet .circle3 {
  top: 100%;
  transform: translate(0, -50%);
}

.inventoryTable {
  .inStock {
    color: #47be7d;
  }
  .outStock {
    color: #e9b500;
  }
  th {
    font-weight: 500;
    color: #555;
    font-size: 0.9rem;
  }
  tr.noQtyAlert {
    td {
      background: #ff00002a;
    }
  }
}

.greyBtn {
  border-radius: 6px !important;
  background-color: #f3f6f9 !important;
  padding: 8px 32px !important;
  color: #7e8299 !important;
  font-size: 12px !important;
  font-family: $font-family-body;
  font-style: normal;
  font-weight: 600 !important;
  border: solid 1px #7e8299 !important;
  &:hover {
    background-color: #7e8299 !important;
    color: #f3f6f9 !important;
  }
}

.bankDetails {
  border-radius: 10px;
  border: 1px dashed #b5b5c3;
  background-color: rgba(245, 248, 250, 0.69);
  padding: 30px 30px 80px 30px !important;
  input {
    background-color: #fff !important;
  }
}

.addItems {
  border-radius: 10px;
  border: 1px dashed #b5b5c3;
  background-color: rgba(241, 148, 148, 0.09);
  padding: 5px 20px;
  margin-bottom: 15px;
  input,
  select {
    padding: 3px 5px !important;
    background-color: #fff;
    border: 1px solid #00000069 !important;
  }
  input[name="qty"] {
    padding: 0;
    background-color: transparent;
  }
  .cutIcon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    background-color: #ffe2e5;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    z-index: 999;
    i {
      cursor: pointer;
      color: #d9214e;
    }
  }
}

.buttonCenter {
  margin: 0 auto;
}

.width100 {
  width: 100%;
}

.categoryWrap {
  max-width: 830px;
  margin: 0 auto;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(192, 195, 200, 0.2);
  font-family: $font-family-body;
  h3 {
    font-size: 1.2rem;
    border-bottom: solid 1px #eeeff3;
    padding: 10px 30px;
  }
  ul {
    padding: 10px 30px;
    flex-wrap: wrap;
    li:not(.subList) {
      color: #5e6278;
      font-size: 14px;
      line-height: 1.4em;
      font-weight: 500;
      font-family: $font-family-body;
      padding: 8px 10px;
      border-radius: 4px;
      margin: 0 10px 20px;
      box-shadow: 0px 0px 10px #0000001e;
      border: 1.5px solid #00000000;
      transition: all .5s ease-out;
      svg,button{
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-out;
        margin-left: -10px;
      }
      &:hover, &.active {
        border-color: #0076FC;
        cursor: pointer;
        svg,button{
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
          &:last-child{
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

.instock {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
