@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
// @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

body {
  background-color: #f9f9f9;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #727e8c;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #475f7b;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: 0.5s;
  outline: none;
}

/* ------------ */

.card {
  //   box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  //   border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
  .card-body {
    padding: 0;
  }
}

.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.form-submit {
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
  // width: 120px;
  height: 20px;
  cursor: pointer;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  // min-height: 150px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.file-upload-box .file-upload-input {
  height: 100%;
  width: 100%;
  visibility: hidden;
  position: absolute;
  top: 0;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  width: 100px;
  margin-right: 10px;
  flex-wrap: wrap;
  .file-image {
    width: 100%;
    height: 100px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: 100%;
  // display: none;
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}

.breadcrumb {
  background: transparent;
  margin: 0;
  border-radius: 0px;
  padding: 10px 0px !important;
}

header {
  background: #fff;
}

.kb-attach-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

button {
  cursor: pointer;
}

.master-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #00000054;
  span {
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
  }
  i,
  svg {
    font-size: 0.9rem;
    color: #000;
    width: 40px;
  }
}

.fs-10 {
  font-size: 0.7rem !important;
  font-weight: bold;
}

.category_images {
  position: absolute;
  top: 0%;
  border-bottom: 1px solid #fff;
  width: 100%;
  display: flex;
  background-color: #0000004b;
  padding: 6px 10px;
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    border: 7px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
    background-color: #fff;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }

  button {
    background-color: transparent;
    color: #fff;
    font-size: 1rem;
    border-bottom: 3px solid #fff;
    padding: 4px 10px;
  }
}

.modal-title.h4 {
  font-size: 1rem;
}
.deleteModal {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 0.4rem;
  span {
    color: #000;
    font-weight: 500;
    letter-spacing: 0.78px;
  }
}
