header {
  align-items: center;
  padding: 13px 30px 13px 0;
  .backBtn {
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -13px;
    z-index: 9;
  }
  .headerNav {
    padding-left: 30px;
    li {
      a {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #6c7293;
        padding: 9px 23px;
        background-color: transparent;
        border-radius: 0px;
      }
      &.active {
        a {
          background: #f3f6f9;
          border-radius: 4px;
          color: #6993ff;
        }
      }
    }
  }
  .searchForm {
    width: 240px;
    margin-left: auto;
    .form-group {
      position: relative;
      .icon {
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
      }
    }
    input {
      width: 100%;
      outline: none;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #b5b5c3;
      border: 1px solid #e4e6ef;
      border-radius: 9px;
      padding: 10px 20px 10px 40px;
    }
  }
  .chatIcon {
    position: relative;
    margin: 0 30px 0 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #50cd89;
      display: block;
      margin-bottom: 7px;
    }
  }
  .profile {
    align-items: center;
    span {
      display: block;
      font-weight: 400;
      font-size: 10px;
      line-height: 1;
      color: #747474;
    }
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: #000000;
      padding-bottom: 4px;
    }
    figure {
      width: 25px;
      height: 26px;
      // border-radius: 6px;
      background-color: #f9f9f9;
      margin: 0px;
      margin-right: 8px;
      img {
        border-radius: 6px;
      }
    }
    .userIcon {
      .icon{
        width: 30px;
        height: 26px;
        text-align: center;
        .svg-inline--fa{
          height: 12px;
        }
      }
      
      &:hover {
        .userDropDown {
          visibility: visible;
          opacity: 1;
          transform: translateY(20px);
        }
      }
    }
    .userDropDown {
      background-color: #fff;
      position: absolute;
      top: 100%;
      width: 215px;
      right: 0px;
      border: 1px solid #0000001a;
      z-index: 9;
      box-shadow: 0px 0px 10px #0000006e;
      border-radius: 10px;
      transform: translateY(40px);
      visibility: hidden;
      opacity: 0;
      transition: all 1s ease-out;
      hr {
        margin: 0;
      }
      ul {
        margin: 0;
      }
      li {
        padding: 0.3rem 1rem;
        font-size: 0.9rem;
        font-family: "poppins";
      }
    }
  }
}

.otpModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }
  .otpIfNoLogin {
    min-width: 400px;
    p {
      width: 80%;
    }
    .Otp-form-control {
      width: 50px !important;
      height: 41px !important;
    }
    #inputs {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .left-and-right-inner-addon {
      position: relative;
    }
    .left-and-right-inner-addon input {
      padding-right: 35px !important;
      padding-left: 25px !important;
    }
    .left-and-right-inner-addon i.left {
      position: absolute;
      padding: 12px 12px;
    }
    .left-and-right-inner-addon button.right {
      position: absolute;
      right: 10px;
      // padding: 12px 12px;
      margin: 0;
    }
    .submitBtn {
      font-size: 0.9rem;
    }
  }
}
