.onboardDetails {
  width: 100%;
  background-color: #fff;
  .wrap {
    background: #ffffff;
    border-radius: 12px;
  }
  .head {
    border-bottom: solid 1px #eeeff3;
    align-items: center;
    button {
      margin-left: auto;
      align-self: center;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      background-color: #eef6ff;
      i {
        color: #a1a5b7;
        font-size: 22px;
      }
    }
    h2 {
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: #212121;
    }
    a,
    .btn {
      color: $color-white;
      font-weight: 400;
      font-size: 14px;
      background: #00a3ff;
      border-radius: 6px;
      padding: 7px 10px;
      width: unset;
      border: none;
    }
  }
  .inner {
    padding: 0 0 5px;
    .tabs {
      border: 0px !important;
      .nav-link {
        border: 0px !important;
        color: #000000 !important;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 0.88rem;
        letter-spacing: 0.3px;
        &.active {
          background: #f5f8fa;
          color: #009ef6 !important;
        }
      }
    }
    .row {
      &:nth-of-type(1) {
        li {
          &.full {
            width: 100%;
          }
        }
      }
    }
    h3 {
      font-weight: 700;
      font-size: 19px;
      line-height: 22px;
      color: #3f4254;
      padding-bottom: 20px;
      margin: 15px 0;
      border-bottom: solid 1px #eff2f5;
    }
    ol {
      li {
        list-style: square;
        border: 0 !important;
        background-color: transparent !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
    li:not(.nav-item) {
      background: #f8f9ff;
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      padding: 10px 13px;
      width: calc(25% - 18.75px);
      margin-right: 15px;
      margin-bottom: 15px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      .amenities {
        margin-top: 15px;
        li {
          background: transparent;
          border: none;
          border-radius: 0px;
          padding: 0px;
          width: 16.5%;
          margin-right: 0px;
          margin-bottom: 20px;
          align-items: center;
          flex-wrap: nowrap;
          padding-right: 10px;
          img {
            width: 20px;
          }
          p {
            color: #000000;
            margin-left: 10px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
            letter-spacing: 0.023rem;
          }
        }
      }
      h4 {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        letter-spacing: 0.03rem;
      }
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #555557;
        display: block;
        margin-top: 2px;
      }
      a {
        font-size: 14px;
        color: #3699ff;
      }
    }
  }
}

.table-box {
  width: 100%;
  table {
    width: 100%;
    border-spacing: 0;
    thead {
      background-color: #e1ae2152;
    }
    tbody tr:nth-child(even) {
      background-color: #9e9e9e2f;
    }
    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      border-bottom: solid 1px #e5eaee;
      padding: 10px 5px;
    }
    td {
      padding: 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      color: #000;
      a {
        font-size: 14px;
      }
      .button-group {
        .btn {
          padding: 3px 8px;
          margin-right: 10px;
        }
      }
    }
  }
  margin-top: 5px;
}

.dasdasdas {
  overflow: auto;
}
.room-listing {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  .room-box {
    width: calc(16.66% - 0.5rem);
    text-align: center;
    padding: 2px;
    background-color: #f1f1f1;
    position: relative;
    p {
      margin-bottom: 5px;
      font-size: 10px;
      color: #222;
    }
    img {
      width: 20px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #000;
      width: 18px;
      height: 18px;
      color: #fff;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.stepOnboard {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  background: #3699ff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  position: relative;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px 1px #ddd;
  .fa-check {
    color: #fff;
    background-color: green;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    padding: 3px;
    font-size: 0.5rem;
  }
  &:hover {
    scale: 1.1;
  }
  p {
    font-size: 1rem;
  }
}

.editDrop {
  position: fixed;
  background-color: #fff;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 99999;
  box-shadow: 0px 0px 10px #999;
  right: 5%;
  padding: 0.5rem;
  border-radius: 9px;
  &.inBooking {
    right: 2%;
  }
  &:not(.inBooking) button {
    background-color: transparent !important;
    border: 0;
    color: #333;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: start;
    padding: 1rem;
    transition: all 1s ease-out;
    &:hover {
      border: 1px solid #009ef6;
      color: #000;
    }
    &:focus {
      color: #000;
    }
  }
  hr {
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
  }
}
