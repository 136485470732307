.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &.nowrap {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;     
    }

    &.column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    &.space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    &.space-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    &.space-around {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    &.space-evenly {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }

    &.space-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    // Items alignment
    &.align-center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    &.align-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    &.align-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
