.tableHeading {
  color: $color-black;
}

.foodTable {
  .deliver {
    color: #47be7d;
  }
  .pending {
    color: #e9b500;
  }
  p {
    font-size: 13px;
    color: #b5b5c3;
  }
}

.AssignRoom,
.room-page {
  .room-card.active {
    background-color: #47be7d !important;
    border: dashed 1px #47be7d !important;
    .inner-assgin-box {
      color: #fff !important;
    }
    .people-card {
      border: solid 1px #fff;
    }
  }
  .room-card.selected {
    background-color: #9bfbc9 !important;
  }
  .room-card.disabled {
    background-color: rgba(217, 33, 78, 0.2) !important;
    border: dashed 1px #d9214e !important;
    .inner-assgin-box {
      color: #d9214e !important;
    }
    .people-card {
      border: solid 1px #d9214e;
    }
  }
  .room-card.dirty {
    background-color: rgba(255, 154, 13, 0.2) !important;
    border: dashed 1px #ff9a0d !important;
    &.selected {
      background-color: rgba(255, 154, 13, 0.5) !important;
    }
    .inner-assgin-box {
      color: #ff9a0d !important;
    }
    .people-card {
      border: solid 1px #ff9a0d;
    }
  }
  .room-card.blocked {
    background-color: rgba(218, 218, 218, 0.2) !important;
    border: dashed 1px #000000 !important;
    .inner-assgin-box {
      color: #353535 !important;
    }
  }
}

.field-group-column {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .btn-position {
    position: absolute;
    top: -30px;
    right: 0;
    // box-sizing: border-box;
    // background-color: red;
    padding: 0;
    background-size: 10px;
  }
}
