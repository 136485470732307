.mainBook {
  padding: 20px 0 50px;
  background-color: #f9f9f9;
  .col-left {
    width: calc(100% - 363px);
    padding-right: 20px;
  }
  .col-right {
    width: 363px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 10px;
    padding: 15px;
    align-self: flex-start;
    .mealPlan {
      display: flex;
      justify-content: space-between;
      .lables {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        span {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          label {
            margin-bottom: 0;
          }
        }
        small {
          font-size: 0.65rem;
          font-weight: 600;
          letter-spacing: 0.3px;
        }
      }
    }
    h3 {
      padding-bottom: 4px;
      border-bottom: 1px solid #d9dbe4;
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #181c32;
      margin-bottom: 20px;
    }
    ul {
      margin-bottom: 0px;
    }
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      span {
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #5b5670;
      }
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        color: #181c32;
        font-family: $font-family-Poppins;
        text-align: right;
        margin-bottom: 0px;
        &.green {
          color: #47be7d;
        }
        del {
          color: #ff0000;
          font-size: 12px;
        }
      }
    }
    .total-price {
      justify-content: space-between;
      padding-top: 10px;
      border-top: 1px solid #d9dbe4;
      &.bgTotalPrice {
        background: #000;
        padding: 20px 10px 10px;
        h4,
        span,
        p {
          color: #fff;
        }
      }
      h4 {
        font-family: $font-family-Poppins;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        color: #181c32;
        span {
          font-family: $font-family-inter;
          font-weight: 500;
          font-size: 10px;
          line-height: 23px;
          color: #7e8299;
        }
      }
      p {
        font-family: $font-family-Poppins;
        font-weight: 700;
        font-size: 18px;
        line-height: 15px;
        color: #47be7d;
      }
    }
  }

  .hotelsBox {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
    border-radius: 0px 0px 4px 4px;
    padding: 15px 25px 10px;
    margin-bottom: 15px;
    .bookingTags .tag {
      display: flex;
      align-items: start;
      color: #000;
      font-family: $font-family-body;
      gap: 1rem;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0px;
      }
      svg {
        font-size: 1.4rem;
        width: 20px;
        margin-top: 0.1rem;
      }
      i {
        font-size: 1.6rem;
        width: 20px;
        margin-top: 0.1rem;
      }
      .content {
        p {
          margin-bottom: 0;
          font-size: 0.88rem;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: 0.3px;
        }
        span {
          color: #000000cc;
          font-size: 0.8rem;
          font-weight: 500;
          letter-spacing: 0.3px;
        }
      }
    }
  }
  .rating {
    justify-content: space-between;
    padding-bottom: 0;
    .col-left,
    .col-right {
      padding: 0;
      width: auto;
    }
    i {
      color: #ffc700;
      font-size: 15px;
    }
    span {
      margin-left: 11px;
      font-family: $font-family-inter;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #7e8299;
    }
    p {
      background: #fff5f8;
      border: 1px solid #ff0034;
      border-radius: 86px;
      padding: 8px 18px;
      font-family: $font-family-Poppins;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #ff0034;
      img {
        margin-right: 6px;
      }
    }
  }
  h2 {
    font-weight: 600;
    color: #181c32;
    // padding: 0 20px;
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 38px;
      color: #181c32;
    }
  }
  .address {
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgb(36, 36, 36);
    font-family: $font-family-Poppins;
    img {
      margin-right: 8px;
    }
  }

  .overlay {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(52, 64, 84, 0.6);
    backdrop-filter: blur(8px);
    animation: fadein 0.5s;
  }

  .filterRes {
    background-color: #f6fff7;
    border: solid 2px #0ca822;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    h4{
      color: #000;
      font-weight: 700;
    }
  }
  .filterRes {
    align-items: center;
    justify-content: space-between;
    li {
      h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #181c32;
      }
      h5 {
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #181c32;
        span {
          font-family: $font-family-inter;
          font-weight: 500;
          font-size: 10px;
          line-height: 1em;
          color: #7e8299;
          display: block;
        }
      }
      .timelines {
        align-self: center;
        padding: 0 60px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 140px;
          height: 1px;
          border-top: 1px dashed #7e8299;
          margin: 0 10px;
        }
        p {
          border: 1px dashed #7e8299;
          border-radius: 50px;
          font-weight: 500;
          font-size: 10px;
          color: #7e8299;
          padding: 4px 23px;
          font-family: $font-family-inter;
          background-color: $color-white;
          position: relative;
        }
      }
      &:nth-of-type(1) {
        display: flex;
      }
    }
    .ratingCal {
      border-radius: 90px;
      background-color: rgba(71, 190, 125, 0.2);
      align-items: center;
      display: flex;
      align-items: center;
      span {
        background: #47be7d;
        border-radius: 90px;
        padding: 5px 10px;
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 10px;
        line-height: 1.25em;
        color: #ffffff;
      }
      p {
        padding-left: 5px;
        font-family: $font-family-body;
        font-weight: 400;
        font-size: 10px;
        line-height: 1.25em;
        color: #47be7d;
        padding-right: 15px;
      }
    }
  }
}

.guestForm {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(56, 71, 109, 0.03);
  border-radius: 10px;
  h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #181c32;
    padding: 0;
  }
  form {
    justify-content: space-between;
    .form_group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      &.half {
        width: 48%;
      }
      input,
      textarea {
        outline: none;
        resize: none;
        width: 100%;
        background: #ffffff;
        border: 1px solid #d9dbe4;
        border-radius: 6px;
        padding: 13px 15px;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #a1a5b7;
      }
      textarea {
        height: 105px;
      }
      button {
        padding: 12px 30px;
        border: 1px solid #00a3ff;
        border-radius: 6px;
        background-color: #00a3ff;
        font-family: $font-family-Poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.25s ease;
        &:first-of-type {
          color: #00a3ff;
          background-color: #eef6ff;
          &:hover {
            background-color: #00a3ff;
            color: #fff;
          }
        }
      }
    }
  }
}

.booking-form {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 7px #f0f0f0;
  padding: 15px;
}

.applyCoupon {
  margin-top: 20px;
  .form-group {
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
  .couponText {
    span {
      &:nth-of-type(2) {
        font-weight: 600;
        font-size: 0.7rem;
      }
      &:nth-of-type(3) {
        font-weight: 600;
        font-size: 0.6rem;
      }
    }
  }
  button {
    align-self: flex-start;
    &:hover {
      border: solid 1px #5a8dee !important;
      color: #5a8dee;
      background-color: #fff !important;
    }
  }
  p {
    font-size: 13px;
    margin-top: 5px;
  }
  .couponShowHide {
    border: 1px solid #0000001c;
    background: #00a3ff;
    padding: 3px 10px;
    color: #fff;
    font-size: 0.85rem;
    border-radius: 4px;
    letter-spacing: 0.5px;
  }
}

.detailDatePicker {
  input {
    font-size: 0.9rem;
    height: auto;
    padding: 1px 10px;
    width: 120px;
  }
}

.detailSmallInput {
  font-size: 0.9rem !important;
  height: auto !important;
  padding: 1px 10px !important;
  width: 40px !important;
}

.tooltip_Fire {
  position: relative;
  &:hover .Liketooltip {
    visibility: visible;
    opacity: 1;
  }
  .Liketooltip {
    position: absolute;
    top: 100%;
    background-color: #fff;
    padding: 5px;
    font-size: 0.8rem;
    color: #000;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    z-index: 1;
  }
}

.otpIfNoLogin {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 350px;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 24px 0px;
  color: rgb(53, 52, 52);

  svg {
    margin: 16px 0;
  }
  title {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    color: #777777;
    font-size: 14px;
    width: 200px;
    margin-top: 4px;
  }
  input {
    width: 32px;
    height: 32px;
    text-align: center;
    border: none;
    border-bottom: 1.5px solid #d2d2d2;
    margin: 0 10px;
  }

  input:focus {
    border-bottom: 1.5px solid deepskyblue;
    outline: none;
  }

  button {
    letter-spacing: 2px;
    margin-top: 1rem;
    padding: 0.3rem 1rem;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
  }
}


.booking-form .form-group label{
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  display: block;
  margin-bottom: 6px;
}
.booking-form .form-group .form-control{
  font-size: 12px;
  height: 32px;
}
.booking-form .bookingActions{
  padding: 12px 0px;
}
.booking-form .bookingActions .form-group{
  margin-bottom: 0px;
}