* {
  padding: 0px;
  margin: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.slick-slider .slick-track,
.luxy-el,
[data-aos],
.slick-slider .slick-list,
.swiper-wrapper,
.swiper-slide,
.swiper-container,
[data-jarallax-element] {
  will-change: transform;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  width: 100%;
  font-family: $font-family-body;
  font-size: 18px;
  line-height: 1.6;
  color: $color-black;
  &.active {
    overflow: hidden;
  }
}

ul {
  margin: 0;
  padding: 0;
}
ul,
ol:not(.bulletNotHide) {
  li {
    list-style: none;
  }
}
a {
  color: $color-black;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: top;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.relative {
  position: relative;
  width: 100%;
}
.color-white {
  * {
    color: $color-white;
  }
}

p {
  font-weight: 400;
}

.bg-control {
  background-size: cover;
  background-repeat: no-repeat;
}

.slick-slide {
  outline: none;
}
figure {
  margin: 0;
  padding: 0;
  img {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  background: #00a3ff;
}

a,
span {
  display: inline-block;
}
.container {
  max-width: $container;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1200px) {
    max-width: $container !important;
  }
  @media (min-width: 1140px) and (max-width: 1200px) {
    max-width: 970px !important;
  }
}

.custom-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.container-lg {
  max-width: $container-lg;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

.loaderCss {
  .loader-wrapper {
    position: fixed;
    z-index: 999999999999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      z-index: 1;
      width: 80px;
      opacity: 0.9;
    }
  }
}

.popup-form {
  .form-group {
    margin-bottom: 15px;
    label {
      color: #464e5f;
      font-size: 14px;
    }
    input {
      font-size: 12px;
      background-color: #fff;
      border: none;
      outline: none;
      border-radius: 0;
      padding: 12px 10px;
      display: block;
      width: 100%;
    }
    .inputWrap {
      position: relative;
      .p-viewer {
        position: absolute;
        top: 8px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
  .foodOrderTaxShow {
    p {
      font-size: 0.8rem;
      color: #000;
      margin: 0;
      font-weight: 600;
      font-family: $font-family-body;
      text-align: right;
      letter-spacing: 0.5px;
    }
  }
}

.coupons {
  margin-top: 15px;
  gap: 1rem;
  background: #fff;
  padding: 15px;
  width: 100%;
  box-shadow: 0px 0px 7px #f0f0f0;
  border-radius: 20px;
  h4 {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
    border-bottom: 2px solid #000;
    display: inline;
    margin-bottom: 0.5rem;
    padding-bottom: 3px;
  }
  .card {
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: relative;
    margin-bottom: 5px;
    padding: 10px 5px;
    border: 1px solid #0000002a;
  }

  .main {
    padding: 5px 19px;
  }
  .main,
  .copy-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card::after {
    position: absolute;
    content: "";
    height: 40px;
    border-radius: 40px;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: #00a3ff;
    width: 40px;
  }

  .card::before {
    position: absolute;
    content: "";
    height: 40px;
    border-radius: 40px;
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    background-color: #00a3ff;
    width: 40px;
  }

  .co-img img {
    width: 50px;
    height: 50px;
  }
  .content {
    border-left: 2px dotted black;
    padding-left: 10px;
  }

  .content h1 {
    font-size: 17px;
    color: #565656;
  }

  .content h1 span {
    font-size: 14px;
  }
  .content h2 {
    font-size: 12px;
    color: #565656;
    text-transform: uppercase;
  }

  .content p {
    font-size: 10px;
    color: #000000;
    font-weight: 600;
    margin: 0;
  }

  .copy-button {
    border: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
  }

  .copy-button input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
    padding-left: 5px;
    text-transform: uppercase;
  }

  .copy-button button {
    padding: 5px 20px;
    background-color: #00a3ff;
    color: #fff;
    border: 1px solid transparent;
  }
}

.error-text {
  color: #d64646;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 14px;
    padding-right: 5px;
  }
}

.form-control:not(.dataForm) {
  border: 1px solid #888 !important;
}

.orderFoodCategory {
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  .foodCategoryBtn {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    min-width: fit-content;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  .foodCategoryBtn.active {
    background-color: rgb(255, 0, 0) !important;
    color: #fff;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
    visibility: hidden;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}

.itemsCategory {
  ul {
    display: flex;
    gap: 0.3rem;
  }
  li {
    width: 100%;
    display: flex;
    .incDec {
      box-shadow: 0px 0px 2px #007bff;
      background-color: #007bff08;
      border: 1px solid #007bff;
      color: #007bff;
      height: 100%;
      display: flex;
      padding: 2px;
      button {
        width: 25%;
        background-color: #2e2e2ed9;
        color: #fff;
        font-size: 1.3rem;
      }
      input {
        width: 50%;
        background-color: rgb(255, 255, 255);
        border: 0;
        text-align: center;
      }
    }
    label {
      display: flex;
      padding: 10px 20px;
      justify-content: space-between;
      cursor: pointer;
      border: 1px solid rgba(119, 119, 119, 0.4196078431);
      font-weight: 500;
      text-align: center;
      height: 100%;
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
      user-select: none;
    }
  }
  .activeLable {
    box-shadow: 0px 0px 2px #007bff;
    background-color: #007bff08;
    border: 1px solid #007bff;
    color: #007bff;
  }
}
