// landing page header section start
.headerLanding {
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 99;
  box-sizing: border-box;
}
.homePageMain {
  background-color: #ffffff;
  .headerLanding {
    background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 128, 0, 0));

    .headerTop {
      border-bottom: 0;
    }
  }
}
.headerTop {
  padding: 10px 10px;
  border-bottom: solid 1px #5e6278;
  .container {
    align-items: center;
  }
  ul {
    margin-left: auto;
    margin-bottom: 0;
  }
  li {
    display: flex;
    margin-left: 50px;
    align-items: center;
  }
  figure {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .dashboardLogo {
    width: 185px;
    height: 52px;
    a {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    h2 {
      font-size: 30px;
      text-shadow: 0px 0px 10px #000000ab;
      color: #ffffff;
      margin-left: 10px;
      margin-bottom: 0;
      text-transform: uppercase;
      &:first-letter {
        color: #ff9d25;
      }
    }
  }
  .content {
    p,
    span {
      color: $color-white;
      font-family: $font-family-body;
      cursor: pointer;
      text-shadow: 0px 0px 10px #000000ab;
    }
    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 0;
      text-shadow: 0px 0px 10px #000000ab;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #f4f4f4;
      vertical-align: top;
      text-shadow: 0px 0px 10px #000000ab;
    }
  }
  select {
    border: none;
    background-color: transparent;
    color: #f4f4f4;
    cursor: pointer;
    vertical-align: top;
    outline: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    option {
      color: black;
    }
  }
  .logoutButton {
    margin-left: 10px;
  }
  .loginButton,
  .logoutButton {
    border: 1px solid #00a3ff;
    border-radius: 6px;
    padding: 9px 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #00a3ff;
    font-family: $font-family-body;
    display: flex;
    align-items: center;
    background-color: transparent;
    min-height: 52px;
    text-shadow: 0px 0px 10px #000000;
    img {
      margin-right: 11px;
    }
  }
}

.mainNavbar {
  padding: 10px 0;
  ul {
    align-items: center;
    justify-content: space-between;
  }
  select {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #eef6ff;
    font-family: $font-family-body;
    width: auto;
    outline: none;
    option {
      color: $color-black;
    }
  }
}

// home banner
.homeBanner {
  background-image: url(../../images/Banner3.jpg);
  background-repeat: no-repeat;
  box-shadow: 0px 0px 9px 5px #9a9a9a;
  position: relative;
  padding: 180px 0px 40px;
  &::after {
    content: "";
    background-color: rgba(1, 54, 84, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(5px);
  }
  .searchWrapper{
    height: 85px;
  }
  .homeBannerUpper{
    position: relative;
    z-index: 10;
  }
  h1 {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    text-align: center;
    font-size: 35px;
    line-height: 50px;
    text-shadow: 0px 0px 40px #000;
    padding: 0px 15px;
    margin-bottom: 5px;
    span {
      &:nth-of-type(1) {
        font-weight: 500;
        line-height: 23px;
        &::first-letter {
          color: #f89924;
        }
      }
      &:nth-of-type(2) {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  p{
    padding-top: 18px;
  }
}

// banner form
.banner-form {
  width: 100%;
  // transition: all 0.3s ease-out;
  top: 0px;
  left: 0px;
  z-index: 999;
  position: sticky;
  .wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    background: rgba(255,255,255,0.95);
    border-radius: 9px;
    padding: 15px;
    margin-top: 25px;
  }
  &.homeSearch {
    background: transparent;
    margin-top: -70px;
    .wrapper {
      box-shadow: 0px 0px 30px #00000047;
    }
  }
  &.active {
    position: fixed;
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.233);
    .wrapper {
      box-shadow: none;
      margin-top: 0px;
    }
  }

  .form-group {
    background-color: #fff;
    margin-bottom: 0;

    &:nth-of-type(1) {
      border-left: solid 1px #d9dbe4;
      border-top: solid 1px #d9dbe4;
      border-bottom: solid 1px #d9dbe4;
      padding: 14px 14px;
      border-radius: 6px 0 0 6px;
      position: relative;
      width: 35%;
    }
    &:nth-of-type(2) {
      border-left: solid 1px #d9dbe4;
      border-top: solid 1px #d9dbe4;
      border-bottom: solid 1px #d9dbe4;
      padding: 7px;
      display: flex;
      align-items: center;
      width: 32%;
      // flex: 1;
      img {
        margin: 0 5px;
      }
    }
    &:nth-of-type(3) {
      border-left: solid 1px #d9dbe4;
      border-top: solid 1px #d9dbe4;
      border-bottom: solid 1px #d9dbe4;
      width: calc(33% - 139px);
    }
    .suggetions {
      z-index: 1;
      div {
        background-color: #eee;
      }
    }
    span {
      position: absolute;
      right: 10px;
      top: 14px;
      background: rgba(0, 163, 255, 0.1);
      border-radius: 90px;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #00a3ff;
      img {
        margin-left: 6px;
      }
    }
  }
  input.searchMainBox {
    font-family: $font-family-body;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #a1a5b7;
    border: none;
    outline: none;
    width: 100%;
    padding-right: 150px;
  }
  .clearSearchBar {
    position: absolute;
    top: 50%;
    right: 40%;
    transform: translate(100%, -50%);
    font-size: 0.8rem;
    color: #365b99;
    cursor: pointer;
    z-index: 1;
  }
  .error-text {
    margin-top: 0;
    // position: absolute;
    top: 90%;
    font-size: 0.6rem;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .searchSuggestionText {
    font-size: 14px;
    color: #000;
    letter-spacing: 0.5px;
    margin: 0;
    margin-left: 15px;
    user-select: none;
  }
  input.dataform {
    border: 1px solid #ced4da !important;
  }
  input[type="date"] {
    border: none;
    outline: none;
    font-family: $font-family-body;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #5b5670;
  }
  select {
    border: none;
    outline: none;
    padding: 15px 14px;
    font-family: $font-family-body;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #5b5670;
    background-color: #fff;
  }
  button:not(.react-datepicker__navigation) {
    padding: 17px 40px;
    background-color: #00aa6c;
    border-radius: 0px 6px 6px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-white;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: $font-family-body;
    border: solid 1px #00aa6c;
    transition: all 0.25s ease;
    &:hover {
      color: #00a3ff;
      background-color: #fff;
    }
  }
  button {
    background-color: #00a3ff;
  }
}

// popular section
.popular-section {
  padding-top: 20px;
  .explore-box {
    .show-explore-city {
      .city-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        overflow: auto;
        padding: 5px 0px 10px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(155, 146, 146, 0);
        }
        ::-webkit-scrollbar {
          width: 9px;
        }

        &::-webkit-scrollbar-thumb {
          background: #00a3ff;
        }
        &::-webkit-scrollbar-thumb {
          border: 6px solid transparent;
          border-radius: 9px;
          background-clip: content-box;
        }

        .show-city {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-start;
          width: 80px;
          cursor: pointer;
          text-align: center;
          margin: 0px 10px;
          &:hover {
            img {
              scale: 0.9;
            }
            figure {
              box-shadow: 0px 0px 15px #00000060;
            }
          }
          figure {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 15px #0000001c;
            margin-bottom: 10px;
            img {
              border-radius: 10%;
              transition: all 0.5s ease-out;
              border-radius: 50%;
              width: 100%;
              height: 100%;
            }
          }
          p {
            color: #000;
            font-size: 12px;
            letter-spacing: 0.4px;
            font-weight: 500;
            line-height: 1;
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
    .sliderImg {
      .wrapper {
        background: #f2f3f7;
        box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
        border-radius: 12px;
      }
      figure {
        height: 200px;
        border-radius: 12px 12px 0 0;
        &:after {
          display: none;
        }
        img {
          border-radius: 12px 12px 0 0;
        }
      }
      .content {
        position: static;
        padding: 15px 20px 20px 20px;
        h5 {
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #3f4254;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #000;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          img {
            margin-right: 7px;
          }
        }
        h3 {
          font-weight: 900;
          font-size: 24px;
          line-height: 28px;
          color: #3f4254;
          span {
            margin-left: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #ff0034;
          }
        }
        .button-wrap {
          margin-top: 20px;
          a {
            padding: 12px 20px;
            color: #7e8299;
            background: #d9dbe4;
            border-radius: 6px;
            margin-right: 20px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            &:nth-of-type(2) {
              background-color: #00a3ff;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.appDownloadSection {
  padding: 60px 0;
  margin-top: 1rem;
  background-color: #eeeeee;
  position: relative;
  .first ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    li {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.3rem;
      font-size: 1.4rem;
      font-family: $font-family-body;
      svg,
      span {
        color: #fb961c;
      }
      .font-lg{
        font-size: 150%;
        font-weight: 700;
      }
      svg {
        margin-right: 15px;
      }
      &:nth-child(2) {
        svg {
          font-size: 1.2rem;
        }
      }
    }
  }
  .second {
    position: relative;
    h1 {
      color: #fb961c;
      font-size: 2.5rem;
      text-align: center;
      font-family: $font-family-body;
      font-weight: 600;
      border-bottom: 4px solid #fb961c;
      padding-bottom: 15px;
      margin-top: -30px;
      margin-bottom: 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-color: #fb961c;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        width: 30px;
        height: 30px;
        -webkit-clip-path: polygon(52% 48%, 0 0, 100% 0);
        clip-path: polygon(52% 48%, 0 0, 100% 0);
      }
    }
    .mobileInput {
      margin-top: 1rem;
      position: absolute;
      left: 50%;
      top: 100%;
      width: 280px;
      transform: translate(-50%, -50%);
      border: 1px solid #00000067;
      border-radius: 50px;
      padding: 2px 4px;
      background-color: #fff;
      box-shadow: 0px 0px 20px #ff920e33;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .error-text {
        position: absolute;
        top: 90%;
        left: 0;
      }
      input {
        background-color: #fff;
        outline: none;
        border: 0;
        padding: 5px 8px;
        font-family: $font-family-body;
        border-radius: 50px;
        width: 75%;
      }
      button {
        background-color: #fb961b;
        border-radius: 50px;
        font-size: 0.8rem;
        color: #fff;
        font-family: $font-family-body;
        width: 80px;
        white-space: nowrap;
        padding: 4px 4px;
      }
    }
  }
  .image {
    ul {
      display: grid;
      column-gap: 15px;
      grid-template-areas:
        "main top"
        "main bottom";
      grid-template-columns: 3fr 5fr;
      row-gap: 10px;
      li {
        &:first-of-type {
          grid-area: top;
        }
        &:nth-of-type(2) {
          grid-area: bottom;
        }
        &:nth-of-type(3) {
          grid-area: main;
        }
      }
    }
  }
  .third {
    border-left: 1px solid #000000b0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px 0 20px;
    p {
      display: flex;
      gap: 0.5rem;
      font-weight: 600;
      color: #000;
      font-size: 0.75rem;
      letter-spacing: 0.8px;
    }
  }
}

// exclusive section
.exclusive-section {
  width: 100%;
  h2 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 28px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #17212d;
  }
  .wrap {
    border-radius: 12px;
    background-image: url("../../images/Banner2.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 70px;
    align-items: flex-end;
    justify-content: space-between;
  }
  a {
    font-family: $font-family-body;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    padding: 12px 20px;
    background: #00a3ff;
    border-radius: 6px;
    border: solid 1px #00a3ff;
    transition: all 0.25s ease;
    &:hover {
      color: #00a3ff;
      background-color: #fff;
    }
  }
  .box {
    padding: 30px 20px 32px 30px;
    border-radius: 12px;
    h4 {
      font-weight: 800;
      font-size: 28px;
      line-height: 100px;
      color: #000;
      text-shadow: 0px 0px 10px #fff;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      text-shadow: 0px 0px 10px #000;
    }
  }
}

.testimonialSeaction {
  .section-title {
    font-size: 28px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-weight: 400;
    display: inline-block;
    position: relative;
  }
  .section-title:after,
  .section-title:before {
    content: "";
    position: absolute;
    bottom: 0;
  }
  .section-title:after {
    height: 2px;
    background-color: rgba(252, 92, 15, 0.46);
    left: 25%;
    right: 25%;
  }

  .section-title:before {
    width: 15px;
    height: 15px;
    border: 3px solid #fff;
    background-color: #fc5c0f;
    left: 50%;
    transform: translatex(-50%);
    bottom: -6px;
    z-index: 9;
    border-radius: 50%;
  }
  .testimonial-item {
    padding: 10px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    figure {
      padding: 0;
      border-radius: 12px;
      width: 100px;
      height: 100px;
      position: relative;
      margin-bottom: 0;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.164);
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-out;
      }
    }
    &:hover {
      img {
        scale: 0.9;
      }
    }
    p {
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.3px;
      padding: 30px 0px 10px 0px;
    }
    h4 {
      font-size: 15px;
    }
    span {
      font-size: 12px;
      padding-bottom: 5px;
    }
    strong {
      font-size: 21px;
      color: rgb(255, 187, 0);
    }
  }
}
// choose section
.testimonialHomePage {
  width: 100%;
  border-radius: 12px;
  position: relative;
  padding: 0 15px;
  height: 100%;
  width: 100%;
  padding-top: 30px;

  .slick-initialized .slick-slide {
    margin-right: 15px;
  }

  .slick-arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 0;
    outline: none;
    border: none;
    position: absolute;
    right: 10px;
    top: -2px;
    cursor: pointer;
    right: 10;
    transform: translateY(-100%);
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 15px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.slick-prev {
      transform: translate(-120%, -100%);
      background-color: #eef6ff;
      &:before {
        background-image: url("../../images/leftarrowSlide.png");
      }
    }
    &.slick-next {
      background-color: #3e97ff;
      &:before {
        background-image: url("../../images/leftarrowRight.png");
      }
    }
  }
}
.choose-section {
  padding: 30px 0 10px;
  .heading {
    text-align: center;
    h2 {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 44px;
      line-height: 66px;
      color: #3699ff;
    }
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #5e6278;
    }
  }
  .wrap {
    display: flex;
    align-items: center;
  }
  .content {
    width: calc(100% - 655px);
    padding-right: 50px;
    padding-left: 110px;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    min-height: 600px;
  }
  li {
    width: 190px;
    position: relative;
    border-radius: 50%;
    text-align: center;
    display: block !important;
    position: absolute;
    margin: 20px;
    &:nth-child(1){
      top: 0px;
      left: 0px;
    }
    &:nth-child(2){
      top: 0px;
      right: 0px;
    }
    &:nth-child(3){
      bottom: 0px;
      left: 0px;
    }
    &:nth-child(4){
      bottom: 0px;
      right: 0px;
    }
    &:nth-child(5){
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img{
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background-color: #ededed;
      margin-bottom: 10px;
    }
    span {
      width: 40px;
      height: 40px;
      background: #eef6ff;
      border: 1px dashed #3699ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 17px;
      line-height: 48px;
      color: #3699ff;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      color: #000;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #5e6278;
    }
  }
  figure {
    width: 655px;
    padding-top: 0px;
  }
}

// explore-section
.explore-box {
  background: #ffffff;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  overflow-x: hidden;
  .heading {
    margin-bottom: 20px;
    h4 {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 22px;
      line-height: 38px;
      color: #181c32;
      margin-bottom: 0px;
    }
    p {
      font-family: $font-family-body;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #a1a5b7;
    }
  }
  .upperSliderHomepage {
    .slick-arrow {
      top: -40px !important;
    }
  }
  .slick-list {
    padding: 0 20% 0 0 !important;
  }
  .sliderImg {
    border-radius: 12px;
    position: relative;
    padding: 0 15px;
    height: 100%;
    width: 100%;
    figure {
      border-radius: 12px;
      width: 100%;
      height: 320px;
      position: relative;
      border: 1px solid #00000049;
      margin-bottom: 0;
      &:not(.noBottomEffect)::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 150px;
        border-radius: 0 0 12px 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        backdrop-filter: blur(1px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
  .content {
    padding: 25px 20px 25px 35px;
    position: absolute;
    left: 0;
    bottom: 0;
    p {
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      margin: 0;
    }
    span {
      font-family: $font-family-body;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ebebef;
    }
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 0;
    outline: none;
    border: none;
    position: absolute;
    right: 10px;
    top: -72px;
    cursor: pointer;
    right: 10;
    box-shadow: 0px 0px 10px #0000004d;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 15px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.slick-prev {
      transform: translateX(-120%);
      background-color: #eef6ff;
      &:before {
        background-image: url("../../images/leftarrowSlide.png");
      }
    }
    &.slick-next {
      background-color: #3e97ff;
      &:before {
        background-image: url("../../images/leftarrowRight.png");
      }
    }
  }
}
.explore-section {
  background-image: url("../../images/bg.png");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  .explore-box {
    background-color: transparent;
    padding: 25px 0;
  }
  .shimmerEffect {
    padding: 2rem;
    border-radius: 12px;
    width: 400px;
    height: 300px;
  }
}
.purchase-box {
  background: #000;
  box-shadow: 0px 10px 60px rgba(15, 15, 15, 0.08);
  border-radius: 9px;
  padding: 15px 15px 15px 40px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: -80px;
  align-items: center;
  position: relative;
  z-index: 1;
  .content {
    width: calc(100% - 345px);
    h3 {
      font-size: 24px;
      line-height: 1.4;
      font-weight: 400;
      color: $color-white;
      padding-right: 50px;
    }
  }
  .image {
    width: 345px;
    ul {
      display: grid;
      column-gap: 15px;
      grid-template-areas:
        "top main"
        "bottom main";
      grid-template-columns: 5fr 3fr;
      row-gap: 10px;
      li {
        &:first-of-type {
          grid-area: top;
        }
        &:nth-of-type(2) {
          grid-area: bottom;
        }
        &:nth-of-type(3) {
          grid-area: main;
        }
      }
    }
  }
}

// landing page footer
.footerLanding {
  background-color: #0d151e;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    height: 100px;
    // background-image: url("../../images/footer-effect.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -400px;
  }
  .top {
    padding-top: 127px;
    padding-bottom: 0px;
    gap: 1rem;
    .footerTabs {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      width: calc(20% - 1rem);
      &:not(:last-of-type) {
        border-right: solid 1px #152231;
      }
    }
    p,
    a {
      color: $color-white;
    }
    p {
      color: #b5b5c3;
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.01em;
      margin-bottom: 9px;
      text-transform: uppercase;
    }
    a {
      color: #7e8299;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.01em;
      display: flex;
      align-items: center;
    }
    li {
      &:not(:last-of-type) {
        margin-bottom: 17px;
      }
    }
    img {
      margin-right: 14px;
    }
    .listHotelFooter {
      button {
        margin-top: 1rem;
        padding: 8px 1.5rem;
        border-radius: 5px;
        display: flex;
        gap: 1rem;
        font-size: 1.1rem;
        font-family: $font-family-body;
      }
    }
  }
  .middTop {
    .container {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: end;
    }
    li {
      i {
        font-size: 1rem;
        color: #fff;
      }
    }
    p {
      color: #fbfbfb;
    }
  }
  .center {
    border-top: solid 1px #152231;
    padding: 0px;
    h3 {
      color: #b5b5c3;
      font-family: $font-family-body;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
      margin-bottom: 17px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      margin-left: 0.5rem;
    }
    li {
      color: #fff;
      cursor: pointer;
      font-size: 0.9rem;
    }
  }
  .bottom {
    padding-top: 20px;
    a {
      display: flex;
      align-items: center;
    }
    img {
      &:first-of-type {
        width: 24px;
        height: 25px;
        margin-right: 9px;
      }
      &:nth-of-type(2) {
        height: 14px;
      }
    }
    ul {
      margin-left: auto;
    }
    li {
      margin-left: 30px;
      a {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #7e8299;
        font-family: $font-family-body;
      }
    }
  }
}


.footerLanding .center{
  display: none;
}