.loginSection {
  width: 100%;
  height: 100vh;
  padding: 30px 0;
  background-color: rgba(0, 0, 0, 0.46);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/login_bg.jpg");
    z-index: -1;
  }
  .topbar {
    align-items: center;
    border-bottom: solid 1px #ffffff00;
    figure {
      width: 181px;
      height: 58px;
    }
    p {
      padding-left: 70px;
      font-weight: 500;
      color: $color-white;
      margin: 0;
      font-size: 23px;
    }
  }
  .wrapper {
    align-items: center;
  }
  .wrapper.loginWrapper {
    gap: 2rem;
    padding-left: 12rem;
  }
  .content {
    width: calc(100% - 480px);
    padding-right: 60px;
    p {
      font-weight: 600;
      color: $color-white;
      line-height: 1.6em;
    }
  }
  .img {
    width: 360px;
    figure {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.452);
      border-top-left-radius: 31px;
      border-bottom-left-radius: 31px;
    }
  }
  .form-wrap {
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.267);
    width: 458px;
    background-color: $color-white;
    .termsparagraph {
      font-size: 0.74rem;
      color: #000000d3;
      font-weight: 600;
      margin: 0 32px;
      a {
        font-size: 0.74rem;
      }
    }
    .otpEditMobile {
      margin: 0;
      font-size: 15px;
      color: #000;
      button {
        background-color: transparent;
        padding: 0;
        color: #00a3ff;
        border: 0;
        outline: 0;
      }
    }
    .notCustomerBtn {
      // background: #000;
      margin-left: 32px;
      color: #000;
      border-bottom: 1px solid #000;
      width: 150px;
      padding: 2px;
      font-size: 0.74rem;
      color: #000000d3;
      font-weight: 600;
      text-align: center;
      font-family: $font-family-body;
      cursor: pointer;
    }
    .head {
      padding: 8px 23px;
      background-color: $color-black;
      color: $color-white;
    }
    .tag {
      cursor: pointer;
      width: 100%;
      position: relative;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 53%;
        width: 100%;
        height: 1px;
        background-color: #a6a6a6;
      }
      span {
        border-bottom: 1px solid #a6a6a6;
        border-radius: 10px;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #222222;
        display: inline-block;
        background-color: $color-white;
        padding: 0px 20px;
        position: relative;
      }
    }
    .registerBtn {
      margin: 25px 20px 28px 32px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #222222;
      i {
        margin-left: 7px;
        color: #a6a6a6;
        font-size: 27px;
        vertical-align: middle;
      }
    }
  }
  h1 {
    font-weight: 700;
    color: $color-white;
    margin-bottom: 40px;
    line-height: 1em;
  }
  .form-inner {
    padding: 23px 32px 5px 32px;
    h2 {
      font-weight: 700;
      line-height: 1.5em;
      color: #333333;
      margin-bottom: 20px;
    }
    .react-tel-input {
      .form-control {
        width: 100%;
        border: 1px solid #a6a6a6;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
        height: 42px;
        padding-left: 50px !important;
      }
    }
    button {
      padding: 12px 64px;
      background-color: $color-black;
      border: solid 1px $color-black;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color-white;
      margin-top: 13px;
      font-family: "Poppins", sans-serif;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #222222;
      margin-top: 19px;
      a {
        color: #fe0000;
      }
    }
  }

  // register
  &.signUpSection {
    .form-inner {
      padding: 25px 32px 22px 32px;
      h2 {
        margin-bottom: 28px;
      }
    }
    .form-group {
      margin-bottom: 20px;

      &.passcode {
        position: relative;
        input {
          padding-right: 90px;
        }
        button {
          position: absolute;
          padding: 0;
          background-color: transparent;
          border: none;
          border-radius: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fe0000;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.25em;
      display: block;
      margin-bottom: 10px;
    }
    input {
      border: 1px solid #a6a6a6;
      border-radius: 4px;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25em;
      color: #222222;
      padding: 10px;
      width: 100%;
      font-family: "Poppins", sans-serif;
    }
    .signUpBtns {
      margin-top: -3px;
      padding: 12px 95px;
      background: #c9c9c9;
      border: 1px solid #c9c9c9;
    }
  }
}

.login-btn-list {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.admin-login-form {
  .form-group {
    margin-bottom: 20px;
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }
    input {
      width: 100%;
      font-size: 14px;
      padding: 10px 15px;
    }
    .icon_input {
      position: relative;
      overflow: hidden;
      div {
        position: absolute;
        top: 0.2px;
        right: 0;
        border: 1px solid #888;
        height: 100%;
        background: #a3a3a33d;
        color: #000;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        cursor: pointer;
        .fa-eye {
          font-size: 1rem;
          color: #000;
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.Otp-form-control {
  width: 35px !important;
  height: 35px !important;
  margin-right: 20px;
}

.verify-otp-booking {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .otpEditMobile {
    button {
      background-color: transparent;
    }
  }
  .form-group {
    > div {
      justify-content: center;
      input {
        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }
  }
}
